import React from 'react';
import MainContainer from '../../shared/main-container/MainContainer';
import styles from './ValidateSuccess.module.scss';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Box from '../../shared/box/Box';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { ValidationDetails } from '../ValidateForm.model';
import { RoutesPaths } from '../../../routes/routes.paths';

const ValidateSuccess = () => {
  const { goBack, location, push } = useHistory<ValidationDetails>();
  if (!location.state || location.state.id === undefined) {
    push(RoutesPaths.VALIDATE_RECEIPT);
    return null;
  }
  const {
    id,
    payerRemarks,
    payerSubject,
    currency,
    totalAmount,
    createdOn,
    buyerName,
    buyerPhone,
    buyerEmail,
  } = location.state;

  return (
    <MainContainer
      additionalHeaderElement={
        <div className={styles.header}>
          <Button onClick={goBack} className={styles.backCTA}>
            <ArrowBack />
            Back
          </Button>
          <div className={styles.text}>Receipt validated successfully</div>
          <div>
            <CheckCircleOutlineIcon className={styles.successIcon} />
          </div>
        </div>
      }
    >
      <div className={styles.container}>
        <Box>
          <h1 className={styles.subtitle}>
            Your receipt has been validated successfully.
          </h1>
          <h2>Transaction details:</h2>
          <div className={styles.detailsRow}>
            <span>Receipt id</span>
            <span>{id}</span>
          </div>
          <div className={styles.detailsRow}>
            <span>Date</span>
            <span>{createdOn}</span>
          </div>
          <div className={styles.detailsRow}>
            <span>Buyer name</span>
            <span>{buyerName}</span>
          </div>
          <div className={styles.detailsRow}>
            <span>Buyer email</span>
            <span>{buyerEmail}</span>
          </div>
          <div className={styles.detailsRow}>
            <span>Buyer phone</span>
            <span>{buyerPhone}</span>
          </div>
          {payerSubject && (
            <div className={styles.detailsRow}>
              <span>Buyer subject</span>
              <span>{payerSubject}</span>
            </div>
          )}
          {payerRemarks && (
            <div className={styles.detailsRow}>
              <span>Buyer remarks</span>
              <span>{payerRemarks}</span>
            </div>
          )}
          <div className={styles.detailsRow}>
            <span>Amount</span>
            <span>
              {totalAmount}
              {currency}
            </span>
          </div>
        </Box>
      </div>
    </MainContainer>
  );
};
export default ValidateSuccess;
