export const RoutesPaths = {
  ROOT: '/',
  DIGITAL_RECEIPTS: '/order',
  LOGIN: '/login',
  ADMIN_CUSTOMER: '/admin-customer',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/secret-reset/:token',
  CHANGE_PASSWORD: '/change-password',
  SETTINGS: '/settings',
  PAYMENT_SETTINGS: '/payment-settings',
  PAY_BY_LINK: '/pay-by-link',
  PAY_BY_LINK_NEW: '/pay-by-link/new',
  PAY_BY_LINK_EDIT: '/pay-by-link/edit/:code',
  SHOP_NEW: '/shop/new',
  SETTLEMENTS: '/settlements',
  SHOPIFY_AUTHENTICATE: '/shopify-authenticate',
  DISBURSEMENTS: '/disbursements',
  REPORTS: '/reports',
  CLIENT_REPORTS: '/client-reports',
  VALIDATE_RECEIPT: '/validate-receipt',
  VALIDATE_SUCCESS: '/validate-success',
  STATUS: '/status',
  STATUS_SUCCESS: '/status/success',
  HOME: '/home',
  POS_APP: '/pos',
  RECEIPT: '/receipt',
  // TODO replace paperform link by app route when registration will be ready
  REGISTER: "/register",
  REST_API:
    'https://api.merchant.ereceipt.swiftpay.ph/api/pos/api-documentation#tag/POS/operation/postReceipt ',
};
