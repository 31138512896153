import React from "react";
import { Footer } from "../home/Footer/Footer";
import { LandingPageLayout } from "../shared/landingPageLayout/LandingPageLayout";
import styles from "./Register.module.scss";

export const Register = () => {
  const showSpinner = "1";
  const formSlug = "vmlj1fpp";
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://paperform.co/__embed.min.js';
    document.body.appendChild(script);
  }, []);

  return (
    <LandingPageLayout>
      <h3 className={styles.title}>Register merchant account</h3>
      <div className={styles.iframeWrapper}>
	<div
          data-paperform-id={formSlug}
          data-spinner={showSpinner}
          style={{height: "100%"}}
         />
      </div>
    </LandingPageLayout>
  );
};
