import { Card } from "../Card/Card";
import receiptImage from "../../../assets/images/validateReceiptImage.png";
import NxButton from "../../shared/nxButton/NxButton";
import { CardLabel } from "../CardLabel/CardLabel";
import styles from "../Card/Card.module.scss";
import { Link } from "react-router-dom";
import { RoutesPaths } from "../../../routes/routes.paths";

export const ReceiptCard = () => {
  return (
    <Card img={receiptImage}>
      <CardLabel>For Individuals</CardLabel>
      <div className={styles.textWrapper}>
        <h2>Verify your e-receipt</h2>
        <p>Ensure transaction accuracy, and simplify financial tracking.</p>
      </div>
      <Link to={RoutesPaths.VALIDATE_RECEIPT} className={styles.buttonWrapper}>
        <NxButton>Verify e-receipt</NxButton>
      </Link>
    </Card>
  );
};
