import { Payment } from './../components/payments/Payment.model';
import ChangePassword from '../components/change-password/ChangePassword';
import Disbursements from '../components/disbursements/Disbursements';
import ForgotPassword from '../components/forgot-password/ForgotPassword';
import Login from '../components/login/Login';
import PayByLinkNewEdit from '../components/pay-by-link/new/PayByLinkNewEdit';
import PayByLink from '../components/pay-by-link/PayByLink';
import Payments from '../components/payments/Payments';
import ResetPassword from '../components/reset-password/ResetPassword';
import Settings from '../components/settings/Settings';
import CreateShop from '../components/shop/CreateShop';
import ShopifyAuthenticate from '../components/shopify-authenticate/ShopifyAuthenticate';
import Transfers from '../components/transfers/Transfers';
import { KeyedRouteProps } from './routes.model';
import { RoutesPaths } from './routes.paths';
import Orders from '../components/orders/Orders';
import Customer from '../components/admin/customerAdmin/Customer';
import ValidateReceipt from '../components/ValidateReceipt/ValidateReceipt';
import ValidateSuccess from '../components/ValidateReceipt/ValidateSuccess/ValidateSuccess';
import Status from '../components/status/Status';
import StatusSuccess from '../components/status/StatusSuccess/StatusSuccess';
import { MerchantSettings } from '../components/settings/merchant/MerchantSettings';
import { PaymentSettings } from '../components/paymentSettings/PaymentSettings';
import Home from '../components/home/Home';
import { Pos } from '../components/pos/Pos';
import { HtmlReceipt } from '../components/htmlReceipt/HtmlReceipt';
import { Register } from "../components/register/Register";

export const ROUTES: KeyedRouteProps[] = [
  {
    path: RoutesPaths.ROOT,
    key: 'ROOT',
    exact: true,
    secured: true,
    component: Payments,
  },
  {
    path: RoutesPaths.RECEIPT,
    key: 'RECEIPT',
    exact: true,
    secured: false,
    component: HtmlReceipt,
  },
  {
    path: RoutesPaths.DIGITAL_RECEIPTS,
    key: 'ORDERS',
    exact: true,
    secured: true,
    component: Orders,
  },
  {
    path: RoutesPaths.ADMIN_CUSTOMER,
    key: 'ADMIN_CUSTOMER',
    exact: true,
    secured: true,
    component: Customer,
  },
  {
    path: RoutesPaths.REPORTS,
    key: 'REPORTS',
    exact: true,
    secured: true,
    component: Payments,
  },
  {
    path: RoutesPaths.HOME,
    key: 'HOME',
    exact: true,
    secured: false,
    component: Home,
  },
  {
    path: RoutesPaths.LOGIN,
    key: 'LOGIN',
    exact: true,
    secured: false,
    component: Login,
  },
  {
    path: RoutesPaths.FORGOT_PASSWORD,
    key: 'FORGOT_PASSWORD',
    exact: true,
    secured: false,
    component: ForgotPassword,
  },
  {
    path: RoutesPaths.RESET_PASSWORD,
    key: 'RESET_PASSWORD',
    exact: true,
    secured: false,
    component: ResetPassword,
  },
  {
    path: RoutesPaths.CHANGE_PASSWORD,
    key: 'CHANGE_PASSWORD',
    exact: true,
    secured: true,
    component: ChangePassword,
  },
  {
    path: RoutesPaths.SETTINGS,
    key: 'SETTINGS',
    exact: true,
    secured: true,
    component: MerchantSettings,
  },
  {
    path: RoutesPaths.PAYMENT_SETTINGS,
    key: 'PAYMENT_SETTINGS',
    exact: true,
    secured: true,
    component: PaymentSettings,
  },
  {
    path: RoutesPaths.PAY_BY_LINK,
    key: 'PAY_BY_LINK',
    exact: true,
    secured: true,
    component: PayByLink,
  },
  {
    path: RoutesPaths.PAY_BY_LINK_NEW,
    key: 'PAY_BY_LINK_NEW',
    exact: true,
    secured: true,
    component: PayByLinkNewEdit,
  },
  {
    path: RoutesPaths.PAY_BY_LINK_EDIT,
    key: 'PAY_BY_LINK_EDIT',
    exact: true,
    secured: true,
    component: PayByLinkNewEdit,
  },
  {
    path: RoutesPaths.SHOP_NEW,
    key: 'SHOP_NEW',
    exact: true,
    secured: true,
    component: CreateShop,
  },
  // {
  //   path: RoutesPaths.SETTLEMENTS,
  //   key: 'SETTLEMENTS',
  //   exact: true,
  //   secured: true,
  //   component: Transfers
  // },
  {
    path: RoutesPaths.DISBURSEMENTS,
    key: 'DISBURSEMENTS',
    exact: true,
    secured: true,
    isEnableDisbursementRequired: true,
    component: Disbursements,
  },
  {
    path: RoutesPaths.SHOPIFY_AUTHENTICATE,
    key: 'SHOPIFY_AUTHENTICATE',
    exact: true,
    secured: false,
    component: ShopifyAuthenticate,
  },
  {
    path: RoutesPaths.VALIDATE_RECEIPT,
    key: 'VALIDATE_RECEIPT',
    exact: true,
    secured: false,
    component: ValidateReceipt,
  },
  {
    path: RoutesPaths.VALIDATE_SUCCESS,
    key: 'VALIDATE_SUCCESS',
    exact: true,
    secured: false,
    component: ValidateSuccess,
  },
  {
    path: RoutesPaths.STATUS,
    key: 'STATUS',
    exact: true,
    secured: false,
    component: Status,
  },
  {
    path: RoutesPaths.STATUS_SUCCESS,
    key: 'STATUS_SUCCESS',
    exact: true,
    secured: false,
    component: StatusSuccess,
  },
  {
    path: RoutesPaths.POS_APP,
    key: 'POS_APP',
    exact: true,
    secured: false,
    component: Pos,
  },
  {
    path: RoutesPaths.REGISTER,
    key: "REGISTER",
    exact: true,
    secured: false,
    component: Register,
  },
];
