import React, { ReactElement, useState, useContext, useEffect } from "react";
import { ReactComponent as SignInIllustration } from "../../assets/images/sign-in-illustration.svg";
import { ReactComponent as Logo } from "../../assets/images/swiftpay-logo.svg";
import { TransHelper } from "../../utils/trans-helper";
import Box from "../shared/box/Box";
import MainContainer from "../shared/main-container/MainContainer";
import {
  BannerMessage,
  BannerType,
} from "../shared/main-container/MainContainer.model";
import LoginForm from "./login-form/LoginForm";
import styles from "./Login.module.scss";
import { SecurityContext } from "../../App";
import { useTranslation } from "react-i18next";
import { LandingPageLayout } from "../shared/landingPageLayout/LandingPageLayout";
import { LandingPageLeftRight } from "../shared/landingPageLayout/LandingPageLeftRight";

import signInAsMerchantImage from "../../assets/images/merchantPhone.svg";

export const PrefixTrans = TransHelper.getPrefixedTrans("LOGIN");

export default function Login(): ReactElement {
  const [error, setError] = useState<BannerMessage>();
  const { isLogged, logoutReason } = useContext(SecurityContext);
  const [sessionError, setSessionError] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLogged && logoutReason == t("LOGIN.SESSION_EXPIRED_ERROR")) {
      setSessionError(true);
    } else {
      setSessionError(false);
    }
  }, [isLogged]);

  return (
    <LandingPageLayout
    // bannerMessage={error}
    >
      <LandingPageLeftRight
        left={
          <>
            <div className={styles.loginForm}>
              <div className={styles.boxHeader}>
                <PrefixTrans>LOGIN_TO_YOUR_ACCOUNT</PrefixTrans>
              </div>
              <LoginForm
                onError={(message): void =>
                  setError({ message, type: BannerType.ERROR })
                }
              />
            </div>
          </>
        }
        right={
          <div className={styles.rightWrapper}>
            <img src={signInAsMerchantImage} />
            <h2>Philippine E-receipt</h2>
            <p>Digitally transforming receipt processing for businesses.</p>
          </div>
        }
      />
      {/* {
        sessionError && <div className={styles.errorBanner}>
          <PrefixTrans>SESSION_EXPIRED</PrefixTrans>
        </div>
      }
       */}
    </LandingPageLayout>
  );
}
