import {ReactElement} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styles from './DisbursementCard.module.scss';
import NxLoader, {
  NxLoaderVariant,
  NxLoaderColorVariant
} from '../../shared/nxLoader/NxLoader';

interface DisbursementCardProps {
  title: string;
  count: number | string;
  loading: boolean;
}

export const DisbursementCard = ({title, count, loading}: DisbursementCardProps): ReactElement => (
  <Card variant='outlined' className={styles.disbursementCard}>
    <CardContent className={styles.disbursementCardContent}>
      <NxLoader variant={NxLoaderVariant.SMALL}
        colorVariant={NxLoaderColorVariant.TEXT}
        loaded={!loading}>
        <>
          <div className={styles.number}>{count}</div>
          <div className={styles.title}>{title}</div>
        </>
      </NxLoader>
    </CardContent>
  </Card>
);

export default DisbursementCard;
