import dayjs, {Dayjs} from 'dayjs';
import {EnvironmentType} from '../../shared/model/EnvironmentType.model';
import {FilterCriteria} from '../../shared/model/Filter.model';
import {PagedResponse} from '../../shared/model/Page.model';
import {PageCriteria} from '../../shared/model/PagedCriteria.model';
import {DisbursementStatus} from '../../shared/model/DisbursementStatus.model';

export type DisbursementTO<T_DATE = string, T_CHANNEL = string, T_STATUS = string> = {
  id: string;
  shortId?: string;
  merchantId: number;
  merchantReferenceNo: string;
  channel: T_CHANNEL;
  channelReferenceNo: string;
  status: string;
  disbursementStatus: T_STATUS;
  destination: string;
  institutionCode: string;
  institutionName?: string;
  amount: number;
  registrationTime: T_DATE;
  settlementTime?: T_DATE;
};

export enum DisbursementChannel {
  PESONET = 'PESONET',
  INSTAPAY = 'INSTAPAY'
}

export type Disbursement = DisbursementTO<Dayjs, DisbursementChannel, DisbursementStatus>;

export const fromDisbursementTo = (disbursementTO: DisbursementTO): Disbursement => ({
  ...disbursementTO,
  disbursementStatus: DisbursementStatus[disbursementTO.status],
  channel: DisbursementChannel[disbursementTO.channel],
  destination: disbursementTO.channel ? `${disbursementTO.channel} - ${disbursementTO.institutionName || disbursementTO.institutionCode}` : '',
  shortId: disbursementTO.id.slice(-10),
  registrationTime: dayjs(disbursementTO.registrationTime),
  settlementTime: disbursementTO.settlementTime ? dayjs(disbursementTO.settlementTime) : undefined
});

export type DisbursementsResponse = PagedResponse<DisbursementTO>;

export type DisbursementStatistics = {
  averageAmount: number;
  totalAmount: number;
  totalCount: number;
};

export type DisbursementRequestParams = PageCriteria & FilterCriteria<DisbursementStatus> & {
  env: EnvironmentType;
  phrase?: string;
};

export type AccountBalance = {
  balance: number;
};

export type AccountBalanceReqParams = {
  merchantId: number;
};