import {Checkbox} from '@material-ui/core';
import clsx from 'clsx';
import {Field} from 'formik';
import React, {ChangeEvent, ReactElement, useRef} from 'react';
import {logMissingOnChange} from '../inputError';
import {Nil, NxFormikProps} from '../../../shared/model/NxComponents.model';
import styles from './NxCheckbox.module.scss';

export interface NxCheckboxProps {
  checked?: boolean | null;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  name?: string;
  onBlur?: (e: ChangeEvent) => void;
  onChange?: (e: ChangeEvent, checked: boolean) => void;
  translucentUncheckedRow?: boolean;
}

const calculateValue = (newChecked: boolean | Nil, previousChecked: boolean | Nil): boolean => {
  if (newChecked === null) {
    return previousChecked ?? false;
  }

  return !!newChecked;
};

const NxCheckbox = (
  {
    checked,
    children,
    className = '',
    disabled = false,
    name,
    onBlur,
    onChange,
    translucentUncheckedRow = true
  }: NxCheckboxProps
): ReactElement => {

  const checkboxClasses = {root: styles.checkbox};
  const rowClassName = clsx(
    className,
    {
      [styles.row]: children,
      [styles.row_translucent]: children && translucentUncheckedRow && !checked
    }
  );

  const lastValue = useRef(checked);
  const finalChecked = calculateValue(checked, lastValue.current);
  lastValue.current = finalChecked;

  const handleChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    lastValue.current = checked;

    if (!onChange) {
      logMissingOnChange('Checkbox', name);
      return;
    }

    onChange(event, checked);
  };

  return (
    <div className={rowClassName}>
      <Checkbox classes={checkboxClasses}
                checked={finalChecked}
                disabled={disabled}
                onBlur={onBlur}
                onChange={handleChange}
                name={name} />
      {children}
    </div>
  );
};

export default NxCheckbox;

export type NxFormikCheckboxProps = Omit<NxCheckboxProps, NxFormikProps> & {
  name: string;
  validate?: (checked: boolean) => string | void | Promise<string | void>;
};

export const NxFormikCheckbox = (props: NxFormikCheckboxProps): ReactElement =>
  <Field type='checkbox' as={NxCheckbox} {...props} />;