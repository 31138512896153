import dayjs, { Dayjs } from "dayjs";
import { EnvironmentType } from "../../../shared/model/EnvironmentType.model";
import { FilterCriteria } from "../../../shared/model/Filter.model";
import { PagedResponse } from "../../../shared/model/Page.model";
import { PageCriteria } from "../../../shared/model/PagedCriteria.model";

export enum CustomerStatus {
  INCOMPLETE = "INCOMPLETE",
  NEW = "NEW",
  WAITING = "WAITING",
  ACTIVATED = "ACTIVATED",
}

export type OrderTO = {
  orderNumber: string;
  createdOn: Date;
  totalReceiptsCount: number;
  minReceiptId?: string;
  maxReceiptId?: string;
  status: CustomerStatus;
  orderedBy: string;
};

export type OrdersResponse = PagedResponse<OrderTO>;

export type OrderStatistics = {
  averageAmount: number;
  totalAmount: number;
  totalCount: number;
};

export type OrderRequestParams = PageCriteria &
  FilterCriteria<CustomerStatus> & {
    env: EnvironmentType;
    phrase?: string;
  };

export type OrderCallbackDetailsTO<T_DATE = string> = {
  errorMessage: string;
  payload: {
    attributes: Map<string, string>;
    callbackType: string;
    callbackUrl: string;
    id: string;
    merchantId: number;
  };
  responseCode: string;
  success: boolean;
  timestamp: T_DATE;
};

export const fromOrderCallbackDetailsTO = (
  OrderCallbackDetailsTO: OrderCallbackDetailsTO
): OrderCallbackDetails => ({
  ...OrderCallbackDetailsTO,
  timestamp: dayjs(OrderCallbackDetailsTO.timestamp),
});

export type OrderCallbackDetails = OrderCallbackDetailsTO<Dayjs>;
