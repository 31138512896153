import React, {ReactElement, useState} from 'react';
import {NxFormikInput} from '../shared/nxInput/NxFormikInput';
import styles from './ForgotPassword.module.scss';
import {useTranslation} from 'react-i18next';
import {TransHelper} from '../../utils/trans-helper';
import {BannerMessage} from '../shared/main-container/MainContainer.model';
import Box from '../shared/box/Box';
import {object, SchemaOf, string} from 'yup';
import {ReactComponent as ForgotPasswordIcon} from '../../assets/images/forgot-password.svg';
import {ReactComponent as ResetSentIcon} from '../../assets/images/reset-sended.svg';
import MainContainer from '../shared/main-container/MainContainer';
import NxButton from '../shared/nxButton/NxButton';
import {Formik, FormikProps} from 'formik';
import {RESET_URL} from '../../shared/constants/api-urls';
import usePost from '../../shared/hooks/use-post.hook';
import {Button, Grid} from '@material-ui/core';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

export const PrefixTrans = TransHelper.getPrefixedTrans('FORGOT_PASSWORD');

export interface IForgotPassword {
  email: string;
}

export default function ForgotPassword(): ReactElement {

  const [error, setError] = useState<BannerMessage>();
  const [sent, sentSent] = useState<boolean>(false);
  const {t} = useTranslation();
  const resetPassword = usePost(RESET_URL);

  const submit = async (
    values: IForgotPassword
  ): Promise<void> => {
    await resetPassword(null, new URLSearchParams({email: values.email}))
      .then((): void => {
        sentSent(true);
      })
      .catch((error): void => {
        setError(error);
      });
  };

  const ResetForm = ({
                       handleSubmit,
                       isSubmitting
                     }: FormikProps<IForgotPassword>): ReactElement => (
    <form onSubmit={handleSubmit} className={styles.formWrapper}>
      <NxFormikInput className={classNames(styles.input, styles['input--center'])}
                     name='email'
                     label={<PrefixTrans>EMAIL</PrefixTrans>}
                     type='email' />
      <div className={styles.btnWrapper}>
        <NxButton type='submit'
                  className={styles.button}
                  loaded={!isSubmitting}>
          <PrefixTrans>SEND</PrefixTrans>
        </NxButton>
      </div>
    </form>
  );

  const forgotPasswordSchema: SchemaOf<IForgotPassword> = object({
    email: string().email()
      .required(t('SHARED.FIELD_REQUIRED'))
  }).defined();

  return (
    <MainContainer bannerMessage={error}>
      <Grid container spacing={2} justifyContent={'center'}>
        <Grid item xs={5}>
          {
            !sent
              ? <Box className={styles.container}>
                <ForgotPasswordIcon />
                <h2><PrefixTrans>HEADER</PrefixTrans></h2>
                <div className={styles.description}>
                  <PrefixTrans>DESCRIPTION</PrefixTrans>
                </div>
                <Formik<IForgotPassword> initialValues={{email: ''}}
                                         onSubmit={submit}
                                         validateOnMount={false}
                                         validateOnBlur={false}
                                         validateOnChange={false}
                                         validationSchema={forgotPasswordSchema}>
                  {ResetForm}
                </Formik>
              </Box>
              : successMessage()
          }
        </Grid>
      </Grid>
    </MainContainer>
  );
}

const successMessage = (): ReactElement => (
  <Box className={styles.container}>
    <ResetSentIcon />
    <h2><PrefixTrans>HEADER_SUCCESS</PrefixTrans></h2>
    <p className={styles.description}>
      <PrefixTrans>DESCRIPTION_SUCCESS</PrefixTrans>
    </p>
    <Link to='/' className={styles.button}>
      <Button variant='contained' color='primary'>
        <PrefixTrans>BTN_SUCCESS</PrefixTrans>
      </Button>
    </Link>
  </Box>
);
