import { ReactElement, useContext, useEffect, useState } from 'react';
import { MerchantContext, SecurityContext } from '../../../App';
import { Formik, FormikProps } from 'formik';
import styles from './MerchantSettings.module.scss';
import { Divider, Grid } from '@material-ui/core';
import { NxFormikInput } from '../../shared/nxInput/NxFormikInput';
import MainContainer from '../../shared/main-container/MainContainer';
import NxButton from '../../shared/nxButton/NxButton';
import { number, object, SchemaOf, string } from 'yup';
import useGet from '../../../shared/hooks/use-get.hook';
import { MERCHANTS_URL } from '../../../shared/constants/api-urls';
import { Merchant } from '../../../shared/model/Merchant.model';
import NxLoader from '../../shared/nxLoader/NxLoader';
import clsx from 'clsx';
import usePut from '../../../shared/hooks/use-put.hook';

type MerchantSettingsFields = {
  merchantId: string;
  recipientAddress: string;
  barangay: string;
  city: string;
  province: string;
  zipCode: string;
};

const formValueFormatter = (value: any) => {
  if (value === null) {
    return '';
  }
  return value;
};

const MerchantSettingsFormSchema: SchemaOf<MerchantSettingsFields> =
  object().shape({
    merchantId: string().defined(),
    recipientAddress: string().required(),
    barangay: string().required(),
    city: string().required(),
    province: string().required(),
    zipCode: string().required(),
  });

const DEFAULT_MERCHANT_DATA: MerchantSettingsFields = {
  barangay: '',
  city: '',
  merchantId: '',
  recipientAddress: '',
  province: '',
  zipCode: '',
};

export const MerchantSettings = () => {
  const { currentMerchantId } = useContext(MerchantContext);
  const [userDetails, setUserDetails] = useState<any>(DEFAULT_MERCHANT_DATA);
  const getCurrentUser = useGet<Merchant>(
    `${MERCHANTS_URL}/${currentMerchantId}`
  );
  const updateMerchant = usePut<Merchant, MerchantSettingsFields>(
    `${MERCHANTS_URL}/${currentMerchantId}`
  );
  useEffect(() => {
    async function callUserDetails(): Promise<any> {
      const userData = await getCurrentUser();
      setUserDetails(userData);
    }
    callUserDetails();
  }, [currentMerchantId]);

  const handleOnSubmit = async (values: MerchantSettingsFields) => {
    await updateMerchant({
      ...values,
    });
  };

  return (
    <MainContainer>
      <NxLoader loaded={userDetails?.id !== '' && userDetails.id !== undefined}>
        <Grid container className={styles.root}>
          <Grid item xs={6} className={styles.container}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                merchantId: formValueFormatter(userDetails?.id),
                recipientAddress: formValueFormatter(
                  userDetails?.recipientAddress
                ),
                barangay: formValueFormatter(userDetails?.barangay),
                city: formValueFormatter(userDetails?.city),
                province: formValueFormatter(userDetails?.province),
                zipCode: formValueFormatter(userDetails?.zipCode),
              }}
              onSubmit={handleOnSubmit}
              validateOnMount={true}
              validateOnBlur={true}
              validationSchema={MerchantSettingsFormSchema}
            >
              {({
                handleSubmit,
                isSubmitting,
                isValid,
              }: FormikProps<MerchantSettingsFields>): ReactElement => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid item>
                      <h3>Organisation details</h3>
                    </Grid>
                    <div className={styles.inputWrapper}>
                      <NxFormikInput
                        label='Merchant ID'
                        name='merchantId'
                        disabled
                      />
                    </div>
                    <div className={styles.inputWrapper}>
                      <NxFormikInput
                        label='Organisation address'
                        name='recipentAddress'
                        id='organisationAddress'
                      />
                    </div>
                    <div className={styles.inputWrapper}>
                      <NxFormikInput label='Barangay' name='barangay' />
                    </div>
                    <div className={styles.inputWrapper}>
                      <NxFormikInput label='City' name='city' />
                    </div>
                    <div className={styles.inputWrapper}>
                      <NxFormikInput label='Province' name='province' />
                    </div>
                    <div className={styles.inputWrapper}>
                      <NxFormikInput label='Zipcode' name='zipCode' />
                    </div>
                    <Divider />
                    <Grid container justifyContent='flex-end'>
                      <NxButton
                        type='submit'
                        disabled={!isValid}
                        loaded={!isSubmitting}
                      >
                        Save changes
                      </NxButton>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </Grid>
          <Grid
            item
            xs={5}
            className={clsx(styles.container, styles.shrinkContainer)}
          >
            <Grid item>
              <h3>Deactivate account</h3>
              <div className={styles.description}>
                Deactivating pauses your profile and access, with potential loss
                of some data. Reactivation available anytime.
              </div>
              <NxButton>Deactivate account</NxButton>
            </Grid>
          </Grid>
        </Grid>
      </NxLoader>
    </MainContainer>
  );
};
