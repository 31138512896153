import clsx from 'clsx';
import { StatusTypes, statusMapper } from '../../../shared/utils/statusMapper';
import styles from './status.module.scss';

export const StatusField = ({ status }: { status: StatusTypes }) => {
  const statusObject = statusMapper(status);

  const statusStyles = clsx(styles.background, {
    [styles.success]: statusObject.type === 'SUCCESS',
    [styles.error]: statusObject.type === 'ERROR',
    [styles.neutral]: statusObject.type === 'DEFAULT',
  });

  return (
    <div className={statusStyles}>
      {statusObject.value.charAt(0).toUpperCase() + statusObject.value.slice(1).toLowerCase()}
    </div>
  );
};
