import { Grid } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { GridRowParams } from "@material-ui/data-grid";
import { AxiosResponse } from "axios";
import { Tooltip } from "react-tooltip";
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { MerchantContext, SecurityContext } from "../../App";
import {
  ADMIN_ORDERS_URL,
  INSTITUTIONS_URL,
  ORDERS_URL,
  PAYMENTS_SUMMARY_URL,
} from "../../shared/constants/api-urls";
import useGet, { useGetRaw } from "../../shared/hooks/use-get.hook";
import { EnvironmentType } from "../../shared/model/EnvironmentType.model";
import { PageInfo } from "../../shared/model/Page.model";
import { ReactComponent as StatsIcon } from "../../assets/images/stats.svg";
import { ReactComponent as ReceiptIcon } from "../../assets/images/receipt.svg";
import { ReactComponent as TooltipIcon } from "../../assets/images/tooltip.svg";

import { filterFactory } from "../../shared/utils/filterFactory";
import { TransHelper } from "../../utils/trans-helper";
import Filters, { FilterParams } from "./OrderFilter/Filters";
import MainContainer from "../shared/main-container/MainContainer";
import NxButton from "../shared/nxButton/NxButton";
import NxInput from "../shared/nxInput/NxInput";
import NxLoader, { NxLoaderVariant } from "../shared/nxLoader/NxLoader";
import { NxSelectOption } from "../shared/nxSelect/NxSelect";
import DataTable from "../shared/table/Table";
import styles from "./Order.module.scss";
import { ordersTableColumnDefinitions } from "./OrdersTableColumnDefinitions";
import { SettlementInstitution } from "../../shared/model/SettlementInstitution.model";
import { OrderRequestParams, OrdersResponse, OrderTO } from "./Order.model";
import { OrderNewBatch } from "./orderNewBatch/OrderBatch";
import usePut from "../../shared/hooks/use-put.hook";
import { DEFAULT_TABLE_PAGE_SIZE } from "../../shared/constants/app-constants";
import {
  DEFAULT_DATE_TIME_FROM,
  DEFAULT_DATE_TIME_TO,
} from "../../utils/time-utils";
import { OrderStatusEnum } from "../../shared/constants/receipt-status";
import { Details } from "../shared/details/Details";
import EreceiptDetailsContent from "./orderDetails/OrderDetailsContent";
import { useLocation } from "react-router";
import TableFilters from "../shared/table/TableFilters";
import { getFilterSearchParams } from "../../utils/search-helpers";
import { OrderStatistic } from "../payments/Payment.model";
import Box from "../shared/box/Box";
import clsx from "clsx";
import OrderNewBatchModal from "./orderNewBatch/NewBatchModal";

export const PrefixTrans = TransHelper.getPrefixedTrans("ORDERS");
const SharedTrans = TransHelper.getPrefixedTrans("SHARED");

export default function Orders(): ReactElement {
  const { t } = useTranslation();
  const [institutions, setInstitutions] = useState<SettlementInstitution[]>();
  const [payments, setPayments] = useState<Array<OrderTO>>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [orderStatistics, setOrderStatistics] = useState<OrderStatistic>();
  const { search } = useLocation();
  const [env, setEnv] = useState<EnvironmentType>(EnvironmentType.PRODUCTION);
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [openPlaceNewOrder, setOpenPlaceNewOrder] = useState<boolean>(false);
  const [detailsId, setDetailsId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingStats, setLoadingStats] = useState<boolean>(true);
  const [envError, setEnvError] = useState<boolean>(false);
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);
  const { currentMerchantId } = useContext(MerchantContext);
  const { isAdmin } = useContext(SecurityContext);
  const confirmOrderPayment = usePut(
    `${ORDERS_URL}/${detailsId}/payment-confirmation`
  );
  const [orderRequestParams, setOrderRequestParams] = useState<
    OrderRequestParams | undefined
  >({
    pageNo: 0,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    merchantId: currentMerchantId,
    dateFrom: DEFAULT_DATE_TIME_FROM,
    dateTo: DEFAULT_DATE_TIME_TO,
    status: OrderStatusEnum.NEW,
    env,
  });

  const USER_ORDERS_URL = isAdmin ? ADMIN_ORDERS_URL : ORDERS_URL;
  const getOrders = useGet<OrdersResponse>(USER_ORDERS_URL);
  const getOrdersSummary = useGet<OrderStatistic>(PAYMENTS_SUMMARY_URL);
  const getInstitutions = useGet<SettlementInstitution[]>(INSTITUTIONS_URL);
  const ordersCsvRequest = useGetRaw<string>(`${ORDERS_URL}/csv`);

  const availableReceiptsLevel = useCallback(() => {
    if (!orderStatistics?.availableReceipts || !orderStatistics.totalReceipts) {
      return undefined;
    }
    const availableReceipts =
      1 - orderStatistics?.availableReceipts / orderStatistics?.totalReceipts;
    if (availableReceipts <= 0.3) {
      return "CRITICAL";
    }
    if (availableReceipts <= 0.7) {
      return "BAD";
    }
    return "GOOD";
  }, [orderStatistics]);

  const updateData = useCallback(async () => {
    if (!orderRequestParams) {
      return;
    }

    setLoadingStats(true);
    setLoading(true);
    setEnvError(false);

    if (!isAdmin) {
      await getOrdersSummary(filterFactory(orderRequestParams))
        .then(setOrderStatistics)
        .catch(() => setOrderStatistics(undefined));
      setLoadingStats(false);
    }

    // fetch table data
    await getOrders(filterFactory(orderRequestParams))
      .then((response: OrdersResponse) => {
        setPageInfo(response);
        setPayments(
          response.result.map((order) => ({
            id: order.orderNumber,
            ...order,
          }))
        );
      })
      .catch((): void => {
        setPageInfo({
          pageNo: 0,
          resultCount: 0,
          totalCount: 0,
        });
        setPayments([]);
        setEnvError(true);
        setDetailsId(null);
      });
    setLoading(false);
  }, [orderRequestParams]);

  useEffect(() => {
    getInstitutions(new URLSearchParams({ env })).then(setInstitutions);
  }, [env]);

  useEffect(() => {
    if (institutions) {
      updateData();
    }
  }, [orderRequestParams, institutions]);

  useEffect(() => {
    const params = getFilterSearchParams<OrderStatusEnum>(search);
    if (currentMerchantId) {
      setOrderRequestParams((previousReqParams?: OrderRequestParams) => ({
        ...previousReqParams,
        pageNo: 0,
        merchantId: currentMerchantId,
        env,
        ...params,
      }));
    }
  }, [search]);

  const handlePageChange = (pageNo: number): void => {
    if (currentMerchantId) {
      setOrderRequestParams((previousReqParams?: OrderRequestParams) => ({
        ...previousReqParams,
        pageNo: pageNo,
        merchantId: currentMerchantId,
        env,
      }));
    }
  };

  const handleDownloadCsv = async (): Promise<void> => {
    if (!orderRequestParams) {
      return;
    }
    setDownloadInProgress(true);
    await ordersCsvRequest(filterFactory(orderRequestParams)).then(
      (response: AxiosResponse<string>) => {
        // As filename is included in header:
        // content-disposition: attachment; filename=swiftpay-payments-1650022658434.csv
        const contentDispositionHeaderValue: string =
          response.headers["content-disposition"];
        const fileName = contentDispositionHeaderValue.split("filename=")[1];
        const data = new Blob([response.data], { type: "text/csv" });
        const csvURL = window.URL.createObjectURL(data);
        const tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName);
        tempLink.click();
      }
    );
    setDownloadInProgress(false);
  };

  const toggleDetails = (open: boolean, params?): void => {
    if (params) {
      setDetailsId(params.id.toString());
    } else {
      setDetailsId(null);
    }
    setOpenDetails(open);
  };

  const handleFinishOrderWithOpenDetails = () => {
    setOpenDetails(true);
    setOpenPlaceNewOrder(false);
  };

  const getStatusOptions = (): NxSelectOption[] =>
    Object.keys(OrderStatusEnum).map(
      (key) =>
        ({
          label: t(`SHARED.ORDER.STATUS.${OrderStatusEnum[key]}`),
          value: OrderStatusEnum[key],
        } as NxSelectOption)
    );

  const handleOrderCreate = () => {
    updateData();
  };

  const handlePaymentConfirm = async () => {
    await confirmOrderPayment();
    updateData();
  };

  return (
    <>
      <Drawer anchor={"right"} open={openDetails}>
        {detailsId?.length ? (
          <Details
            id={detailsId}
            url={USER_ORDERS_URL}
            env={env}
            onClose={(): void => toggleDetails(false)}
            onConfirm={handlePaymentConfirm}
          >
            <EreceiptDetailsContent />
          </Details>
        ) : (
          <div>Error</div>
        )}
      </Drawer>
      {/* <Drawer anchor='right' open={openPlaceNewOrder}>
        <OrderNewBatch
          onClose={() => setOpenPlaceNewOrder(false)}
          onConfirm={handleOrderCreate}
        />
      </Drawer> */}
      <OrderNewBatchModal
        isOpen={openPlaceNewOrder}
        onClose={() => setOpenPlaceNewOrder(false)}
        onConfirm={handleOrderCreate}
        onFinish={handleFinishOrderWithOpenDetails}
        setNewOrderId={setDetailsId}
      />
      <MainContainer>
        <Grid
          className={styles.pageHeader}
          container
          direction="row"
          justifyContent={"space-between"}
        >
          {isAdmin ? (
            <div className={styles.title}>
              <PrefixTrans>TITLE</PrefixTrans>
            </div>
          ) : (
            <div className={styles.title}>
              <PrefixTrans>TITLE_MERCHANT</PrefixTrans>
              {!isAdmin && (
                <NxButton onClick={() => setOpenPlaceNewOrder(true)}>
                  Order new Receipts
                </NxButton>
              )}
            </div>
          )}
        </Grid>
        {!isAdmin && (
          <Grid
            direction="row"
            justifyContent="space-between"
            container
            spacing={2}
            className={styles.summaryBoxContainer}
          >
            <Grid xs={6} item>
              <Box className={styles.summaryBox}>
                <NxLoader loaded={!!orderStatistics?.maxId}>
                  <div className={styles.headline}>
                    <div className={styles.boxTitle}>
                      <StatsIcon />
                      <div>Active numbers</div>
                    </div>
                    <div>
                      <a
                        className="my-anchor-element"
                        data-tooltip-id="active-numbers-tooltip"
                        data-tooltip-content="Hello world2!"
                        data-tooltip-place="top"
                      >
                        <TooltipIcon />
                      </a>
                    </div>
                  </div>
                  <div className={styles.details}>
                    From <p>{orderStatistics?.minId}</p>to{" "}
                    <p>{orderStatistics?.maxId}</p>
                  </div>
                </NxLoader>
              </Box>
            </Grid>
            <Grid xs={6} item>
              <Box className={styles.summaryBox}>
                <NxLoader loaded={!!orderStatistics?.maxId}>
                  <div className={styles.headline}>
                    <div className={styles.boxTitle}>
                      <ReceiptIcon />
                      <div>Available receipts</div>
                    </div>
                    <div>
                      <a
                        className="my-anchor-element"
                        data-tooltip-id="available-numbers-tooltip"
                        data-tooltip-content="Hello world!"
                        data-tooltip-place="top"
                      >
                        <TooltipIcon />
                      </a>
                    </div>
                  </div>

                  <div
                    className={clsx(styles.details, styles.detailsRow)}
                    style={{ alignItems: "center" }}
                  >
                    {orderStatistics?.totalReceipts &&
                      orderStatistics.availableReceipts && (
                        <>
                          <div className={styles.detailsRow}>
                            <p>
                              {orderStatistics?.totalReceipts -
                                orderStatistics?.availableReceipts}
                            </p>
                            /
                            <p className={styles.grayed}>
                              {orderStatistics?.totalReceipts} pcs.
                            </p>
                          </div>
                          <div
                            className={clsx(
                              {
                                [styles.good]:
                                  availableReceiptsLevel() === "GOOD",
                                [styles.bad]:
                                  availableReceiptsLevel() === "BAD",
                                [styles.critical]:
                                  availableReceiptsLevel() === "CRITICAL",
                              },
                              styles.percentBox
                            )}
                          >
                            {Math.round(
                              (1 -
                                orderStatistics!.availableReceipts /
                                  orderStatistics!.totalReceipts) *
                                100
                            )}
                            %
                          </div>
                        </>
                      )}
                  </div>
                </NxLoader>
              </Box>
            </Grid>
            <Tooltip anchorSelect=".my-anchor-element" place="top" />
          </Grid>
        )}

        <Grid className={styles.tableContainer}>
          <TableFilters
            title="Transaction history"
            withDate
            withSearch
            selectOptions={getStatusOptions()}
            onDowload={handleDownloadCsv}
            isDownloading={downloadInProgress}
            defaultSelectValue={OrderStatusEnum.NEW}
          />
          <DataTable
            loading={loading}
            pageInfo={pageInfo}
            rows={payments}
            onRowClick={(param: GridRowParams) => toggleDetails(true, param)}
            onPageChange={handlePageChange}
            pageSize={orderRequestParams?.pageSize}
            columns={ordersTableColumnDefinitions(t, isAdmin)}
            noRowsMessage={
              envError
                ? t("SHARED.NO_DATA_ENV", { environmentType: env })
                : t("SHARED.NO_DATA")
            }
          />
        </Grid>
      </MainContainer>
    </>
  );
}
