import styles from './HtmlReceipt.module.scss';
import qrCode from '../../assets/images/qrcode.png';
import npo from '../../assets/images/npo.svg';
import clsx from 'clsx';
import { useLocation, useParams } from 'react-router';
import { getFormattedDateOrNullReplacement } from '../../shared/utils/valueFormatter';

export const HtmlReceipt = () => {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const amount = queryParameters.get('totalAmount');
  const receiptNo = queryParameters.get('receiptNo');
  const payorName = queryParameters.get('payerName');
  const receiptDate = queryParameters.get('receipt_date');
  const referenceNo = queryParameters.get('referenceNo');
  const address = queryParameters.get('address');

  return (
    <>
      <div className={styles.container} style={{margin: "5px 0 0 5px"}}>
        <header className={clsx(styles.header, styles.borderBottom)}>
          <div>
            <img src={npo} alt='npo logo' className={styles.image} />
          </div>
          <p className={styles.title}>
            <span>
              OFFICIAL RECEIPT <br /> (ORIGINAL)
            </span>
            <br />
            REPUBLIC OF THE PHILIPPINES
          </p>
          <img src={qrCode} alt='qr code link' className={styles.image} />
        </header>
        <main>
          <section className={styles.address}>
            <p className={styles.caption}>MUNICIPALITY OF CALAUAN</p>
            <div>
              Address: Municipal Hall A. Marfori St. Brgy. Silangan Calauan,
              Laguna, 4012
            </div>
          </section>
          <section className={clsx(styles.splitSection, styles.borderBottom)}>
            <div>
              <div className={styles.label}>RECEIPT NO:</div>
              <div className={styles.value}>{receiptNo}</div>
            </div>
            <div>
              <div className={styles.label}>DATE:</div>
              <div className={styles.value}>
                {getFormattedDateOrNullReplacement(receiptDate)}
              </div>
            </div>
          </section>
          <section>
            <div>
              <div className={styles.label}>PAYOR:</div>
              <div>{payorName}</div>
              <div>Address: {address}</div>
            </div>
          </section>
          <section className={styles.emptySection} />
          <section></section>
          <section className={clsx(styles.splitSection, styles.borderBottom)}>
            <div>
              <div className={clsx(styles.label, styles.centered)}>
                NATURE OF COLLECTION
              </div>
            </div>
            <div>
              <div className={clsx(styles.label, styles.centered)}>AMOUNT</div>
            </div>
          </section>
          <section className={clsx(styles.splitSection, styles.borderBottom)}>
            <div>
              <div className={clsx(styles.value, styles.plainValue)}>
                Reference no: {referenceNo}
              </div>
            </div>
            <div>
              <div
                className={clsx(styles.value, styles.plainValue, styles.total)}
              >
                ₱ {amount}
              </div>
            </div>
          </section>
          <section className={clsx(styles.splitSection, styles.borderBottom)}>
            <div>
              <div className={clsx(styles.value, styles.total)}>TOTAL</div>
            </div>
            <div>
              <div className={clsx(styles.value, styles.total)}>₱ {amount}</div>
            </div>
          </section>
        </main>
      </div>
      <div style={{fontSize: "8px", margin: "2px 0 0 5px"}}>
        BIR Authority to Print No: 071AU20230000003461<br/>
        BIR Accreditation No: 039MP201900000004, JAN 15, 2019<br/>
        National Printing Office, EDSA cor. NIA North Side Rd., Dilimian, Q.C.
      </div>
      <button
          onClick={window.print}
          style={{marginTop: '20px', marginLeft: '150px'}}>
        Print your receipt
      </button>
    </>
  );
};
