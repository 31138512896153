import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router';
import {SecurityContext} from '../../App';
import {RoutesPaths} from '../../routes/routes.paths';
import {HttpError, SimpleErrorBody} from '../model/Error.model';

export default function useApiCatch(): (error) => Promise<never> {
  const location = useLocation();
  const history = useHistory();
  const {t} = useTranslation();
  const {setIsLogged, setLogoutReason} = useContext(SecurityContext);

  return (error): Promise<never> => {
    if (location.pathname !== RoutesPaths.LOGIN && (error as HttpError<SimpleErrorBody>).response?.status === 401) {
      setIsLogged(false);
      setLogoutReason(t('LOGIN.SESSION_EXPIRED_ERROR'));
      history.push(RoutesPaths.LOGIN);
    }

    if (process.env.REACT_APP_LOGGING_ENABLED === 'true') {
      console.error(error);
    }

    return Promise.reject(error);
  };
}
