import DayJsUtils from "@date-io/dayjs";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import dayjs, { Dayjs } from "dayjs";
import { useField } from "formik";
import { isNil } from "lodash/fp";
import React, { ChangeEvent, ReactElement, useRef } from "react";
import { logMissingOnChange } from "../inputError";
import { Nil, NxFormikProps } from "../../../shared/model/NxComponents.model";
import {
  fieldClassName,
  inputClasses,
  labelClasses,
} from "../nxGenericTextField/NxGenericTextField";
import styles from "./NxDatePicker.module.scss";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

export const DEFAULT_DATE_PICKER_DISPLAY_FORMAT = "MM/DD/YYYY";
export const DEFAULT_DATE_PICKER_VALUE_FORMAT = "YYYY-MM-DD";

export interface NxDatePickerProps {
  className?: string;
  disabled?: boolean;
  disableToolbar?: boolean;
  error?: string;
  id?: string;
  label: React.ReactNode;
  name?: string;
  onBlur?: (e: ChangeEvent) => void;
  onChange?: (value: string | null) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: string | null;
  valueFormat?: string;
  displayFormat?: string;
  onClose?: () => void;
  placeholder?: string;
  minDate?: ParsableDate;
  maxDate?: ParsableDate;
  disableFutureDates?: boolean;
  initialView?: "year" | "date" | "month";
}

const calculateValue = (
  value: string | Nil,
  lastValue: Dayjs | null,
  valueFormat?: string
): dayjs.Dayjs | null => {
  if (isNil(value)) {
    return value === null ? null : lastValue;
  }

  const date = dayjs(value, valueFormat);

  return date.isValid() ? date : null;
};

const NxDatePicker = ({
  className,
  disabled = false,
  disableToolbar = false,
  error,
  id,
  label,
  name,
  onBlur,
  onChange: parentOnChange,
  value,
  onFocus,
  valueFormat = DEFAULT_DATE_PICKER_VALUE_FORMAT,
  displayFormat = DEFAULT_DATE_PICKER_DISPLAY_FORMAT,
  onClose,
  placeholder,
  minDate,
  maxDate,
  disableFutureDates,
  initialView,
}: NxDatePickerProps): React.ReactElement => {
  const datePickerClasses = {
    paper: styles.datePicker,
    toolbar: styles.toolbar,
  };
  const adornmentClasses = { root: styles.iconWrapper };

  const lastValue = useRef<Dayjs | null>(null);
  const finalValue = calculateValue(value, lastValue.current, valueFormat);
  lastValue.current = finalValue;

  const onChange = (value: Dayjs | null): void => {
    lastValue.current = value;

    if (!parentOnChange) {
      logMissingOnChange("DatePicker", name);
      return;
    }

    if (value === null || !value.isValid()) {
      parentOnChange(null);
      return;
    }

    const text = value.format(valueFormat);
    parentOnChange(text);
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={DayJsUtils}>
        <KeyboardDatePicker
          id={id}
          disabled={disabled}
          label={label}
          disableToolbar={disableToolbar}
          disableFuture={disableFutureDates}
          autoOk
          onClose={onClose}
          openTo={initialView || "year"}
          error={!!error}
          variant="inline"
          format={displayFormat}
          // validation should be handled by client using this component
          invalidDateMessage={null}
          maxDateMessage={null}
          minDateMessage={null}
          name={name}
          value={finalValue}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          KeyboardButtonProps={{ "aria-label": "change date" }}
          className={`${fieldClassName} ${className}`}
          InputAdornmentProps={{ classes: adornmentClasses }}
          InputProps={{ classes: inputClasses(disabled, false, false) }}
          InputLabelProps={{ classes: labelClasses }}
          PopoverProps={{
            classes: datePickerClasses,
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
          }}
          minDate={minDate}
          maxDate={maxDate}
        />
      </MuiPickersUtilsProvider>
      {!!error && <div className={styles.error}>{error}</div>}
    </>
  );
};

export default NxDatePicker;

export type NxFormikDatePickerProps = Omit<NxDatePickerProps, NxFormikProps> & {
  name: string;
  validate?: (value: string | null) => string | void | Promise<string | void>;
};

export const NxFormikDatePicker = (
  props: NxFormikDatePickerProps
): ReactElement => {
  const [field, meta, helpers] = useField(props);
  const { ...rest } = field;

  return (
    <NxDatePicker
      {...rest}
      {...props}
      error={meta.error}
      onChange={(value: string | null) => helpers.setValue(value, true)}
      onBlur={() => helpers.setTouched(true, true)}
    />
  );
};
