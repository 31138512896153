import {GridCellParams, GridCellValue, GridValueFormatterParams} from '@material-ui/data-grid';
import {TFunction} from 'i18next';
import styles from './Disbursements.module.scss';
import {DisbursementStatus} from '../../shared/model/DisbursementStatus.model';

export const tableCellDisbursementStatusFormatter = (t: TFunction): (params: GridValueFormatterParams) => GridCellValue =>
  (params: GridValueFormatterParams): GridCellValue =>
    params.value ? t(`DISBURSEMENTS.STATUS.${params.value}`) : '-';

export const tableCellDestinationFormatter = (t: TFunction): (params: GridValueFormatterParams) => GridCellValue =>
  (params: GridValueFormatterParams): GridCellValue =>
    params.value ? params.value : '-';

export const tableCellDisbursementStatusClassNameFormatter = (params: GridCellParams): string => {
  switch (DisbursementStatus[String(params.value)]) {
    case DisbursementStatus.EXECUTED:
      return styles.statusCellPositive;
    case DisbursementStatus.ERROR:
    case DisbursementStatus.REJECTED:
      return styles.statusCellNegative;
    default:
      return styles.statusCellNeutral;
  }
};
