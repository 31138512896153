import { ReactComponent as AppStoreDownload } from "../../assets/images/appStoreDownload.svg";
import { ReactComponent as GooglePlayDownload } from "../../assets/images/googlePlayDownload.svg";
import { ReactComponent as Phone1 } from "../../assets/images/pos-phone-1.svg";
import phone2 from "../../assets/images/phone2.svg";
import cloud1 from "../../assets/images/cloud1.svg";
import cloud2 from "../../assets/images/cloud2.svg";
import styles from "./Pos.module.scss";

import MainContainer from "../shared/main-container/MainContainer";

export const Pos = () => {
  return (
    <MainContainer>
      <div className={styles.wrapper}>
        <div className={styles.relativeWrapper}>
          <div className={styles.textWrapper}>
            <h1>Accept QR PH over-the-counter</h1>
            <p>
              Download our app, sign in to your merchant account and you can now generate dynamic QR PH codes{" "}
              <br /> so your clients can use &quot;Scan to pay&quot; when paying over-the-counter
            </p>
            <div>
              <GooglePlayDownload />
              <AppStoreDownload />
            </div>
          </div>

          <div>
            <Phone1 className={styles.phone1} />
          </div>
          <img src={phone2} className={styles.phone2} />
          <img src={cloud1} className={styles.cloud1} />
          <img src={cloud2} className={styles.cloud2} />
        </div>
      </div>
    </MainContainer>
  );
};
