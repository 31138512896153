import React from 'react';
import {Trans} from 'react-i18next';

interface Props {
  children: string;
  values?: Record<string, unknown>;
}

export const TransHelper = {
  getPrefixedTrans: (prefix: string) => {
    return function PrefixTrans({children, values}: Props): React.ReactElement {
      return <Trans values={values}>{`${prefix}.${children}`}</Trans>;
    };
  }
};
