import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { ReactElement } from 'react';
import { TABLE_DATE_TIME_FORMAT } from '../../../shared/constants/format-templates';
import {
  getAmountOrNullReplacement,
  getFormattedDateOrNullReplacement,
  getValueOrNullReplacement,
} from '../../../shared/utils/valueFormatter';
import { TransHelper } from '../../../utils/trans-helper';
import NxLoader, {
  NxLoaderColorVariant,
  NxLoaderVariant,
} from '../../shared/nxLoader/NxLoader';
import styles from '../../shared/details/Details..module.scss';
import { StatusField } from '../../shared/status/StatusField';
import { PaymentProductDetails } from './PaymentProductDetails';
import { RoutesPaths } from '../../../routes/routes.paths';
import { Link } from 'react-router-dom';
import NxButton from '../../shared/nxButton/NxButton';
import { DownloadReceiptButton } from '../../shared/DownloadReceiptButton/DownloadReceiptButton';

export const PrefixTrans = TransHelper.getPrefixedTrans('PAYMENTS');
export const SharedTrans = TransHelper.getPrefixedTrans('SHARED');

interface PaymentsDetailsProps {
  data?: { [key: string]: any | undefined };
  onClose?: () => void;
  isLoading?: boolean;
}

export default function PaymentDetails({
  onClose,
  data,
  isLoading,
}: PaymentsDetailsProps): ReactElement {
  return (
    <div>
      <Grid container className={styles.wrapper}>
        <div className={styles.title}>
          <PrefixTrans>DETAILS.TITLE</PrefixTrans>
          <IconButton
            color='inherit'
            aria-label='close details'
            onClick={onClose}
            edge='start'
            className={styles.title__close}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <NxLoader
          variant={NxLoaderVariant.SMALL}
          colorVariant={NxLoaderColorVariant.TEXT}
          loaded={!isLoading}
        >
          <div className={styles.row}>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>EXECUTED_ON</PrefixTrans>
              </div>
              <div className={styles.value}>
                {data?.paymentStatus === 'EXECUTED'
                  ? getFormattedDateOrNullReplacement(data.executedOn)
                  : '-'}
              </div>
            </Grid>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>PAYMENT_ID</PrefixTrans>
              </div>
              <div className={styles.value}>{data?.referenceNo}</div>
            </Grid>
          </div>
          <div className={styles.row}>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>RECEIPT_ID</PrefixTrans>
              </div>
              <div className={styles.value}>
                {data?.createdOn
                  ? getFormattedDateOrNullReplacement(data?.id)
                  : '-'}
              </div>
            </Grid>
            <Grid xs={6} item>
              <div className={styles.label}>
                <SharedTrans>AMOUNT</SharedTrans>
              </div>
              <div className={styles.value}>
                {getAmountOrNullReplacement(data?.amount)}
              </div>
            </Grid>
          </div>
          <div className={styles.row}>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>STATUS</PrefixTrans>
              </div>
              <div className={styles.value}>
                {data?.paymentStatus && (
                  <StatusField status={data?.paymentStatus} />
                )}
              </div>
            </Grid>
          </div>
          <PaymentProductDetails paymentData={data?.attributes} />
          <h3>Download receipt</h3>
          <Grid xs={12}>
            <DownloadReceiptButton
              email={data?.attributes?.EMAIL_ADDRESS}
              exeutedOn={data?.executedOn}
              id={data?.id}
              payerName={data?.attributes?.PAYOR_NAME}
              referenceNo={data?.attributes?.referenceNo}
              ticketNumber={data?.attributes?.ticketNumber}
              totalAmount={data?.amount}
            />
          </Grid>
        </NxLoader>
      </Grid>
    </div>
  );
}
