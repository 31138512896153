import {GridCellValue, GridValueFormatterParams} from '@material-ui/data-grid';
import {
  getAmountOrNullReplacement,
  getFormattedDateOrNullReplacement,
  getValueOrNullReplacement
} from './valueFormatter';

export const tableCellNullFormatter = (params: GridValueFormatterParams): GridCellValue =>
  getValueOrNullReplacement(params.value);

export const tableCellDateFormatter = (params: GridValueFormatterParams): GridCellValue => {
  return getFormattedDateOrNullReplacement(params.value);
};

export const tableCellCurrencyFormatter = (params: GridValueFormatterParams): GridCellValue =>
  getAmountOrNullReplacement(params.value);

