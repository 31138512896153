import React, {ReactElement, useState} from 'react';
import {NxFormikInput} from '../shared/nxInput/NxFormikInput';
import styles from './ResetPassword.module.scss';
import {TransHelper} from '../../utils/trans-helper';
import {BannerMessage, BannerType} from '../shared/main-container/MainContainer.model';
import Box from '../shared/box/Box';
import {ReactComponent as ForgotPasswordIcon} from '../../assets/images/forgot-password.svg';
import {ReactComponent as ResetSentIcon} from '../../assets/images/reset-sended.svg';
import MainContainer from '../shared/main-container/MainContainer';
import {Button, Grid, IconButton, InputAdornment} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Formik, FormikProps} from 'formik';
import NxButton from '../shared/nxButton/NxButton';
import {object, ref, SchemaOf, string} from 'yup';
import {Link, useParams} from 'react-router-dom';
import {RESET_CONFIRM_URL} from '../../shared/constants/api-urls';
import usePost from '../../shared/hooks/use-post.hook';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import PasswordStrength, {strongPassword} from '../shared/passswordStrength/PasswordStrength';
import {RoutesPaths} from '../../routes/routes.paths';
import classNames from 'classnames';

export const PrefixTrans = TransHelper.getPrefixedTrans('RESET_PASSWORD');

interface INewPassword {
  password: string,
  repeatPassword: string,
}

interface IChangePasswordPayload {
  rawSecret: string,
  token: string
}

export default function ResetPassword(): ReactElement {

  const [bannerData, setBannerData] = useState<BannerMessage>();
  const [sended, sentSended] = useState<boolean>(false);
  const {t} = useTranslation();
  const resetConfirmPassword = usePost<void, IChangePasswordPayload>(RESET_CONFIRM_URL);
  const params = useParams<{token: string}>();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = (): void => {
    setShowPassword(prev => !prev);
  };

  const handleMouseDownPassword = (event): void => {
    event.preventDefault();
  };

  const submit = async (
    values: INewPassword
  ): Promise<void> => {

    await resetConfirmPassword({
      rawSecret: values.password,
      token: params.token
    })
      .then(() => {
        sentSended(true);
      })
      .catch((error) => {
        console.error(error);
        setBannerData({
          type: BannerType.ERROR,
          message: `${t('CHANGE_PASSWORD.FORM_ERROR')}: ${error}`
        });
      });
  };

  const newPasswordSchema: SchemaOf<INewPassword> = object({
    password: string()
      .required(t('SHARED.FIELD_REQUIRED'))
      .test('is-complex', t('RESET_PASSWORD.PWD_RULES'), (v) => strongPassword.test(v?.length ? v : '')),
    repeatPassword: string()
      .required(t('SHARED.FIELD_REQUIRED'))
      .oneOf([ref('password'), null], t('RESET_PASSWORD.PWD_NOT_EQUALS'))
  }).defined();

  const ChangePasswordForm = ({
                                handleSubmit,
                                values,
                                isSubmitting
                              }: FormikProps<INewPassword>): ReactElement => (
    <form onSubmit={handleSubmit} className={classNames(styles.formWrapper, styles['formWrapper--reset'])}>
      <NxFormikInput className={styles.input}
                     name='password'
                     label={<PrefixTrans>PASSWORD</PrefixTrans>}
                     type={!showPassword ? 'password' : 'text'}
                     endAdornment={
                       <InputAdornment position='end'>
                         <IconButton
                           aria-label='toggle password visibility'
                           onClick={handleClickShowPassword}
                           onMouseDown={handleMouseDownPassword}
                           edge='end'>
                           {!showPassword ? <VisibilityOff /> : <Visibility />}
                         </IconButton>
                       </InputAdornment>
                     } />
      <PasswordStrength password={values.password} />
      <NxFormikInput className={styles.input}
                     name='repeatPassword'
                     label={<PrefixTrans>REPEAT_PASSWORD</PrefixTrans>}
                     type='password' />
      <div className={styles.btnWrapper}>
        <NxButton type='submit'
                  className={styles.button}
                  loaded={!isSubmitting}>
          <PrefixTrans>SEND</PrefixTrans>
        </NxButton>
      </div>
    </form>
  );

  return (
    <MainContainer bannerMessage={bannerData}>
      <Grid container spacing={2} justifyContent={'center'}>
        <Grid item xs={6}>
          {!sended ?
            <Box className={styles.container}>
              <ForgotPasswordIcon />
              <h2><PrefixTrans>HEADER</PrefixTrans></h2>
              <p className={styles.description}>
                <PrefixTrans>DESCRIPTION</PrefixTrans>
              </p>
              <Formik<INewPassword> initialValues={{password: '', repeatPassword: ''}}
                                    onSubmit={submit}
                                    validateOnMount={false}
                                    validateOnBlur={false}
                                    validateOnChange={false}
                                    validationSchema={newPasswordSchema}>
                {ChangePasswordForm}
              </Formik>
            </Box> :
            successMessage()}
        </Grid>
      </Grid>
    </MainContainer>
  );
}

const successMessage = (): ReactElement => (
  <Box className={styles.container}>
    <ResetSentIcon />
    <h2><PrefixTrans>HEADER_SUCCESS</PrefixTrans></h2>
    <p className={styles.description}>
      <PrefixTrans>DESCRIPTION_SUCCESS</PrefixTrans>
    </p>
    <Link to={RoutesPaths.LOGIN} className={styles.button}>
      <Button variant='contained' color='primary'>
        <PrefixTrans>BTN_SUCCESS</PrefixTrans>
      </Button>
    </Link>
  </Box>
);
