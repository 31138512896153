import { Button } from "@material-ui/core";
import clsx from "clsx";
import React, { ReactElement } from "react";
import NxLoader, {
  NxLoaderColorVariant,
  NxLoaderVariant,
} from "../nxLoader/NxLoader";
import styles from "./NxButton.module.scss";

export enum MaterialButtonVariant {
  CONTAINED = "contained",
  OUTLINED = "outlined",
  TEXT = "text",
}

export interface NxButtonProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  endIcon?: React.ReactElement | null;
  loaded?: boolean;
  onClick?: () => void;
  startIcon?: React.ReactElement | null;
  type?: string;
  variant?: MaterialButtonVariant;
  component?: React.ElementType;
}

const NxButton = ({
  children,
  className,
  disabled = false,
  endIcon,
  loaded = true,
  onClick,
  startIcon,
  type = "button",
  variant = MaterialButtonVariant.CONTAINED,
}: NxButtonProps) => {
  const loaderColorVariant =
    variant === MaterialButtonVariant.OUTLINED ||
    variant === MaterialButtonVariant.TEXT
      ? NxLoaderColorVariant.PRIMARY
      : NxLoaderColorVariant.WHITE;

  const buttonClasses = {
    endIcon: clsx(styles.icon_end, { [styles.hidden]: !loaded }),
    startIcon: clsx(styles.icon, { [styles.hidden]: !loaded }),
  };

  const buttonClassName = clsx(className, styles.button, {
    [styles.button_disabled]: disabled,
  });

  return (
    <Button
      variant={variant}
      color="primary"
      classes={buttonClasses}
      className={buttonClassName}
      disableElevation={true}
      disabled={disabled}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      type={type as "button" | "submit"}
    >
      {!loaded && (
        <NxLoader
          variant={NxLoaderVariant.SMALL}
          colorVariant={loaderColorVariant}
        />
      )}
      <span className={clsx({ [styles.hidden]: !loaded })}>{children}</span>
    </Button>
  );
};

export default NxButton;
