import clsx from 'clsx';
import React from 'react';
import {Trans} from 'react-i18next';
import styles from './TermsLink.module.scss';

interface Props {
  checkbox?: React.ReactNode;
  className?: string;
}

export default function TermsLink({checkbox, className}: Props): React.ReactElement {

  const tcLink = process.env.REACT_APP_TERMS_AND_CONDITIONS_LINK ?? '';
  const termsLink = <a href={tcLink} target='_blank' rel='noopener noreferrer' className={styles.link}>{}</a>;

  return (
    <div className={clsx(styles.terms, className)}>
      {checkbox}
      <span className={`${styles.label} ${styles.label_full}`}>
        <Trans i18nKey={'LOGIN.ACCEPT_TERMS_FULL'} components={{1: termsLink}} />
      </span>
      <span className={`${styles.label} ${styles.label_short}`}>
        <Trans i18nKey={'LOGIN.ACCEPT_TERMS_SHORT'} components={{1: termsLink}} />
      </span>
    </div>
  );
}
