import {StylesProvider, ThemeProvider} from '@material-ui/core';
import dayjs from 'dayjs';
import i18n from 'i18next';
import React, {ReactElement} from 'react';
import ReactDOM from 'react-dom';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {initReactI18next} from 'react-i18next';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import translationEN from './i18n/en.json';
import Theme from './styles/Theme';
import {isReCaptchaEnabled} from './utils/app-utils';
import {ConditionalWrapper} from './utils/conditional-wrapper';
import duration from 'dayjs/plugin/duration';

const languageCode = 'en';

i18n
  .use(initReactI18next)
  .init({
    lng: languageCode,
    fallbackLng: languageCode,
    interpolation: {
      escapeValue: false
    },
    resources: {
      [languageCode]: {
        translation: translationEN
      }
    }
  });

dayjs.extend(duration);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <StylesProvider injectFirst>
        <ConditionalWrapper
          condition={isReCaptchaEnabled}
          wrapper={(children: ReactElement): ReactElement =>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ?? ''}>
              {children}
            </GoogleReCaptchaProvider>
          }
        >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ConditionalWrapper>
      </StylesProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
