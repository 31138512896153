export type Product = {
  id: number;
  REFERENCE_NUMBER?: string;
  TAX_DECLARATION_NUMBER?: number;
  amount?: number;
  NAME?: string;
  EMAIL_ADDRESS?: string;
  CONTACT_NUMBER?: string;
  BUSINESS_NAME?: string;
  PAYOR_NAME?: string;
  TICKET_NUMBER?: number;
  VIOLATION_TYPE?: string;
};
export enum ProductEnum {
  REFERENCE_NUMBER = 'REFERENCE_NUMBER',
  TAX_DECLARATION_NUMBER = 'TAX_DECLARATION_NUMBER',
  amount = 'amount',
  NAME = 'NAME',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  CONTACT_NUMBER = 'CONTACT_NUMBER',
  BUSINESS_NAME = 'BUSINESS_NAME',
  PAYOR_NAME = 'PAYOR_NAME',
  TICKET_NUMBER = 'TICKET_NUMBER',
  VIOLATION_TYPE = 'VIOLATION_TYPE',
}
