import React, {ReactElement, useState} from 'react';
import {NxFormikInput} from '../shared/nxInput/NxFormikInput';
import styles from './ChangePassword.module.scss';
import {TransHelper} from '../../utils/trans-helper';
import {BannerMessage, BannerType} from '../shared/main-container/MainContainer.model';
import MainContainer from '../shared/main-container/MainContainer';
import {Grid, IconButton, InputAdornment, Paper} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import NxButton from '../shared/nxButton/NxButton';
import {object, SchemaOf, string, ref} from 'yup';
import {PASSWORD_UPDATE_URL} from '../../shared/constants/api-urls';
import usePost from '../../shared/hooks/use-post.hook';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import PasswordStrength, {strongPassword} from '../shared/passswordStrength/PasswordStrength';
import {useHistory} from 'react-router';
import {RoutesPaths} from '../../routes/routes.paths';
import {HttpError, SimpleErrorBody} from '../../shared/model/Error.model';

export const PrefixTrans = TransHelper.getPrefixedTrans('CHANGE_PASSWORD');

interface IChangePassword {
  oldPassword: string,
  password: string,
  repeatPassword: string,
}

interface IChangePasswordPayload {
  newRawSecret: string,
  oldRawSecret: string
}

const GENERAL_ERROR_KEY = 'HTTP_ERROR';

export default function ChangePassword(): ReactElement {
  const history = useHistory();
  const [bannerData, setBannerData] = useState<BannerMessage>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const {t} = useTranslation();
  const passwordChange = usePost<void, IChangePasswordPayload>(PASSWORD_UPDATE_URL);

  const handleMouseDownPassword = (event): void => {
    event.preventDefault();
  };

  const submit = async (
    values: IChangePassword,
    actions: FormikHelpers<IChangePassword>
  ): Promise<void> => {

    await passwordChange({
      newRawSecret: values.password,
      oldRawSecret: values.oldPassword
    })
      .then(() => {
        setBannerData({
          type: BannerType.INFO,
          message: t('CHANGE_PASSWORD.FORM_SUCCESS')
        });

        actions.resetForm();
        history.push(RoutesPaths.ROOT);
      })
      .catch((e) => {
        const httpError = e as HttpError<SimpleErrorBody>;

        actions.setFieldError(
          GENERAL_ERROR_KEY,
          httpError.error.errorMessage
        );

        return Promise.resolve();
      });
  };

  const changePasswordSchema: SchemaOf<IChangePassword> = object({
    oldPassword: string()
      .required(t('SHARED.FIELD_REQUIRED')),
    password: string()
      .required(t('SHARED.FIELD_REQUIRED'))
      .test('is-complex', t('RESET_PASSWORD.PWD_RULES'), (v) => strongPassword.test(v?.length ? v : '')),
    repeatPassword: string()
      .oneOf([ref('password'), null], t('RESET_PASSWORD.PWD_NOT_EQUALS'))
      .required(t('SHARED.FIELD_REQUIRED'))
  }).defined();

  const ChangePasswordForm = ({
                                handleSubmit,
                                values,
                                errors,
                                isSubmitting
                              }: FormikProps<IChangePassword>): ReactElement => (
    <form onSubmit={handleSubmit} className={styles.formWrapper}>
      <NxFormikInput className={styles.input}
                     name='oldPassword'
                     label={<PrefixTrans>OLD_PASSWORD</PrefixTrans>}
                     type={!showOldPassword ? 'password' : 'text'}
                     endAdornment={
                       <InputAdornment position='end'>
                         <IconButton
                           aria-label='toggle password visibility'
                           onClick={(): void => setShowOldPassword(prev => !prev)}
                           onMouseDown={handleMouseDownPassword}
                           edge='end'
                         >
                           {!showOldPassword ? <VisibilityOff /> : <Visibility />}
                         </IconButton>
                       </InputAdornment>
                     } />
      <NxFormikInput className={styles.input}
                     name='password'
                     label={<PrefixTrans>PASSWORD</PrefixTrans>}
                     type={!showPassword ? 'password' : 'text'}
                     endAdornment={
                       <InputAdornment position='end'>
                         <IconButton aria-label='toggle password visibility'
                                     onClick={(): void => setShowPassword(prev => !prev)}
                                     onMouseDown={handleMouseDownPassword}
                                     edge='end'>
                           {!showPassword ? <VisibilityOff /> : <Visibility />}
                         </IconButton>
                       </InputAdornment>
                     } />
      <PasswordStrength className={styles.passwordStrength} password={values.password} />
      <NxFormikInput className={styles.input}
                     name='repeatPassword'
                     label={<PrefixTrans>REPEAT_PASSWORD</PrefixTrans>}
                     type={!showConfirmPassword ? 'password' : 'text'}
                     endAdornment={
                       <InputAdornment position='end'>
                         <IconButton aria-label='toggle password visibility'
                                     onClick={(): void => setShowConfirmPassword(prev => !prev)}
                                     onMouseDown={handleMouseDownPassword}
                                     edge='end'>
                           {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                         </IconButton>
                       </InputAdornment>
                     } />
      {
        // errors[GENERAL_ERROR_KEY] &&
        <div className={'error'}>{errors[GENERAL_ERROR_KEY]}</div>
      }
      <div className={styles.btnWrapperChangePassword}>
        <NxButton type='submit'
                  className={styles.button}
                  loaded={!isSubmitting}>
          <PrefixTrans>SUBMIT</PrefixTrans>
        </NxButton>
      </div>
    </form>
  );

  return (
    <MainContainer bannerMessage={bannerData}>
      <Grid>
        <div className={styles.title}>
          <PrefixTrans>HEADER</PrefixTrans>
        </div>
        <div className={styles.description}>
          <PrefixTrans>DESCRIPTION</PrefixTrans>
        </div>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
          <Paper elevation={0} className={styles.container}>
            <Formik<IChangePassword> initialValues={{oldPassword: '', password: '', repeatPassword: ''}}
                                     onSubmit={submit}
                                     validateOnMount={false}
                                     validateOnBlur={false}
                                     validateOnChange={false}
                                     validationSchema={changePasswordSchema}>
              {ChangePasswordForm}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
    </MainContainer>
  );
}