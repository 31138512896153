import availableReceipt from "../../../assets/images/availableReceipt.svg";
import consumedReceipt from "../../../assets/images/consumedReceipt.svg";
import receiptCalendar from "../../../assets/images/receiptCalendar.svg";
import receiptCheck from "../../../assets/images/receiptCheck.svg";

export const cards = [
  {
    icon: availableReceipt,
    title: "Locate your receipt number",
    text: "Look for the number on your receipt with the format: XXX-000000-000000000",
  },
  {
    icon: receiptCalendar,
    title: "Enter receipt details",
    text: "Type in the total amount you paid and the date the receipt was issued",
  },
  {
    icon: receiptCheck,
    title: "Accept our Terms of Use",
    text: "It’s just standard procedure to ensure everything is smooth and secure",
  },
  {
    icon: consumedReceipt,
    title: 'Press the "Verify" button',
    text: " If all details are correct, you’ll instantly see the specifics of your transaction",
  },
];
