import dayjs from 'dayjs';
import jwt_decode, {JwtPayload} from 'jwt-decode';

export const SESSION_TOKEN_KEY = 'session-token';

export const isTokenValid = (): boolean => {
  const token = getSessionTokenFromStorage();
  if (!token) {
    return false;
  }
  const decoded = jwt_decode<JwtPayload>(token);
  return dayjs.unix(decoded.exp ?? 0).isAfter(dayjs());
};
export interface JTWUserData extends  JwtPayload {
  userId: string,
  merchantIds: Array<number>
}
export const getSessionTokenFromStorage = (): string | null =>
  window.sessionStorage.getItem(SESSION_TOKEN_KEY);

export const setSessionTokenToStorage = (token: string): void =>
  window.sessionStorage.setItem(SESSION_TOKEN_KEY, token);

export const removeSessionToken = (): void =>
  window.sessionStorage.removeItem(SESSION_TOKEN_KEY);
