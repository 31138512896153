import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { ReactElement, useEffect, useState } from 'react';
import { DISBURSEMENTS_URL } from '../../../shared/constants/api-urls';
import { TABLE_DATE_TIME_FORMAT } from '../../../shared/constants/format-templates';
import useGet from '../../../shared/hooks/use-get.hook';
import {
  getAmountOrNullReplacement,
  getFormattedDateOrNullReplacement,
} from '../../../shared/utils/valueFormatter';
import { TransHelper } from '../../../utils/trans-helper';
import NxLoader, {
  NxLoaderColorVariant,
  NxLoaderVariant,
} from '../../shared/nxLoader/NxLoader';

import {
  Disbursement,
  DisbursementTO,
  fromDisbursementTo,
} from '../Disbursement.model';
import styles from './DisbursementDetails.module.scss';
import { SettlementInstitution } from '../../../shared/model/SettlementInstitution.model';

export const PrefixTrans = TransHelper.getPrefixedTrans('DISBURSEMENTS');
export const SharedTrans = TransHelper.getPrefixedTrans('SHARED');

interface DisbursementsDetailsProps {
  id: string;
  env: string;
  onClose: () => void;
  institutions?: SettlementInstitution[];
}

export default function DisbursementDetails({
  id,
  env,
  institutions,
  onClose,
}: DisbursementsDetailsProps): ReactElement {
  const [loading, setLoading] = useState<boolean>(true);
  const [disbursementDetails, setDisbursementDetails] = useState<
    Disbursement | undefined
  >(undefined);
  const getDisbursementDetails = useGet<DisbursementTO>(
    `${DISBURSEMENTS_URL}/${id}`
  );

  useEffect(() => {
    getDisbursementDetails(new URLSearchParams({ env }))
      .then((disbursementTO: DisbursementTO) => {
        const institution = institutions?.find((i) =>
          [i.swift, i.code].includes(disbursementTO.institutionCode)
        );
        setDisbursementDetails(
          fromDisbursementTo({
            ...disbursementTO,
            institutionName: institution?.name || '',
          })
        );
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div>
      <Grid container className={styles.wrapper}>
        <div className={styles.title}>
          <PrefixTrans>DETAILS.TITLE</PrefixTrans>
          <IconButton
            color='inherit'
            aria-label='close details'
            onClick={onClose}
            edge='start'
            className={styles.title__close}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <NxLoader
          variant={NxLoaderVariant.SMALL}
          colorVariant={NxLoaderColorVariant.TEXT}
          loaded={!loading}
        >
          <div className={styles.row}>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>DISBURSEMENT_ID</PrefixTrans>
              </div>
              <div className={styles.value}>{disbursementDetails?.id}</div>
            </Grid>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>MERCHANT_REFERENCE_NO</PrefixTrans>
              </div>
              <div className={styles.value}>
                {disbursementDetails?.merchantReferenceNo}
              </div>
            </Grid>
          </div>
          <div className={styles.row}>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>REGISTRATION_TIME</PrefixTrans>
              </div>
              <div className={styles.value}>
                {disbursementDetails?.registrationTime.format(
                  TABLE_DATE_TIME_FORMAT
                )}
              </div>
            </Grid>
            <Grid xs={6} item>
              <div className={styles.label}>
                <SharedTrans>AMOUNT</SharedTrans>
              </div>
              <div className={styles.value}>
                {getAmountOrNullReplacement(disbursementDetails?.amount)}
              </div>
            </Grid>
          </div>
          <div className={styles.row}>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>DISBURSEMENT_STATUS</PrefixTrans>
              </div>
            </Grid>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>SETTLEMENT_TIME</PrefixTrans>
              </div>
              <div className={styles.value}>
                {getFormattedDateOrNullReplacement(
                  disbursementDetails?.settlementTime
                )}
              </div>
            </Grid>
          </div>
          <div className={styles.row}>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>DESTINATION</PrefixTrans>
              </div>
              <div className={styles.value}>
                {getFormattedDateOrNullReplacement(
                  disbursementDetails?.destination
                )}
              </div>
            </Grid>
          </div>
        </NxLoader>
      </Grid>
    </div>
  );
}
