import { ReactElement, useEffect, useState, cloneElement } from 'react';

import useGet from '../../../shared/hooks/use-get.hook';

interface IDetailsProps {
  id: string;
  env: string;
  onClose: () => void;
  onConfirm?: (id: string) => Promise<void>;
  url: string;
  children: ReactElement<any>;
}
export const Details = ({
  id,
  env,
  onClose,
  onConfirm,
  children,
  url,
    ...props
}: IDetailsProps): ReactElement => {
  const [loading, setLoading] = useState<boolean>(true);
  const [detailsData, setDetailsData] = useState<{
    [key: string]: any;
  } | undefined>(undefined);

  const getData = useGet<{ [key: string]: any | undefined }>(`${url}/${id}`);

  useEffect(() => {
    if (!detailsData) {
      updateData();
    }
  }, []);

  const updateData = () => {
    getData(new URLSearchParams({ env })).then((paymentTo) => {
      setDetailsData({ ...paymentTo });
      setLoading(false);
    });
  };

  const handleOnConfirm = async () => {
    await onConfirm?.(detailsData?.orderNumber as string);
    updateData();
  };

  const childrenWithProps = cloneElement(children, {
    data: detailsData,
    handleOnConfirm,
    updateData,
    onClose,
    isLoading: loading,
    ...props
  });

  return <>{childrenWithProps}</>;
};
