import { Link } from 'react-router-dom';
import { RoutesPaths } from '../../../routes/routes.paths';
import NxButton from '../nxButton/NxButton';
import { FC } from 'react';

type Props = {
  totalAmount?: number;
  id?: string;
  payerName?: string;
  exeutedOn?: string;
  referenceNo?: string;
  email?: string;
  ticketNumber?: string;
};

export const DownloadReceiptButton: FC<Props> = ({
  email,
  payerName,
  id,
  exeutedOn,
  referenceNo,
  totalAmount,
  ticketNumber,
}) => {
  return (
    <Link
      to={{
        pathname: RoutesPaths.RECEIPT,
        search: `?totalAmount=${totalAmount}&receiptNo=${id}&payerName=${payerName}&receipt_date=${exeutedOn}&referenceNo=${
          referenceNo || ticketNumber
        }&address=${email}`,
      }}
      target='_blank'
    >
      <NxButton>Download Receipt</NxButton>
    </Link>
  );
};
