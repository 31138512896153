import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Button,
} from '@material-ui/core';
import { FC, useContext, useState } from 'react';
import { ReactComponent as CartIcon } from '../../../assets/images/cart.svg';
import { ReactComponent as WalletIcon } from '../../../assets/images/wallet.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import styles from './OrderBatch.module.scss';
import { PickOrderBatch } from './PickOrderBatch';
import {
  CreateOrderEntity,
  CreateOrderParams,
  CreateOrderReponse,
} from '../Order.model';
import { MerchantContext } from '../../../App';
import usePost from '../../../shared/hooks/use-post.hook';
import { ORDERS_URL } from '../../../shared/constants/api-urls';
import { OrderStatusEnum } from '../../../shared/constants/receipt-status';
import NxLoader from '../../shared/nxLoader/NxLoader';
import { PaymentInstruction } from './PaymentInstruction';
type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (order: CreateOrderEntity) => void;
  onFinish: () => void;
  setNewOrderId: (id: string) => void;
};

const OrderNewBatchModal: FC<Props> = (props) => {
  const { onClose, isOpen, onConfirm, onFinish, setNewOrderId } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedBatch, setSelectedBatch] = useState<undefined | number>(
    undefined
  );
  const { currentMerchantId } = useContext(MerchantContext);
  const [createOrderData, setCreateOrderData] =
    useState<CreateOrderReponse | null>(null);
  const orderNewBatch = usePost<CreateOrderReponse, CreateOrderParams>(
    ORDERS_URL
  );

  const handleOnClose = () => {
    setSelectedBatch(undefined);
    setStep(1);
    setCreateOrderData(null);
    onClose();
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    if (!currentMerchantId && !selectedBatch) {
      return;
    }
    const data = await orderNewBatch({
      numberOfReceipts: selectedBatch!,
      merchantId: currentMerchantId!,
    });

    setCreateOrderData(data);
    setNewOrderId(data.orderNumber.toString());
    setStep(2);
    setSelectedBatch(undefined);
    setIsLoading(false);

    onConfirm({
      ...data,
      id: data.orderNumber,
      createdOn: new Date().toString(),
      orderedBy: 'User 0',
      status: OrderStatusEnum.NEW,
      totalReceiptsCount: selectedBatch!,
      minReceiptId: 'MIN 0',
      maxReceiptId: 'MAX 1',
      orderNumber: data.orderNumber,
      merchantId: currentMerchantId!,
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleOnClose}
      classes={{
        paper: styles.modalContainer,
      }}
    >
      <Grid
        direction='row'
        justifyContent='space-between'
        container
        alignItems='center'
      >
        <div className={styles.iconWrapper}>
          {step === 1 ? <CartIcon /> : <WalletIcon />}
        </div>

        <IconButton onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
      {
        <NxLoader loaded={!isLoading}>
          {step === 1 ? (
            <PickOrderBatch
              selectedBatch={selectedBatch}
              setSelectedBatch={setSelectedBatch}
              onSubmit={handleConfirm}
              isLoading={isLoading}
              {...props}
            />
          ) : (
            <NxLoader loaded={!!createOrderData?.orderNumber}>
              <PaymentInstruction
                orderId={createOrderData?.orderNumber}
                onClose={handleOnClose}
                onSubmit={onFinish}
              />
            </NxLoader>
          )}
        </NxLoader>
      }
    </Dialog>
  );
};

export default OrderNewBatchModal;
