import DatePicker from "react-datepicker";
import NxButton, { MaterialButtonVariant } from "../nxButton/NxButton";
import { useState } from "react";
import styles from "./CustomDatePicker.module.scss";
import { Header } from "./Header";

export type CustomDatePickerProps = {
  value: Date;
  onChange: (date: Date) => void;
  placeholder?: string;
};

const CustomDatePicker = ({
  onChange,
  value,
  placeholder,
}: CustomDatePickerProps) => {
  const [currentDate, setCurrentDate] = useState<Date>(value);
  const [renderKey, setRenderKey] = useState(Date.now());

  const handleDateConfirm = () => {
    onChange(currentDate);
    setRenderKey(Date.now());
  };

  return (
    <>
      <div className={styles.inputWrapper}>
        <DatePicker
          key={renderKey}
          selected={currentDate}
          onChange={(date) => setCurrentDate(date || new Date())}
          className={styles.input}
          placeholderText={placeholder}
          calendarClassName={styles.popper}
          renderCustomHeader={(props) => <Header {...props} />}
          shouldCloseOnSelect={false}
          closeOnScroll={true}
        >
          <div className={styles.bottomButtons}>
            <NxButton
              className={styles.button}
              variant={MaterialButtonVariant.OUTLINED}
            >
              Cancel
            </NxButton>
            <NxButton className={styles.button} onClick={handleDateConfirm}>
              Apply
            </NxButton>
          </div>
        </DatePicker>
      </div>
    </>
  );
};

export default CustomDatePicker;
