import { useRef, useState } from "react";
import NxButton from "../nxButton/NxButton";
import { TransHelper } from "../../../utils/trans-helper";

export type UploadButtonProps = {
  onUpload: (file: any) => void;
};

export const SharedTrans = TransHelper.getPrefixedTrans("SHARED");

export const UploadButton = ({ onUpload }: UploadButtonProps) => {
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUpload = (file: File) => {
    setFile(file);
    onUpload(file);
  };

  return (
    <>
      {!file ? (
        <NxButton onClick={() => fileInputRef.current?.click()}>
          <SharedTrans>UPLOAD</SharedTrans>
        </NxButton>
      ) : (
        file.name
      )}
      <input
        type="file"
        onChange={(e) => handleUpload(e.target.files?.[0] as File)}
        hidden
        ref={fileInputRef}
      />
    </>
  );
};
