import { Chip, Grid } from "@material-ui/core";
import { Card } from "../Card/Card";
import signInAsMerchantImage from "../../../assets/images/signInAsMerchantImage.png";
import NxButton from "../../shared/nxButton/NxButton";
import { Link } from "react-router-dom";
import { RoutesPaths } from "../../../routes/routes.paths";
import { CardLabel } from "../CardLabel/CardLabel";
import styles from "../Card/Card.module.scss";

export const MerchantCard = () => {
  return (
    <Card img={signInAsMerchantImage}>
      <CardLabel variant="secondary">For businesses</CardLabel>
      <div className={styles.textWrapper}>
        <h2>Sign in as a merchant</h2>
        <p>Access your purchases and sales overview with a simple sign-in.</p>
      </div>
      <Link to={RoutesPaths.LOGIN} className={styles.buttonWrapper}>
        <NxButton>Sign In</NxButton>
      </Link>
      <p className={styles.register}>
        Don&apos;t have an account ? <a href={RoutesPaths.REGISTER}>Register</a>
      </p>
    </Card>
  );
};
