import { Card, Divider, Switch } from '@material-ui/core';
import styles from './PaymentSettings.module.scss';
import { FC } from 'react';

type TPaymentCard = {
  logoUrl: string;
  name: string;
  value: boolean;
  code: string;
  toggleValue: (value: string) => void;
};

export const PaymentCard: FC<TPaymentCard> = ({
  logoUrl,
  name,
  value,
  code,
  toggleValue,
}) => {
  return (
    <Card className={styles.card}>
      <div className={styles.cardContent}>
        <img src={logoUrl} alt={`${name}-logo`} className={styles.icon} />
        <div className={styles.name}>{name}</div>
      </div>
      <Divider />
      <div className={styles.switch}>
        <Switch
          checked={value}
          onChange={() => toggleValue(code)}
          color='secondary'
        />
      </div>
    </Card>
  );
};
