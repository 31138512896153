import { DataGrid, GridComponentProps } from "@material-ui/data-grid";
import React, { ReactElement } from "react";
import { PageInfo } from "../../../shared/model/Page.model";
import EmptyTable from "../emptyTable/EmptyTable";
import NxLoader, {
  NxLoaderColorVariant,
  NxLoaderVariant,
} from "../nxLoader/NxLoader";
import TableFilters from "./TableFilters";
import CustomPagination from "./pagination/Pagination";

type TableProps = Pick<
  GridComponentProps,
  | "loading"
  | "columns"
  | "rows"
  | "onRowClick"
  | "getRowId"
  | "onPageChange"
  | "pageSize"
> & {
  pageInfo?: PageInfo;
  noRowsMessage: string;
};

const DataTable = (props: TableProps): ReactElement => (
  <DataGrid
    {...props}
    page={props.pageInfo?.pageNo}
    rowCount={props.pageInfo?.totalCount}
    pagination
    paginationMode="server"
    disableSelectionOnClick
    disableExtendRowFullWidth
    rowHeight={43}
    headerHeight={48}
    components={{
      LoadingOverlay: NxLoader,
      NoRowsOverlay: EmptyTable,
      Pagination: CustomPagination,
    }}
    componentsProps={{
      loadingOverlay: {
        variant: NxLoaderVariant.SMALL,
        colorVariant: NxLoaderColorVariant.TEXT,
      },
      noRowsOverlay: {
        message: props.noRowsMessage,
      },
    }}
  />
);

export default DataTable;
