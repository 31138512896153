import {Button} from "@material-ui/core";
import {GridCellParams} from "@material-ui/data-grid";
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import styles from './CopyCellRender.module.scss';
import {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

export default function CopyCellRender(params: GridCellParams, copyText?: string | number) {
  const {t} = useTranslation();
  const [isShownCopied, setIsShownCopied] = useState(false);

  const onCopy = useCallback((e) => {
    e.stopPropagation();

    if (isShownCopied) return;

    setIsShownCopied(true);

    setTimeout(() => {
      setIsShownCopied(false);
    }, 3000);
  }, []);

  return (
    <div className={styles.container}>
      {params.value}
      <CopyToClipboard text={copyText || params.value}>
        {isShownCopied
          ? <div className={styles.copied}>{t('SHARED.COPIED')}</div>
          : <Button
            className={styles.button}
            variant='text'
            color='primary'
            onClick={onCopy}>
            {isShownCopied
              ? <div className={styles.copied}>{t('SHARED.COPIED')}</div>
              : <CopyIcon />}
          </Button>}
      </CopyToClipboard>
    </div>
  );
}