import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from "axios";
import { SwiftpayRequest } from "../model/SwiftpayRequest.model";
import HttpService from "../services/http.service";
import useApiCatch from "./api-error-catch.hook";

/**
 * Dedicated hook for perform GET request.
 *
 * @param url - target url for API request.
 *
 * T - type of data returned by api call.
 *
 * @return function which allows execute configured GET request. It has following arguments:
 * cancelTokenSource - CancelTokenSource which allows to abort request, it can be null or undefined.
 */
export default function useGet<T>(
  url: string
): (
  params?: URLSearchParams | null,
  cancelTokenSource?: CancelTokenSource | null,
  headers?: AxiosRequestConfig["headers"] | null
) => Promise<T> {
  const catchError = useApiCatch();

  return (
    params?: URLSearchParams | null,
    cancelTokenSource?: CancelTokenSource | null,
    headers?: AxiosRequestConfig["headers"] | null
  ): Promise<T> => {
    const request: SwiftpayRequest = {
      url,
      method: "GET",
      params,
      cancelTokenSource,
    };

    return HttpService.request<T>(request, headers).catch(catchError);
  };
}

export function useGetRaw<T>(
  url: string
): (
  params?: URLSearchParams | null,
  cancelTokenSource?: CancelTokenSource | null
) => Promise<AxiosResponse<T>> {
  const catchError = useApiCatch();

  return (
    params?: URLSearchParams | null,
    cancelTokenSource?: CancelTokenSource | null
  ): Promise<AxiosResponse<T>> => {
    const request: SwiftpayRequest = {
      url,
      method: "GET",
      params,
      cancelTokenSource,
    };

    return HttpService.rawRequest<T>(request).catch(catchError);
  };
}

/**
 * Dedicated hook for perform GET request.
 *
 * @param url - target url for API request.
 *
 * T - type of data returned by api call.
 *
 * @return function which allows execute configured GET request. It has following arguments:
 * cancelTokenSource - CancelTokenSource which allows to abort request, it can be null or undefined.
 */
export const useGetExt = function useGetExt<T>(): (
  url: string,
  params?: URLSearchParams | null,
  cancelTokenSource?: CancelTokenSource | null
) => Promise<T> {
  const catchError = useApiCatch();

  return (
    url,
    params?: URLSearchParams | null,
    cancelTokenSource?: CancelTokenSource | null
  ): Promise<T> => {
    const request: SwiftpayRequest = {
      url,
      method: "GET",
      params,
      cancelTokenSource,
    };

    return HttpService.request<T>(request).catch(catchError);
  };
};
