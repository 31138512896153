import {useField, useFormikContext} from 'formik';
import React, {ReactElement} from 'react';
import {isFieldRequired, useValidationSchema} from '../../../shared/form/ValidationService';
import {NxFormikProps} from '../../../shared/model/NxComponents.model';
import NxInput, {NxInputProps} from './NxInput';

export type NxFormikInputProps = Omit<NxInputProps, NxFormikProps> & {
  name: string;
  validate?: (text: string) => string | void | Promise<string | void>;
};

export const NxFormikInput = (props: NxFormikInputProps): ReactElement => {

  const context = useFormikContext();
  const [field, meta, ] = useField(props);
  const validationSchema = useValidationSchema();

  return <NxInput {...field}
                  {...props}
                  error={meta.error}
                  disabled={props.disabled || context.isSubmitting}
                  required={props.required || isFieldRequired(validationSchema, field.name)} />;
}