import React, {ChangeEvent, ReactElement, useState} from 'react';
import {useTranslation} from 'react-i18next';
import usePost from '../../shared/hooks/use-post.hook';
import {ErrorBody, HttpError} from '../../shared/model/Error.model';
import {TransHelper} from '../../utils/trans-helper';
import MainContainer from '../shared/main-container/MainContainer';
import {BannerMessage, BannerType} from '../shared/main-container/MainContainer.model';
import NxButton from '../shared/nxButton/NxButton';
import NxInput from '../shared/nxInput/NxInput';
import NxLoader, {NxLoaderVariant} from '../shared/nxLoader/NxLoader';
import {ShopifyAuthenticatePayload, ShopifyAuthenticateResponse} from './ShopifyAuthenticate.model';
import styles from './ShopifyAuthenticate.module.scss';

export const PrefixTrans = TransHelper.getPrefixedTrans('SHOPIFY');

export default function ShopifyAuthenticate(): ReactElement {

  const {t} = useTranslation();
  const [payload, setPayload] = useState<ShopifyAuthenticatePayload>();
  const [error, setError] = useState<BannerMessage>();
  const [isConfirmationSending, setIsConfirmationSending] = useState<boolean>();
  const confirmRequest = usePost<ShopifyAuthenticateResponse, ShopifyAuthenticatePayload>('shopify/application/confirm');

  const onConfirm = async (): Promise<void> => {
    if (payload) {
      setIsConfirmationSending(true);
      try {
        const response = await confirmRequest(payload);
        window.location.href = response.redirectUrl;
      } catch (error) {
        const httpError = error as HttpError<ErrorBody>;
        if (httpError.response.status === 404) {
          setError({message: t('SHOPIFY.ERROR.MERCHANT_NOT_FOUND'), type: BannerType.ERROR});
        } else {
          setError({message: t('SHOPIFY.ERROR.CONFIRMATION_FAIL'), type: BannerType.ERROR});
        }
      }
      setIsConfirmationSending(false);
    }
  };

  return (
    <MainContainer bannerMessage={error}>
      <div className={styles.formContainer}>
        <NxInput className={styles.input}
                 label={<PrefixTrans>INPUT.LABEL.EMAIL</PrefixTrans>}
                 value={payload?.email ?? ''}
                 onChange={
                   (event): void => setPayload({
                     ...payload,
                     email: (event as ChangeEvent<HTMLInputElement>).target.value
                   })
                 } />
        <NxInput className={styles.input}
                 label={<PrefixTrans>INPUT.LABEL.WEBSITE_DOMAIN</PrefixTrans>}
                 value={payload?.shopDomain ?? ''}
                 onChange={
                   (event): void => setPayload({
                     ...payload,
                     shopDomain: (event as ChangeEvent<HTMLInputElement>).target.value
                   })
                 } />
        <NxButton onClick={onConfirm} disabled={isConfirmationSending}>
          <NxLoader variant={NxLoaderVariant.SMALL}
                    loaded={!isConfirmationSending}>
            Confirm
          </NxLoader>
        </NxButton>
      </div>
    </MainContainer>
  );
}
