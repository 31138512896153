import { useTranslation } from "react-i18next";
import { ValidationSuccessEntity } from "./ValidationSuccessEntity";

type DynamicListProps = {
  attributes: object;
  exclude: string[];
};

export const DynamicList = ({ attributes, exclude }: DynamicListProps) => {
  const { t } = useTranslation();

  return (
    <>
      {Object.entries(attributes).map(([key, value]) => (
        <>
          {!exclude.includes(key) ? (
            <ValidationSuccessEntity
              label={t(`SHARED_RECEIPT_VALIDATION_${key}`)}
              value={value}
            />
          ) : null}
        </>
      ))}
    </>
  );
};
