import { createTheme } from "@material-ui/core";
import { toNumber } from "lodash/fp";
import styles from "./Theme.module.scss";

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: toNumber(styles.breakpointSm),
      md: toNumber(styles.breakpointMd),
      lg: toNumber(styles.breakpointLg),
      xl: 1920,
    },
  },
  palette: {
    primary: {
      contrastText: styles.white,
      main: styles.primaryColor,
    },
    secondary: {
      main: styles.secondaryColor,
    },
    error: {
      main: styles.error,
    },
    action: {
      hover: styles.hoverColor,
    },
  },
  props: {
    MuiDialog: {
      maxWidth: false,
    },
    MuiCheckbox: {
      color: "primary",
    },
    MuiRadio: {
      color: "primary",
    },
  },
  typography: {
    htmlFontSize: toNumber(styles.baseFontSize),
    fontSize: (toNumber(styles.baseFontSize) * 14) / 16,
    fontFamily: styles.mainFontFamily,
    h1: {
      fontSize: styles.fontSizeXl,
      lineHeight: 1.5,
    },
    h2: {
      fontSize: styles.fontSizeL,
      lineHeight: 1.5,
    },
    h4: {
      fontSize: styles.fontSizeXs,
    },
    body1: {
      fontSize: styles.fontSizeS,
    },
    body2: {
      fontSize: styles.fontSizeS,
    },
    caption: {
      fontSize: styles.fontSizeXs,
    },
    button: {
      fontSize: styles.fontSizeM,
      textTransform: "none",
    },
  },
});
