import dayjs, { Dayjs } from 'dayjs';
import { EnvironmentType } from '../../shared/model/EnvironmentType.model';
import { FilterCriteria } from '../../shared/model/Filter.model';
import { Product } from '../../shared/model/Product.model';
import { PagedResponse } from '../../shared/model/Page.model';
import { PageCriteria } from '../../shared/model/PagedCriteria.model';
import { PaymentMethod } from '../../shared/model/PaymentMethod.model';
import { PaymentStatusEnum } from '../../shared/constants/receipt-status';

const SHORT_ID_DIGITS = 8;

export type PaymentTO<
  T_DATE = string,
  T_STATUS = string,
  T_PAYMENT_METHOD = string
> = {
  id: string;
  shortId?: string;
  paymentIdSuffix?: string;
  merchantId: number;
  merchantName: string;
  paymentStatus: T_STATUS;
  paymentMethod: T_PAYMENT_METHOD;
  sourceOfFund?: string;
  referenceNo: string;
  bankAccountNo: string;
  rejectionReason: string;
  institutionCode?: string;
  institutionName?: string;
  amount: number;
  totalAmount: number;
  fee?: number;
  currency: string;
  createdOn: T_DATE;
  executedOn?: T_DATE;
  expiredOn?: T_DATE;
  canceledOn?: T_DATE;
  attributes?: {
    lastName: string;
    firstName: string;
    orderId: string;
    payerSubject: string;
    type: string;
    email: string;
    phoneNo: string;
  };
};

export enum AdditionalAttributes {
  BANK_ACCOUNT_NO = 'bankAccountNo',
}

export type Payment = PaymentTO<Dayjs, PaymentStatusEnum, PaymentMethod>;

export const fromPaymentTo = (paymentTO: PaymentTO): Payment => ({
  ...paymentTO,
  paymentIdSuffix: paymentTO.id.slice(-SHORT_ID_DIGITS),
  paymentStatus: PaymentStatusEnum[paymentTO.paymentStatus],
  paymentMethod: PaymentMethod[paymentTO.paymentMethod],
  sourceOfFund: paymentTO.paymentMethod
    ? PaymentMethod[paymentTO.paymentMethod] +
      ' - ' +
      (paymentTO.institutionName || paymentTO.institutionCode)
    : undefined,
  createdOn: dayjs(paymentTO.createdOn),
  executedOn: paymentTO.executedOn ? dayjs(paymentTO.executedOn) : undefined,
  canceledOn: paymentTO.canceledOn ? dayjs(paymentTO.canceledOn) : undefined,
  expiredOn: paymentTO.expiredOn ? dayjs(paymentTO.expiredOn) : undefined,
});

export type PaymentsResponse = PagedResponse<PaymentTO>;

export type OrderStatistic = {
  availableReceipts: number;
  maxId: string;
  minId: string;
  totalReceipts: number;
};

export type PaymentRequestParams = PageCriteria &
  FilterCriteria<string> & {
    env: EnvironmentType;
    phrase?: string;
  };

export enum GcashAttributesTransKeys {
  gCashTransactionId = 'GCASH_TRANSACTION_ID',
  gCashAcquirementId = 'GCASH_ACQUIREMENT_ID',
  gCashAcquirementStatus = 'GCASH_ACQUIREMENT_STATUS',
  gCashResultCode = 'GCASH_RESULT_CODE',
  bankAccountNo = 'QRPH_ACCOUNT_NO',
}

export type PaymentCallbackDetailsTO<T_DATE = string> = {
  errorMessage: string;
  payload: {
    attributes: Map<string, string>;
    callbackType: string;
    callbackUrl: string;
    id: string;
    merchantId: number;
  };
  responseCode: string;
  success: boolean;
  timestamp: T_DATE;
};

export const fromPaymentCallbackDetailsTO = (
  paymentCallbackDetailsTO: PaymentCallbackDetailsTO
): PaymentCallbackDetails => ({
  ...paymentCallbackDetailsTO,
  timestamp: dayjs(paymentCallbackDetailsTO.timestamp),
});

export type PaymentCallbackDetails = PaymentCallbackDetailsTO<Dayjs>;
