import {Grid, Paper} from '@material-ui/core';
import classNames from 'classnames';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import React, {ReactElement, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {mixed, object, SchemaOf, string} from 'yup';
import {MerchantContext} from '../../App';
import {ReactComponent as ShopCreateIllustration} from '../../assets/images/shop-create.svg';
import {ReactComponent as ShopSent} from '../../assets/images/shop-sent-illustration.svg';
import {RoutesPaths} from '../../routes/routes.paths';
import {MERCHANTS_URL} from '../../shared/constants/api-urls';
import useGet from '../../shared/hooks/use-get.hook';
import usePost from '../../shared/hooks/use-post.hook';
import {ErrorBody, HttpError} from '../../shared/model/Error.model';
import {Merchant} from '../../shared/model/Merchant.model';
import PlatformType from '../../shared/model/PlatformType.model';
import {Shop, ShopCreateResponse} from '../../shared/model/Shop.model';
import {TransHelper} from '../../utils/trans-helper';
import MainContainer from '../shared/main-container/MainContainer';
import {BannerMessage, BannerType} from '../shared/main-container/MainContainer.model';
import NxButton from '../shared/nxButton/NxButton';
import {NxFormikInput} from '../shared/nxInput/NxFormikInput';
import {NxFormikSelect} from '../shared/nxSelect/NxFormikSelect';
import {NxSelectOption} from '../shared/nxSelect/NxSelect';
import styles from './CreateShop.module.scss';

export const PrefixTrans = TransHelper.getPrefixedTrans('CREATE_SHOP');

const MERCHANT_ALREADY_EXISTS = 'MERCHANT_ALREADY_EXISTS';

export default function CreateShop(): ReactElement {

  const [bannerData, setBannerData] = useState<BannerMessage>();
  const [sended, sentSended] = useState<boolean>(false);
  const {t} = useTranslation();
  const history = useHistory();
  const merchantRequest = useGet<Array<Merchant>>(MERCHANTS_URL);
  const createShop = usePost<ShopCreateResponse, Shop>(MERCHANTS_URL);
  const {setMerchants} = useContext(MerchantContext);

  const submit = async (values: Shop, actions: FormikHelpers<Shop>): Promise<void> => {
    const shopPayload: Shop = {
      name: values.name,
      platform: values.platform,
      websiteUrl: values.websiteUrl
    };

    await createShop(shopPayload)
      .then(async () => {
        actions.resetForm();
        sentSended(true);
        setBannerData({
          type: BannerType.SUCCESS,
          message: t('CREATE_SHOP.FORM_SUCCESS')
        });

        await merchantRequest().then((merchants) => {
          setMerchants(merchants);
        });
      })
      .catch((error): void => {
        const httpError = error as HttpError<ErrorBody>;

        if (httpError.error.errorCode === MERCHANT_ALREADY_EXISTS) {
          actions.setFieldError(
            MERCHANT_ALREADY_EXISTS,
            httpError.error.errorMessage
          );
          return;
        }

        setBannerData({
          type: BannerType.ERROR,
          message: httpError.error.errorMessage
        });
      });
  };

  const createShopSchema: SchemaOf<Shop> = object({
    name: string()
      .required(t('SHARED.FIELD_REQUIRED')),
    platform: mixed<PlatformType>().oneOf(Object.values(PlatformType)).nullable().defined()
      .required(t('SHARED.FIELD_REQUIRED')),
    websiteUrl: string()
      .required(t('SHARED.FIELD_REQUIRED')).url(t('CREATE_SHOP.SHOP_URL_VALID'))
  }).defined();

  const initialValues = {
    name: '',
    platform: PlatformType.WOOCOMMERCE,
    websiteUrl: ''
  };

  const platformSelectOptions = Object.keys(PlatformType)
    .map((key: string): NxSelectOption => (
      {
        value: key,
        label: t(`SHARED.PLATFORMS.${key}`)
      }
    ));

  const CreateShopForm = ({handleSubmit, errors, isSubmitting}: FormikProps<Shop>): ReactElement => (
    <form onSubmit={handleSubmit} className={styles.formWrapper}>
      <NxFormikInput className={styles.input}
                     name='name'
                     label={<PrefixTrans>SHOP_NAME</PrefixTrans>}
                     type={'text'} />
      <NxFormikInput className={styles.input}
                     name='websiteUrl'
                     label={<PrefixTrans>SHOP_URL</PrefixTrans>}
                     type='text' />
      <NxFormikSelect className={styles.input}
                      options={platformSelectOptions}
                      label={t('SHARED.PLATFORM')}
                      name='platform' />
      <div className={classNames('error', styles.error)}>{errors[MERCHANT_ALREADY_EXISTS]}</div>
      <div className={styles.buttonContainer}>
        <NxButton className={styles.cancelButton} onClick={(): void => history.push(RoutesPaths.ROOT)}>
          <PrefixTrans>CANCEL</PrefixTrans>
        </NxButton>
        <NxButton type='submit'
                  loaded={!isSubmitting}>
          <PrefixTrans>SEND</PrefixTrans>
        </NxButton>
      </div>
    </form>
  );

  return (
    <MainContainer bannerMessage={bannerData}>
      <Grid container>
        <div className={styles.title}>
          <PrefixTrans>TITLE</PrefixTrans>
        </div>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Paper elevation={0} className={styles.container}>
            <Formik<Shop> initialValues={initialValues}
                          onSubmit={submit}
                          validateOnMount={false}
                          validateOnBlur={false}
                          validateOnChange={false}
                          validationSchema={createShopSchema}>
              {CreateShopForm}
            </Formik>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          {sended ? <ShopSent /> : <ShopCreateIllustration />}
        </Grid>
      </Grid>
    </MainContainer>
  );
}
