import { MenuItem, SelectProps } from "@material-ui/core";
import React, {
  ChangeEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Nil } from "../../../shared/model/NxComponents.model";
import { ReactComponent as ChevronIcon } from "../../../assets/images/icon-chevron.svg";
import styles from "./NxSelect.module.scss";
import NxGenericTextField from "../nxGenericTextField/NxGenericTextField";
import { isNil } from "lodash/fp";
import { logMissingOnChange } from "../inputError";
import { createMapping } from "./ValueMapping";

export interface NxSelectOption<T_Value = string> {
  label: React.ReactNode;
  value: T_Value;
  disabled?: boolean;
}

export interface NxSelectProps<T_Value = string> {
  className?: string;
  disabled?: boolean;
  error?: string;
  id?: string;
  name?: string;
  onBlur?: (value: T_Value | null) => void;
  onChange?: (value: T_Value | null) => void;
  options: NxSelectOption<T_Value>[];
  label: React.ReactNode;
  value?: T_Value | null;
  emptyOption?: boolean;
  positionAbsoluteError?: boolean;
  required?: boolean;
}

const validValueOrUndefined = <T_Value,>(
  value: T_Value | Nil,
  options: NxSelectOption<T_Value>[]
): T_Value | undefined =>
  !isNil(value) && options.some((option) => option.value === value)
    ? value
    : undefined;

const calculateValue = <T_Value,>(
  value: T_Value | Nil,
  lastValue: T_Value,
  options: NxSelectOption<T_Value>[]
): T_Value | undefined => {
  if (isNil(value)) {
    return value === null ? lastValue : undefined;
  }

  return validValueOrUndefined(value, options);
};

const selectProps: SelectProps = {
  IconComponent: ChevronIcon,
  classes: {
    icon: styles.icon,
    root: styles.select,
    filled: styles.select_filled,
  },
  MenuProps: {
    getContentAnchorEl: null,
    classes: {
      list: styles.menu,
      paper: styles.menuWrapper,
    },
    anchorOrigin: {
      horizontal: "left",
      vertical: "bottom",
    },
  },
};

const itemClasses = { root: styles.item };

const NxSelect = function <T_Value = string>({
  className,
  disabled = false,
  emptyOption,
  error,
  id,
  label,
  name,
  onBlur,
  onChange,
  options,
  positionAbsoluteError,
  required,
  value,
}: NxSelectProps<T_Value>): ReactElement {
  const valueMapping = useMemo(() => createMapping(options), [options]);
  const lastValue = useRef(validValueOrUndefined(value, options));
  const finalValue = calculateValue(value, lastValue.current, options);
  lastValue.current = finalValue;

  const handleChange = useCallback(
    (event: ChangeEvent): void => {
      const value: T_Value | undefined = valueMapping.toValue(
        (event as ChangeEvent<HTMLInputElement>).target.value
      );
      lastValue.current = value;

      if (!onChange) {
        logMissingOnChange("Select", name);
        return;
      }

      onChange(value ?? null);
    },
    [name, onChange, valueMapping]
  );

  const handleBlur = useCallback(
    (event: ChangeEvent): void => {
      const value: T_Value | undefined = valueMapping.toValue(
        (event as ChangeEvent<HTMLInputElement>).target.value
      );
      if (!onBlur) {
        return;
      }

      onBlur(value ?? null);
    },
    [onBlur, valueMapping]
  );

  const menuItems = useMemo((): ReactNode[] => {
    const emptyOptionItem = emptyOption ? (
      <MenuItem key={"EMPTY_VALUE_KEY"} value={""} classes={itemClasses}>
        -
      </MenuItem>
    ) : null;

    const optionItems = options.map(({ value, label, disabled = false }) => {
      const textValue = valueMapping.fromValue(value);

      return (
        <MenuItem
          key={textValue}
          value={textValue}
          classes={itemClasses}
          disabled={disabled}
        >
          {label}
        </MenuItem>
      );
    });

    return [emptyOptionItem, ...optionItems];
  }, [emptyOption, options, valueMapping]);

  return (
    <NxGenericTextField
      className={className}
      disabled={disabled}
      error={error}
      id={id}
      label={label}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      value={finalValue === undefined ? "" : valueMapping.fromValue(finalValue)}
      positionAbsoluteError={positionAbsoluteError}
      required={required}
      SelectProps={selectProps}
      select
    >
      {menuItems}
    </NxGenericTextField>
  );
};

export default NxSelect;
