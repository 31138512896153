import { ReactNode, useEffect, useMemo, useState } from "react";
import styles from "./TableFilters.module.scss";
import { SearchParamsKeys } from "../../../shared/constants/search-params";
import {
  DEFAULT_DATE_TIME_FROM,
  DEFAULT_DATE_TIME_TO,
} from "../../../utils/time-utils";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { DownloadButton } from "../downloadButton/downloadButton";
import { SearchInput } from "../filterTable/searchInput/searchInput";
import NxSelect, { NxSelectOption } from "../nxSelect/NxSelect";
import { TransHelper } from "../../../utils/trans-helper";
import dayjs from "dayjs";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";

const PrefixTrans = TransHelper.getPrefixedTrans("FILTERS");
const SharedPrefixTrans = TransHelper.getPrefixedTrans("SHARED");

export type TableFiltersProps = {
  title: ReactNode;
  withDate?: boolean;
  withSearch?: boolean;
  disableFutureDates?: boolean;
  selectOptions?: NxSelectOption[];
  onDowload?: () => void;
  isDownloading?: boolean;
  defaultSelectValue?: string;
};

const TableFilters = ({
  withDate,
  withSearch,
  title,
  selectOptions,
  disableFutureDates,
  onDowload,
  isDownloading,
  defaultSelectValue,
}: TableFiltersProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const values = useMemo(() => {
    const params = new URLSearchParams(location.search);

    return {
      dateTo: params.get(SearchParamsKeys.dateTo) || DEFAULT_DATE_TIME_TO,
      dateFrom: params.get(SearchParamsKeys.dateFrom) || DEFAULT_DATE_TIME_FROM,
      status:
        params.get(SearchParamsKeys.status) || defaultSelectValue || "EXECUTED",
    };
  }, [location.search]);

  const [dateFrom, setDateFrom] = useState(new Date(values.dateFrom));
  const [dateTo, setDateTo] = useState(new Date(values.dateTo));
  const [dateValidationError, setDateValidationError] = useState<string | null>(
    null
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set(SearchParamsKeys.dateFrom, values.dateFrom);
    params.set(SearchParamsKeys.dateTo, values.dateTo);
    params.set(SearchParamsKeys.status, values.status);

    const url = `${location.pathname}?${params.toString()}`;
    history.push(url);
  }, []);

  useEffect(() => {
    const dayjsDateFrom = dayjs(dateFrom);
    const dayjsDateTo = dayjs(dateTo);

    const maxDaysAdded = dayjsDateFrom.add(30, "day");

    const maxDaysError =
      dayjsDateTo.isBefore(maxDaysAdded) || maxDaysAdded.isSame(dayjsDateTo)
        ? null
        : t("SHARED.ERROR_MESSAGE.MAX_DAYS", { maxDays: 31 });

    const invalidDateRangeError =
      dayjsDateFrom.isBefore(dayjsDateTo) || dayjsDateFrom.isSame(dayjsDateTo)
        ? null
        : t("SHARED.ERROR_MESSAGE.INVALID_DATE_RANGE");

    if (maxDaysError || invalidDateRangeError) {
      setDateValidationError((maxDaysError || invalidDateRangeError) as string);
      return;
    }

    setDateValidationError(null);
    handleFilterChange([
      {
        key: SearchParamsKeys.dateFrom,
        value: dayjs(dateFrom).format("YYYY-MM-DD"),
      },
      {
        key: SearchParamsKeys.dateTo,
        value: dayjs(dateTo).format("YYYY-MM-DD"),
      },
    ]);
  }, [dateFrom, dateTo]);

  const handleFilterChange = (
    values: { key: SearchParamsKeys; value: string }[]
  ) => {
    const params = new URLSearchParams(location.search);

    values.forEach(({ key, value }) => {
      params.set(key, value);
    });

    const url = `${location.pathname}?${params.toString()}`;
    history.push(url);
  };

  return (
    <div className={styles.filtersContainer}>
      <p className={styles.filtersTitle}>{title}</p>
      <div className={styles.filtersWrapper}>
        {withSearch && (
          <div>
            <SearchInput
              onChange={(value) =>
                handleFilterChange([{ key: SearchParamsKeys.phrase, value }])
              }
            />
          </div>
        )}
        {withDate && (
          <>
            <div>
              <CustomDatePicker value={dateFrom} onChange={setDateFrom} />
              <div className={styles.error}>{dateValidationError}</div>
            </div>
            <div>
              <CustomDatePicker value={dateTo} onChange={setDateTo} />
              <div className={styles.error}>{dateValidationError}</div>
            </div>
          </>
        )}
        {selectOptions && (
          <div>
            <NxSelect
              options={selectOptions}
              label={<SharedPrefixTrans>STATUS</SharedPrefixTrans>}
              className={styles.statusInput}
              value={values.status}
              onChange={(status) => {
                handleFilterChange([
                  {
                    key: SearchParamsKeys.status,
                    value: status || "",
                  },
                ]);
              }}
            />
          </div>
        )}
        <DownloadButton
          onClick={onDowload}
          url={""}
          asIcon
          isLoading={isDownloading}
        />
      </div>
    </div>
  );
};

export default TableFilters;
