import { Grid } from "@material-ui/core";
import { ValidationResponse } from "../ValidateForm.model";
import { ValidationSuccessEntity } from "./ValidationSuccessEntity";
import dayjs from "dayjs";
import styles from "./ValidationSuccess.module.scss";
import { DynamicList } from "./DynamicList";
import {currencyFormat} from "../../../shared/utils/currencyFormat";
import {DownloadReceiptButton} from '../../shared/DownloadReceiptButton/DownloadReceiptButton';
import React from 'react';

const excludeFields = [
  "firstName",
  "lastName",
  "type",
  "orderId",
  "payerSubject",
  "email",
  "platform",
  "merchantId",
  "productId",
  "amount", // this is part of the static fields
  "phoneNo",
  "swiftpayOrigin",
  "id"
];

type ValidationSuccessProps = {
  receiptDetails: ValidationResponse;
};

export const ValidationSuccess = ({
  receiptDetails,
}: ValidationSuccessProps) => {

  const {id, amount, totalAmount, payerName, attributes, executedOn} = receiptDetails
  return (
    <div className={styles.container}>
      <h2>Transaction details</h2>
      <Grid container spacing={3}>
        <ValidationSuccessEntity label="Receipt ID" value={receiptDetails.id} />
        <ValidationSuccessEntity
          label="Date"
          value={dayjs(receiptDetails.executedOn).format("DD-MM-YYYY")}
        />

        <ValidationSuccessEntity
          label="Payment ID"
          value={receiptDetails.referenceNo}
        />
        <ValidationSuccessEntity
          label="Amount"
          value={currencyFormat(receiptDetails.amount)}
        />
        <DynamicList
          attributes={receiptDetails.attributes}
          exclude={excludeFields}
        />
        <Grid item xs={12}>
          <DownloadReceiptButton
              email={attributes?.EMAIL_ADDRESS}
              exeutedOn={executedOn}
              id={id}
              payerName={attributes?.PAYOR_NAME}
              referenceNo={attributes?.referenceNo}
              ticketNumber={attributes?.ticketNumber}
              totalAmount={amount}
          />
      </Grid>
      </Grid>
    </div>
  );
};
