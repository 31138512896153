import {
  InputBaseClassKey,
  InputClassKey,
  InputLabelClassKey,
  SelectProps,
  TextField,
} from "@material-ui/core";
import { InputProps as StandardInputProps } from "@material-ui/core/Input/Input";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { isNil } from "lodash/fp";
import React, { ChangeEvent, MutableRefObject, ReactElement } from "react";
import styles from "./NxGenericTextField.module.scss";

// Component used by other components

type InputClasses = Partial<ClassNameMap<InputClassKey | InputBaseClassKey>>;

export const inputClasses = (
  disabled: boolean,
  adornedEnd: boolean,
  multiline?: boolean
): InputClasses => ({
  root: clsx(styles.inputWrapper, {
    [styles.inputWrapper_textarea]: multiline,
    [styles.inputWrapper_disabled]: disabled,
  }),
  focused: styles.inputWrapper_focused,
  error: styles.inputWrapper_error,
  adornedEnd: adornedEnd ? styles.inputWrapper_end : undefined,
  input: clsx(styles.input, {
    [styles.input_textarea]: multiline,
  }),
});

export const fieldClassName = styles.field;

export const labelClasses: Partial<ClassNameMap<InputLabelClassKey>> = {
  root: styles.label,
  shrink: styles.label_shrink,
  focused: styles.label_shrink,
};

export interface NxGenericTextFieldProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  error?: string;
  id?: string;
  inputRef?: MutableRefObject<HTMLInputElement | undefined>;
  label: React.ReactNode;
  multiline?: boolean;
  name?: string;
  onBlur?: (e: ChangeEvent) => void;
  onChange?: (e: ChangeEvent) => void;
  onFocus?: (e: ChangeEvent) => void;
  select?: boolean;
  SelectProps?: SelectProps;
  positionAbsoluteError?: boolean;
  required?: boolean;
  rows?: number;
  rowsMax?: number;
  type?: string;
  value?: string | string[];
  placeholder?: string;
  /**
   * Set value to 'true' to force label to stick to the top. 'False' causes it to center.
   * Undefined is a default and it fallbacks to material-ui behaviour.
   */
  shrinkLabel?: boolean;
  /**
   * Sets input mode property for html input
   */
  inputMode?: "numeric";
  inputComponent?: React.ElementType<{
    inputRef: (ref: HTMLInputElement | null) => void;
  }>;
  inputProps?: StandardInputProps["inputProps"];
}

export const fieldWrapperClassName = (className?: string): string =>
  clsx(className, styles.fieldWrapper);
export const errorClassName = (absolute?: boolean): string =>
  clsx(styles.error, { [styles.error_absolute]: absolute });

const NxGenericTextField = ({
  children,
  className,
  disabled = false,
  startAdornment,
  endAdornment,
  error,
  id,
  inputRef,
  label,
  multiline = false,
  name,
  onBlur,
  onChange,
  onFocus,
  rows,
  rowsMax,
  select,
  SelectProps,
  type,
  placeholder,
  value,
  shrinkLabel,
  inputMode,
  positionAbsoluteError = true,
  required,
  inputComponent,
  inputProps,
}: NxGenericTextFieldProps): ReactElement => {
  const InputProps = {
    classes: inputClasses(disabled, true, multiline),
    startAdornment,
    endAdornment,
    inputMode,
    inputComponent,
  } as Partial<StandardInputProps>;

  const InputLabelProps = { classes: labelClasses, shrink: shrinkLabel };

  return (
    <div className={fieldWrapperClassName(className)}>
      <TextField
        variant="filled"
        disabled={disabled}
        className={fieldClassName}
        error={!isNil(error)}
        id={id}
        inputRef={inputRef}
        inputProps={inputProps}
        InputProps={InputProps}
        InputLabelProps={InputLabelProps}
        label={label}
        multiline={multiline}
        name={name}
        onBlur={onBlur}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        required={required}
        minRows={rows}
        maxRows={rowsMax}
        select={select}
        SelectProps={SelectProps}
        type={type}
        value={value}
      >
        {children}
      </TextField>
      {!!error && (
        <div className={errorClassName(positionAbsoluteError)}>{error}</div>
      )}
    </div>
  );
};

export default React.memo(NxGenericTextField);
