import {GridColDef} from '@material-ui/data-grid';
import {tableCellNullFormatter} from './tableCellValueFormatter';

export const createColDefWithDefaultConfiguration = (colDefs: Array<GridColDef>): Array<GridColDef> =>
  colDefs.map((colDef: GridColDef): GridColDef => ({
    sortable: false,
    flex: 1,
    valueFormatter: tableCellNullFormatter,
    ...colDef
  }));
