import {ReactElement, useContext} from 'react';
import {MerchantContext} from '../../../App';
import {isEmpty} from 'lodash';
import styles from './MainLogo.module.scss';
import NpoLogo from '../../../assets/images/npo-logo.jpg';
interface MainLogoProps {
  className?: string;
  children: ReactElement;
  noLogo?: boolean;
}

export default function MainLogo({className, children, noLogo}: MainLogoProps): ReactElement | null {
  const {currentMerchant} = useContext(MerchantContext);

  if (!currentMerchant) {
     <img src={NpoLogo} alt='npo logo' />;
  }

  if (currentMerchant?.hasCustomTheme) {
    if (isEmpty(currentMerchant?.logoUrl) || noLogo) {
       <img src={NpoLogo} alt='npo logo' />;
    }

    return (
      <div className={styles.logo}>
        <img className={className} src={currentMerchant?.logoUrl} />
      </div>
    );
  }

  return children;
}