export enum OrderStatusEnum {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum MerchantStatusEnum {
  NEW = 'NEW',
  INCOMPLETE = 'INCOMPLETE',
  WAITING = 'WAITING',
  ACTIVATED = 'ACTIVATED',
}

export enum PaymentStatusEnum {
  EXECUTED = 'EXECUTED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
}