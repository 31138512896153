import {Grid} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import {GridRowParams} from '@material-ui/data-grid';
import {AxiosResponse} from 'axios';
import {ReactElement, useCallback, useContext, useEffect, useState,} from 'react';
import {useTranslation} from 'react-i18next';
import {MerchantContext} from '../../../App';
import {ADMIN_MERCHANT_URL, ADMIN_MERCHANTS_URL, INSTITUTIONS_URL,} from '../../../shared/constants/api-urls';
import useGet, {useGetRaw} from '../../../shared/hooks/use-get.hook';
import {EnvironmentType} from '../../../shared/model/EnvironmentType.model';
import {PageInfo} from '../../../shared/model/Page.model';
import {CustomerStatus} from './Customer.model';

import {filterFactory} from '../../../shared/utils/filterFactory';
import {TransHelper} from '../../../utils/trans-helper';

import MainContainer from '../../shared/main-container/MainContainer';
import {NxSelectOption} from '../../shared/nxSelect/NxSelect';
import DataTable from '../../shared/table/Table';
import styles from './Customer.module.scss';
import {ordersTableColumnDefinitions} from './CustomersTableColumnDefinitions';
import {SettlementInstitution} from '../../../shared/model/SettlementInstitution.model';
import {Merchant, MerchantRequestParams,} from '../../../shared/model/Merchant.model';
import dayjs from 'dayjs';
import {DEFAULT_DATE_TIME_FROM, DEFAULT_DATE_TIME_TO,} from '../../../utils/time-utils';
import {Details} from '../../shared/details/Details';
import CustomerDetailsContent from './customerDetails/CustomerDetailsContent';
import {useLocation} from 'react-router';
import {getFilterSearchParams} from '../../../utils/search-helpers';
import TableFilters from '../../shared/table/TableFilters';

export const PrefixTrans = TransHelper.getPrefixedTrans('CUSTOMER_ADMIN');
const SharedTrans = TransHelper.getPrefixedTrans('SHARED');

export default function Customer(): ReactElement {
    const {t} = useTranslation();
    const [institutions, setInstitutions] = useState<SettlementInstitution[]>();
    const [pageInfo, setPageInfo] = useState<PageInfo>();
    const {search} = useLocation();
    // const [paymentStatistics, setPaymentStatistics] = useState<OrderStatistic>();
    const [env, setEnv] = useState<EnvironmentType>(EnvironmentType.PRODUCTION);
    const [openDetails, setOpenDetails] = useState<boolean>(false);
    const [detailsId, setDetailsId] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingStats, setLoadingStats] = useState<boolean>(true);
    const [envError, setEnvError] = useState<boolean>(false);
    const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);
    const {currentMerchantId} = useContext(MerchantContext);
    const [merchantRequestParams, setMerchantRequestParams] = useState<
        MerchantRequestParams | undefined
    >({
        dateFrom: dayjs().format(DEFAULT_DATE_TIME_FROM),
        dateTo: dayjs().add(7, 'day').format(DEFAULT_DATE_TIME_TO),
        status: CustomerStatus.NEW,
    });
    const [merchants, setMerchants] = useState<Merchant[]>([]);
    const getInstitutions = useGet<SettlementInstitution[]>(INSTITUTIONS_URL);
    const paymentsCsvRequest = useGetRaw<string>(`${ADMIN_MERCHANTS_URL}/csv`);
    const getMerchants = useGet<Merchant[]>(ADMIN_MERCHANTS_URL);

    const updateData = useCallback(async () => {
        if (!merchantRequestParams) {
            return;
        }

        setLoadingStats(true);
        setLoading(true);
        setEnvError(false);

        try {
            const merchants = await getMerchants(
                filterFactory(merchantRequestParams)
            );
            setMerchants(merchants);
        } catch (err) {
            setMerchants([]);
            setEnvError(true);
        } finally {
            setLoading(false);
            setLoadingStats(false);
        }
    }, [merchantRequestParams]);

    useEffect(() => {
        getInstitutions(new URLSearchParams({env})).then(setInstitutions);
    }, [env]);

    useEffect(() => {
        if (institutions) {
            updateData();
        }
    }, [merchantRequestParams, institutions]);

    useEffect(() => {
        const params = getFilterSearchParams<CustomerStatus>(search);

        if (currentMerchantId) {
            setMerchantRequestParams((previousReqParams?: MerchantRequestParams) => ({
                ...previousReqParams,
                merchantId: currentMerchantId,
                env,
                ...params,
            }));
        }
    }, [search]);

    const handlePageChange = (pageNo: number): void => {
        if (currentMerchantId) {
            setMerchantRequestParams((previousReqParams?: MerchantRequestParams) => ({
                ...previousReqParams,
                pageNo: pageNo,
                merchantId: currentMerchantId,
                env,
            }));
        }
    };

    const handleDownloadCsv = async (): Promise<void> => {
        if (!merchantRequestParams) {
            return;
        }
        setDownloadInProgress(true);
        await paymentsCsvRequest(filterFactory(merchantRequestParams)).then(
            (response: AxiosResponse<string>) => {
                // As filename is included in header:
                // content-disposition: attachment; filename=swiftpay-payments-1650022658434.csv
                const contentDispositionHeaderValue: string =
                    response.headers['content-disposition'];
                const fileName = contentDispositionHeaderValue.split('filename=')[1];
                const data = new Blob([response.data], {type: 'text/csv'});
                const csvURL = window.URL.createObjectURL(data);
                const tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', fileName);
                tempLink.click();
            }
        );
        setDownloadInProgress(false);
    };

    const toggleDetails = (open: boolean, params?): void => {
        if (params) {
            setDetailsId(params.id.toString());
        } else {
            setDetailsId(null);
        }
        setOpenDetails(open);
    };

    const getStatusOptions = (): NxSelectOption[] =>
        Object.keys(CustomerStatus).map(
            (key) =>
                ({
                    label: t(`SHARED.CUSTOMER_ADMIN.STATUS.${CustomerStatus[key]}`),
                    value: CustomerStatus[key],
                } as NxSelectOption)
        );

    return (
        <>
            <Drawer anchor={'right'} open={openDetails}>
                {detailsId?.length ? (
                    <Details
                        id={detailsId}
                        url={ADMIN_MERCHANT_URL}
                        env={env}
                        onClose={(): void => toggleDetails(false)}
                    >
                        <CustomerDetailsContent/>
                    </Details>
                ) : (
                    <div>Error</div>
                )}
            </Drawer>
            <MainContainer>
                <Grid
                    className={styles.pageHeader}
                    container
                    direction='row'
                    justifyContent={'space-between'}
                >
                    <div className={styles.title}>
                        <PrefixTrans>TITLE</PrefixTrans>
                    </div>
                </Grid>
                <Grid className={styles.tableContainer}>
                    <TableFilters
                        title='Transaction history'
                        selectOptions={getStatusOptions()}
                        withDate
                        withSearch
                        onDowload={handleDownloadCsv}
                        isDownloading={downloadInProgress}
                        defaultSelectValue={CustomerStatus.NEW}
                    />
                    <DataTable
                        loading={loading}
                        pageInfo={pageInfo}
                        rows={merchants}
                        onRowClick={(param: GridRowParams): void =>
                            toggleDetails(true, param)
                        }
                        onPageChange={handlePageChange}
                        // pageSize={merchantRequestParams?.pageSize}
                        columns={ordersTableColumnDefinitions(t)}
                        noRowsMessage={
                            envError
                                ? t('SHARED.NO_DATA_ENV', {environmentType: env})
                                : t('SHARED.NO_DATA')
                        }
                    />
                </Grid>
            </MainContainer>
        </>
    );
}
