import styles from './OrderBatch.module.scss';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { FC, useState } from 'react';
import NxButton, { MaterialButtonVariant } from '../../shared/nxButton/NxButton';
import { BatchOptionButton } from './BatchOptionButton';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  selectedBatch: number | undefined;
  setSelectedBatch: (val: number) => void;
  isLoading: boolean;
};
const BATCH_OPTIONS = [50, 100, 500, 1000, 5000, 10000, 50000, 100000];

export const PickOrderBatch: FC<Props> = ({ onClose, onSubmit, selectedBatch, setSelectedBatch, isLoading }) => {

  return (
    <>
      <DialogTitle className={styles.title}>Place new order</DialogTitle>
      <p className={styles.description}>
        Order your receipts batch here. Simply select the quantity of receipts
        you need.
      </p>
      <DialogContent>
        <div className={styles.modalBody}>
          {BATCH_OPTIONS.map((batch) => (
            <BatchOptionButton
              key={batch}
              value={batch}
              onClick={setSelectedBatch}
              isSelected={selectedBatch === batch}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <NxButton
          variant={MaterialButtonVariant.OUTLINED}
          className={styles.actionButton}
          onClick={onClose}
        >
          Cancel
        </NxButton>
        <NxButton className={styles.actionButton} onClick={onSubmit} disabled={!selectedBatch} loaded={!isLoading}>
          Confirm
        </NxButton>
      </DialogActions>
    </>
  );
};
