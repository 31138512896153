import React, { ReactElement, useState } from "react";
import styles from "./ValidateReceipt.module.scss";
import { Formik, FormikProps } from "formik";
import {
  ValidateReceiptFields,
  ValidationDetails,
  ValidationResponse,
} from "./ValidateForm.model";
import { NxFormikInput } from "../shared/nxInput/NxFormikInput";
import { useTranslation } from "react-i18next";
import { NxFormikDatePicker } from "../shared/nxDatePicker/NxDatePicker";
import NxButton from "../shared/nxButton/NxButton";
import { Grid } from "@material-ui/core";
import TermsLink from "../shared/terms-link/TermsLink";
import { NxFormikCheckbox } from "../shared/nxCheckbox/NxCheckbox";
import { RoutesPaths } from "../../routes/routes.paths";
import { useHistory } from "react-router";
import usePost from "../../shared/hooks/use-post.hook";
import { VALIDATE_RECEIPT } from "../../shared/constants/api-urls";
import { LandingPageLayout } from "../shared/landingPageLayout/LandingPageLayout";
import { ValidateReceiptInfo } from "./ValidateReceiptInfo/ValidateReceiptInfo";
import { LandingPageLeftRight } from "../shared/landingPageLayout/LandingPageLeftRight";
import { BannerType } from "../shared/main-container/MainContainer.model";
import { ValidationSuccess } from "./ValidationSuccess/ValidationSuccess";

const ValidateReceipt = () => {
  const { t } = useTranslation();
  const [showErrorBanner, setShowErrorBanner] = React.useState(false);
  const validateReceipt = usePost<ValidationResponse, any>(VALIDATE_RECEIPT);
  const [isLoading, setIsLoading] = React.useState(false);
  const [receiptDetails, setReceiptDetails] =
    useState<ValidationResponse | null>(null);

  const handleSubmit = async (values: ValidateReceiptFields) => {
    try {
      setIsLoading(true);
      const response = await validateReceipt({
        receiptId: values.receiptNumber,
        amount: values.amount,
        bookedDate: values.date,
      });
      setReceiptDetails(response);
    } catch (error) {
      console.log(error);
      setShowErrorBanner(true);
    } finally {
      setIsLoading(false);
      setTimeout(() => setShowErrorBanner(false), 3000);
    }
  };
  const ValidationForm = ({
    handleSubmit,
    errors,
    isValid,
  }: FormikProps<ValidateReceiptFields>): ReactElement => (
    <div>
      <form onSubmit={handleSubmit}>
        <NxFormikInput
          className={styles.input}
          name="receiptNumber"
          label={t("RECEIPT_VALIDATION.RECEIPT_NUMBER")}
          type="text"
          placeholder={t("RECEIPT_VALIDATION.RECEIPT_NUMBER_PLACEHOLDER")}
        />
        <NxFormikInput
          className={styles.input}
          name="amount"
          label={t("RECEIPT_VALIDATION.RECEIPT_TOTAL_AMOUNT_PHP")}
          type="number"
          placeholder={t(
            "RECEIPT_VALIDATION.RECEIPT_TOTAL_AMOUNT_PHP_PLACEHOLDER"
          )}
        />
        <NxFormikDatePicker
          className={styles.input}
          name="date"
          label={t("RECEIPT_VALIDATION.RECEIPT_DATE")}
          placeholder={t("RECEIPT_VALIDATION.RECEIPT_DATE_PLACEHOLDER")}
        />
        <Grid
          justifyContent="space-between"
          alignItems="center"
          style={{ display: "flex", marginTop: "2rem" }}
        >
          <TermsLink
            className={styles.terms}
            checkbox={
              <NxFormikCheckbox
                name="termsAgreement"
                className={`${errors.termsAgreement && styles.checkboxInvalid}`}
              />
            }
          />
        </Grid>
        <NxButton
          onClick={handleSubmit}
          disabled={!isValid}
          className={styles.button}
          loaded={!isLoading}
        >
          {t("RECEIPT_VALIDATION.VALIDATE_CTA")}
        </NxButton>
      </form>
    </div>
  );

  return (
    <LandingPageLayout
      bannerMessage={
        showErrorBanner
          ? {
              type: BannerType.ERROR,
              message: t("RECEIPT_VALIDATION.VALIDATION_ERROR_BANNER_MESSAGE"),
            }
          : undefined
      }
    >
      <LandingPageLeftRight
        left={
          <div className={styles.loginForm}>
            <div className={styles.boxHeader}>
              {t("RECEIPT_VALIDATION.VALIDATE_RECEIPT")}
            </div>
            <Formik<ValidateReceiptFields>
              initialValues={{
                amount: 123,
                date: new Date("2024-02-03"),
                receiptNumber: "WEA-202402-000000005",
                termsAgreement: true,
                // amount: undefined,
                // date: new Date(),
                // receiptNumber: "",
                // termsAgreement: false,
              }}
              onSubmit={handleSubmit}
              validateOnMount={false}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {ValidationForm}
            </Formik>
          </div>
        }
        right={
          receiptDetails ? (
            <ValidationSuccess receiptDetails={receiptDetails} />
          ) : (
            <ValidateReceiptInfo />
          )
        }
      />
    </LandingPageLayout>
  );
};

export default ValidateReceipt;
