import { GridColDef } from '@material-ui/data-grid';
import { TFunction } from 'i18next';
import { createColDefWithDefaultConfiguration } from '../../../shared/utils/dataGridDefaultColumnDefValues';
import { tableCellDateFormatter } from '../../../shared/utils/tableCellValueFormatter';
import { StatusField } from '../../shared/status/StatusField';

export const ordersTableColumnDefinitions = (t: TFunction): Array<GridColDef> =>
  createColDefWithDefaultConfiguration([
    {
      field: 'createdOn',
      headerName: t('CUSTOMER_ADMIN.APPROVALS.CREATED_ON'),
      renderCell: tableCellDateFormatter,
    },
    // {
    //   field: 'id',
    //   headerName: t('CUSTOMER_ADMIN.APPROVALS.ID'),
    // },
    {
      field: 'name',
      headerName: t('CUSTOMER_ADMIN.APPROVALS.NAME'),
    },
    {
      field: 'recipentAdress',
      headerName: t('CUSTOMER_ADMIN.APPROVALS.ADDRESS'),
    },
    {
      field: 'tinNumber',
      headerName: t('CUSTOMER_ADMIN.APPROVALS.TIN'),
    },
    {
      field: 'email',
      headerName: t('CUSTOMER_ADMIN.APPROVALS.EMAIL'),
    },
    {
      field: 'phoneNumber',
      headerName: t('CUSTOMER_ADMIN.APPROVALS.PHONE'),
    },
    {
      field: 'websiteUrl',
      headerName: t('CUSTOMER_ADMIN.APPROVALS.URL'),
    },
    {
      field: 'branches',
      headerName: t('CUSTOMER_ADMIN.APPROVALS.BRANCHES'),
      renderCell: (params) => params.row.environments.length
    },
    {
      field: 'status',
      headerName: t('CUSTOMER_ADMIN.APPROVALS.STATUS'),
      renderCell: (params) => <StatusField status={params.row.status} />
    },
  ]);
