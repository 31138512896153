import MainContainer from "../main-container/MainContainer";
import { LandingPageNavbar } from "../landingPageNavbar/LandingPageNavbar";
import { MainContainerProps } from "../main-container/MainContainer.model";

type LandingPageLayoutProps = MainContainerProps;

export const LandingPageLayout = (props: LandingPageLayoutProps) => {
  return (
    <MainContainer
      {...props}
      CustomNavbar={<LandingPageNavbar />}
    ></MainContainer>
  );
};
