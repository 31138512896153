import { Button, Divider, Icon } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Link from "@material-ui/core/Link";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { MerchantContext, SecurityContext } from "../../../App";
import { ReactComponent as PersonIcon } from "../../../assets/images/person-icon.svg";
import { ReactComponent as StoreIcon } from "../../../assets/images/shop-icon.svg";
import { ReactComponent as IconChevron } from "../../../assets/images/icon-chevron.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/images/logout-icon.svg";
import { ReactComponent as LockIcon } from "../../../assets/images/lock-icon.svg";
import { ReactComponent as NotificationBellIcon } from "../../../assets/images/notification-bell.svg";
import Logo from "../../../assets/images/npo-logo-copy.jpg";
import { RoutesPaths } from "../../../routes/routes.paths";
import { Merchant } from "../../../shared/model/Merchant.model";
import { removeSessionToken } from "../../../utils/security";
import { TransHelper } from "../../../utils/trans-helper";
import styles from "./Navbar.module.scss";
import MainLogo from "../mainLogo/MainLogo";
import clsx from "clsx";
import classNames from "classnames";

export const PrefixTrans = TransHelper.getPrefixedTrans("NAVBAR");

export default function Navbar(): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();
  const [anchorElUserSettings, setAnchorElUserSettings] =
    useState<null | HTMLElement>();
  const open = Boolean(anchorEl);
  const openUserSettings = Boolean(anchorElUserSettings);

  const history = useHistory();
  const [blockShopChange, setBlockShopChange] = useState(false);

  const [anchorMerchants, setAnchorMerchants] = useState<null | HTMLElement>();
  const openMerchants = Boolean(anchorMerchants);

  const { merchants, currentMerchant, setCurrentMerchant } =
    useContext(MerchantContext);
  const { isLogged, setIsLogged, isAdmin, userData } =
    useContext(SecurityContext);

  const userName = userData?.firstName;
  const merchantName = currentMerchant?.name;

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUserSettings(event.currentTarget);
  };

  useEffect(() => {
    const disabledForRoutes = [
      RoutesPaths.SHOP_NEW,
      RoutesPaths.CHANGE_PASSWORD,
    ];
    for (let index = 0; index < disabledForRoutes.length; index++) {
      const route = disabledForRoutes[index];
      if (route === history.location.pathname) {
        setBlockShopChange(true);
        break;
      }
    }
  }, [history.location]);

  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const handleCloseUserMenu = (): void => {
    setAnchorElUserSettings(null);
  };

  const handleMerchantSwitch = (merchant: Merchant): void => {
    setAnchorEl(null);
    setCurrentMerchant(merchant);
  };

  const handleLogout = (): void => {
    removeSessionToken();
    setIsLogged(false);
    handleClose();
    history.replace("/login");
  };

  return (
    <AppBar position="static" color={"transparent"} className={styles.navbar}>
      <div className={styles.navBarContent}>
        <div className={styles.logoLinkContainer}>
          <Link href={RoutesPaths.ROOT} className={styles.logoLink}>
            <MainLogo className={styles.customLogo}>
              <img src={Logo} alt="npo logo" />
            </MainLogo>
          </Link>
        </div>
        {isLogged && (
          <>
            <div className={styles.links}>
              {isAdmin ? (
                <>
                  <div className={styles.linkWrapper}>
                    <Link
                      component={NavLink}
                      to={RoutesPaths.ROOT}
                      exact
                      className={styles.navLink}
                      activeClassName={styles.activeLink}
                    >
                      <PrefixTrans>REPORTS</PrefixTrans>
                    </Link>
                  </div>
                  <div className={styles.linkWrapper}>
                    <Link
                      component={NavLink}
                      to={RoutesPaths.DIGITAL_RECEIPTS}
                      exact
                      className={styles.navLink}
                      activeClassName={styles.activeLink}
                    >
                      <PrefixTrans>CUSTOMER_ADMIN</PrefixTrans>
                    </Link>
                  </div>
                  <div className={styles.linkWrapper}>
                    <Link
                      component={NavLink}
                      to={RoutesPaths.ADMIN_CUSTOMER}
                      exact
                      className={styles.navLink}
                      activeClassName={styles.activeLink}
                    >
                      <PrefixTrans>CLIENT_REPORTS</PrefixTrans>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.linkWrapper}>
                    <Link
                      component={NavLink}
                      to={RoutesPaths.DIGITAL_RECEIPTS}
                      exact
                      className={styles.navLink}
                      activeClassName={styles.activeLink}
                    >
                      <PrefixTrans>ORDERS</PrefixTrans>
                    </Link>
                  </div>
                  <div className={styles.linkWrapper}>
                    <Link
                      component={NavLink}
                      to={RoutesPaths.ROOT}
                      exact
                      className={styles.navLink}
                      activeClassName={styles.activeLink}
                    >
                      <PrefixTrans>RECEIPTS</PrefixTrans>
                    </Link>
                  </div>
                  <div className={styles.linkWrapper}>
                    <Link
                      component={NavLink}
                      to={RoutesPaths.PAYMENT_SETTINGS}
                      exact
                      className={styles.navLink}
                      activeClassName={styles.activeLink}
                    >
                      <PrefixTrans>PAYMENT_PORTAL</PrefixTrans>
                    </Link>
                  </div>
                  <div className={styles.linkWrapper}>
                    <Link
                      component={NavLink}
                      to={RoutesPaths.POS_APP}
                      exact
                      className={styles.navLink}
                      activeClassName={styles.activeLink}
                    >
                      <PrefixTrans>POS_APP</PrefixTrans>
                    </Link>
                  </div>
                  <div className={styles.linkWrapper}>
                    <a
                      href={RoutesPaths.REST_API}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classNames(styles.navLink, styles.anchor)}
                    >
                      <PrefixTrans>REST_API</PrefixTrans>
                    </a>
                  </div>

                  <div className={styles.linkWrapper}>
                    <Link
                      component={NavLink}
                      to={RoutesPaths.SETTINGS}
                      exact
                      className={styles.navLink}
                      activeClassName={styles.activeLink}
                    >
                      <PrefixTrans>SETTINGS</PrefixTrans>
                    </Link>
                  </div>
                </>
              )}
            </div>
            <div className={styles.toolbar}>
              <Button
                aria-label="notifications"
                aria-controls="menu-notifications-appbar"
                aria-haspopup="true"
                onClick={() => null}
                color="inherit"
                className={clsx(
                  styles.menuDropdownButton,
                  styles.notificationButton
                )}
              >
                <Icon className={styles.userMenuItemIcon}>
                  <NotificationBellIcon />
                </Icon>
              </Button>
              <Button
                aria-label="account of current user"
                aria-controls="menu-user-appbar"
                aria-haspopup="true"
                onClick={handleUserMenu}
                color="inherit"
                className={styles.menuDropdownButton}
              >
                <Icon
                  className={clsx(styles.userMenuItemIcon, styles.userSettings)}
                >
                  <PersonIcon />
                </Icon>
                <div>{userName}</div>
                <IconChevron />
              </Button>
              <Menu
                id="menu-user-appbar"
                anchorEl={anchorElUserSettings}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openUserSettings}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  onClick={(): void =>
                    history.push(RoutesPaths.CHANGE_PASSWORD)
                  }
                  className={styles.menuItem}
                >
                  <LockIcon className={styles.menuItemIcon} />
                  <PrefixTrans>CHANGE_PASSWORD</PrefixTrans>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout} className={styles.menuItem}>
                  <LogoutIcon className={styles.menuItemIcon} />
                  <PrefixTrans>LOGOUT</PrefixTrans>
                </MenuItem>
              </Menu>
              {!isAdmin && (
                <>
                  <Button
                    aria-label="account of current merchant"
                    aria-controls="menu-merchant-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    className={styles.menuDropdownButton}
                  >
                    <Icon className={styles.userMenuItemIcon}>
                      <StoreIcon />
                    </Icon>
                    <div>{merchantName}</div>
                    <IconChevron />
                  </Button>
                  <Menu
                    id="menu-merchant-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    {merchants?.map(
                      (merchant): ReactElement => (
                        <MenuItem
                          onClick={(): void => handleMerchantSwitch(merchant)}
                          key={merchant.id}
                          selected={merchant.id === currentMerchant?.id}
                          className={styles.menuItem}
                        >
                          <StoreIcon className={styles.menuItemIcon} />
                          {merchant.name}
                        </MenuItem>
                      )
                    )}
                    <Divider />
                    <MenuItem
                      className={styles.menuItem}
                      onClick={(): void => history.push(RoutesPaths.SHOP_NEW)}
                    >
                      <AddIcon className={styles.menuItemIcon} />
                      <PrefixTrans>NEW_SHOP</PrefixTrans>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </AppBar>
  );
}
