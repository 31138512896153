import classNames from 'classnames';
import React from 'react';
import styles from './EmptyTable.module.scss';
import NothingToDisplay from '../../../assets/images/nothing_to_display.png';

const EmptyTable = ({position, message}: {position?: string, message: string}): React.ReactElement => {
  const positionClass = position === 'relative';
  return (
    <div className={classNames(styles.emptyTableOverlay, {[styles.positionRelative]: positionClass})}>
      <img src={NothingToDisplay} alt="nothing to display" className={styles.image} />
      {message}
    </div>
  );
};

export default EmptyTable;
