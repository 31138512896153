import { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { SwiftpayRequest } from '../model/SwiftpayRequest.model';
import HttpService from '../services/http.service';
import useApiCatch from './api-error-catch.hook';

/**
 * Dedicated hook for perform PUT request.
 *
 * @param url - target url for API request.
 *
 * T - type of data returned by api call.
 * E - type of data passed as PUT body.
 *
 * @return function which allows execute configured PUT request. It has following arguments:
 * data - Data of type E which would be passed as PUT body, it can be null or undefined.
 * cancelTokenSource - CancelTokenSource which allows to abort request, it can be null or undefined.
 */
export default function usePut<T, E>(
  url: string
): (
  data?: E,
  cancelTokenSource?: CancelTokenSource | null,
  headers?: AxiosRequestConfig['headers'] | null
) => Promise<T | undefined> {
  const catchError = useApiCatch();

  return (
    data?: E,
    cancelTokenSource?: CancelTokenSource | null,
    headers?: AxiosRequestConfig['headers']
  ): Promise<T | undefined> => {
    const request: SwiftpayRequest = {
      url,
      method: 'PUT',
      data,
      cancelTokenSource,
    };

    return HttpService.request<T>(request, headers).catch(catchError);
  };
}

export const usePutExt = function usePutExt<T, E>(): (
  url: string,
  data?: E,
  cancelTokenSource?: CancelTokenSource | null
) => Promise<T | undefined> {
  const catchError = useApiCatch();

  return (
    url,
    data?: E,
    cancelTokenSource?: CancelTokenSource | null
  ): Promise<T | undefined> => {
    const request: SwiftpayRequest = {
      url,
      method: 'PUT',
      data,
      cancelTokenSource,
    };

    return HttpService.request<T>(request).catch(catchError);
  };
};
