import {
  GridCellParams,
  GridCellValue,
  GridValueFormatterParams,
} from "@material-ui/data-grid";
import { TFunction } from "i18next";
import { PaymentStatus } from "../../shared/model/PaymentStatus.model";
import styles from "./Payments.module.scss";

export const tableCellPaymentStatusFormatter =
  (t: TFunction): ((params: GridValueFormatterParams) => GridCellValue) =>
  (params: GridValueFormatterParams): GridCellValue =>
    params.value ? t(`SHARED.PAYMENT.STATUS.${params.value}`) : "-";

export const tableCellPaymentStatusClassNameFormatter = (
  params: GridCellParams
): string => {
  switch (PaymentStatus[String(params.value)]) {
    case PaymentStatus.PENDING:
      return styles.statusCellNeutral;
    default:
      return styles.statusCellPositive;
  }
};
