import React, { ReactNode } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import PageNotFound from "../page-not-found/PageNotFound";
import { KeyedRouteProps } from "../routes.model";
import { RoutesPaths } from "../routes.paths";

interface Props {
  routes: KeyedRouteProps[];
  isLogged?: boolean;
}

const RenderRoutes = ({ routes, isLogged }: Props): React.ReactElement => {
  const renderFunc = (
    route: KeyedRouteProps
  ): ((props: RouteComponentProps) => ReactNode) =>
    route.render ??
    ((props: RouteComponentProps): ReactNode => {
      if (window.location.pathname.includes("validate")) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <route.component {...props} />;
      }
      if (!route.component) {
        throw new Error(
          "Render or component property must be provided in route configuration"
        );
      }

      if (route.secured && !isLogged) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <Redirect push={false} to={RoutesPaths.HOME} />;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return <route.component {...props} />;
    });

  const handleRootPath = (): React.ReactElement | void => {
    const isPathRoot = window.location.pathname === RoutesPaths.ROOT;

    if (!isLogged) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return <Redirect push={false} to={RoutesPaths.HOME} />;
    }

    if (isPathRoot && isLogged) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return <Redirect push={false} to={RoutesPaths.ROOT} />;
    }
  };
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Switch>
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        routes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            exact={route.exact}
            render={renderFunc(route)}
          />
        ))
      }
      {handleRootPath()}
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default RenderRoutes;
