import { Button, Grid, InputAdornment } from '@material-ui/core';
import {
  GridColDef,
  GridRowData,
  GridRowId,
  GridRowParams,
} from '@material-ui/data-grid';
import { SearchOutlined } from '@material-ui/icons';
import PlusIcon from '@material-ui/icons/Add';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import { TFunction } from 'i18next';
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { MerchantContext } from '../../App';
import { RoutesPaths } from '../../routes/routes.paths';
import {
  PAY_BY_LINK,
  PAY_BY_LINK_PERMANENT,
} from '../../shared/constants/api-urls';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../shared/constants/app-constants';
import useGet from '../../shared/hooks/use-get.hook';
import { EnvironmentType } from '../../shared/model/EnvironmentType.model';
import { PageInfo } from '../../shared/model/Page.model';

import { createColDefWithDefaultConfiguration } from '../../shared/utils/dataGridDefaultColumnDefValues';
import { filterFactory } from '../../shared/utils/filterFactory';
import { tableCellCurrencyFormatter } from '../../shared/utils/tableCellValueFormatter';
import { TransHelper } from '../../utils/trans-helper';
import {
  tableCellPaymentStatusClassNameFormatter,
  tableCellPaymentStatusFormatter,
} from "../payments/paymentsTableFormatters";
import EnvSwitch from "../shared/envSwitch/EnvSwitch";
import Filters, { FilterParams } from "../shared/filterTable/Filters";
import MainContainer from "../shared/main-container/MainContainer";
import NxInput from "../shared/nxInput/NxInput";
import NxLoader, {
  NxLoaderColorVariant,
  NxLoaderVariant,
} from '../shared/nxLoader/NxLoader';
import { NxSelectOption } from '../shared/nxSelect/NxSelect';
import DataTable from '../shared/table/Table';
import {
  fromPaymentLinkTO,
  PaymentLink,
  PaymentLinkRequestParams,
  PaymentLinksResponse,
  PaymentLinkTO,
} from './PayByLink.model';
import styles from './PayByLink.module.scss';
import dayjs from 'dayjs';
import { DEFAULT_DATE_PICKER_VALUE_FORMAT } from '../shared/nxDatePicker/NxDatePicker';
import { PaymentStatusEnum } from '../../shared/constants/receipt-status';

export const PrefixTrans = TransHelper.getPrefixedTrans('PAY_BY_LINK');

const tableColumns = (t: TFunction): Array<GridColDef> =>
  createColDefWithDefaultConfiguration([
    {
      field: 'code',
      headerName: t('PAY_BY_LINK.TABLE_H_CODE'),
    },
    {
      field: 'amount',
      headerName: t('SHARED.AMOUNT'),
      valueFormatter: tableCellCurrencyFormatter,
    },
    {
      field: 'title',
      headerName: t('PAY_BY_LINK.TABLE_H_TITLE'),
    },
    {
      field: "status",
      headerName: t("PAY_BY_LINK.TABLE_H_PAYMENT"),
      valueFormatter: tableCellPaymentStatusFormatter(t),
      cellClassName: tableCellPaymentStatusClassNameFormatter,
    },
  ]);

const getDefaultRequestParam = (
  currentMerchantId: number,
  env: EnvironmentType
): PaymentLinkRequestParams => ({
  env,
  pageSize: DEFAULT_TABLE_PAGE_SIZE,
  merchantId: currentMerchantId,
  dateFrom: dayjs().format(DEFAULT_DATE_PICKER_VALUE_FORMAT),
  dateTo: dayjs().add(7, 'day').format(DEFAULT_DATE_PICKER_VALUE_FORMAT),
  status: PaymentStatusEnum.EXECUTED,
});

export default function PayByLink(): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const [paymentLinks, setPaymentLinks] = useState<Array<PaymentLink>>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  const [key, setKey] = useState<boolean>(false);
  const [currentPermalink, setCurrentPermalink] =
    useState<PaymentLinkTO | null>(null);
  const [envError, setEnvError] = useState<boolean>(false);
  const { currentMerchant } = useContext(MerchantContext);
  const currentMerchantId = currentMerchant?.id;
  const [env, setEnv] = useState<EnvironmentType>(EnvironmentType.PRODUCTION);
  const [requestParams, setRequestParams] = useState<
    PaymentLinkRequestParams | undefined
  >(
    currentMerchantId
      ? getDefaultRequestParam(currentMerchantId, env)
      : undefined
  );
  const getPayments = useGet<PaymentLinksResponse>(PAY_BY_LINK);
  const getPermalink = useGet<PaymentLinkTO[]>(PAY_BY_LINK_PERMANENT);

  const updateData = useCallback(() => {
    if (!requestParams) {
      return;
    }

    setEnvError(false);
    setLoading(true);

    getPayments(filterFactory(requestParams))
      .then((response: PaymentLinksResponse) => {
        setPageInfo({
          pageNo: response.pageNo,
          resultCount: response.resultCount,
          totalCount: response.totalCount,
        });
        const paymentLinks = response.result.map(fromPaymentLinkTO);
        setPaymentLinks(paymentLinks);
        setLoading(false);
      })
      .catch(() => {
        setPageInfo(undefined);
        setPaymentLinks([]);
        setLoading(false);
        setEnvError(true);
      });

    getPermalink(
      new URLSearchParams({ merchantId: `${requestParams.merchantId}` })
    ).then((res) => {
      const tempPerm = res.reduce((all, el: PaymentLinkTO) => {
        all[el.environmentType] = el;
        return all;
      }, {});
      setCurrentPermalink(tempPerm[requestParams.env]);
    });
  }, [requestParams]);

  useEffect(() => {
    updateData();
  }, [requestParams]);

  useEffect(() => {
    if (currentMerchantId) {
      setRequestParams((previousReqParams?: PaymentLinkRequestParams) =>
        previousReqParams
          ? {
              ...previousReqParams,
              merchantId: currentMerchantId,
              env,
            }
          : getDefaultRequestParam(currentMerchantId, env)
      );
    }
  }, [currentMerchantId]);

  /**
   * Listeners
   */
  const handleFilterChange = (filterParams: FilterParams): void => {
    setRequestParams(
      (previousReqParams?: PaymentLinkRequestParams) =>
        ({
          ...previousReqParams,
          ...filterParams,
          pageNo: 0,
          ...(previousReqParams?.env
            ? { env: previousReqParams?.env }
            : { env }),
        } as PaymentLinkRequestParams)
    );
  };

  const handlePageChange = (newPage: number): void => {
    setRequestParams(
      (previousReqParams?: PaymentLinkRequestParams) =>
        ({
          ...previousReqParams,
          pageNo: newPage,
          ...(previousReqParams?.env
            ? { env: previousReqParams?.env }
            : { env }),
        } as PaymentLinkRequestParams)
    );
  };

  const handleEnvChange = (environment: EnvironmentType): void => {
    setEnv(environment);
    if (currentMerchantId) {
      setRequestParams((previousReqParams?: PaymentLinkRequestParams) => ({
        ...previousReqParams,
        env: environment,
        merchantId: currentMerchantId,
      }));
    }
  };

  const debouncedSearchInput = useDebouncedCallback((value) => {
    if (!currentMerchantId) {
      return;
    }
    setRequestParams((previousReqParams?: PaymentLinkRequestParams) => ({
      ...previousReqParams,
      phrase: value,
      merchantId: currentMerchantId,
      ...(previousReqParams?.env ? { env: previousReqParams?.env } : { env }),
    }));
  }, 2000);

  const handleSearchInput = (e): void => {
    setSearch(e.target.value);
    debouncedSearchInput(e.target.value);
  };

  const getStatusOptions = (): NxSelectOption[] =>
    Object.keys(PaymentStatusEnum).map(
      (key) =>
        ({
          label: PaymentStatusEnum[key],
          value: PaymentStatusEnum[key],
        } as NxSelectOption)
    );

  return (
    <MainContainer>
      <Grid item xs={12} className={styles.pageHeader}>
        <div className={styles.title}>
          <PrefixTrans>TITLE</PrefixTrans>
        </div>
        <div className={styles.headerButtons}>
          <Button
            className={styles.newLinkButton}
            component={RouterLink}
            variant='contained'
            color='primary'
            to={`${RoutesPaths.PAY_BY_LINK_NEW}?env=${requestParams?.env}`}
          >
            <PlusIcon />
            <PrefixTrans>NEW_LINK</PrefixTrans>
          </Button>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={styles.paymentLinkWrapper}>
          <NxLoader
            variant={NxLoaderVariant.SMALL}
            colorVariant={NxLoaderColorVariant.TEXT}
            loaded={!loading}
          >
            {currentPermalink && (
              <>
                <p className={styles.text}>Your permalink:</p>
                <a href={currentPermalink?.paymentUrl}>
                  {currentPermalink?.paymentUrl}{' '}
                </a>
                <CopyToClipboard text={currentPermalink?.paymentUrl}>
                  <Button
                    onClick={(): void => {
                      setKey(true);
                      setTimeout(() => setKey(false), 3000);
                    }}
                    variant='contained'
                    color='primary'
                    startIcon={!key && <CopyIcon />}
                  >
                    {!key ? t('SHARED.COPY') : t('SHARED.COPIED')}
                  </Button>
                </CopyToClipboard>
              </>
            )}
          </NxLoader>
        </div>
      </Grid>
      <Grid className={styles.filtersContainer} item xs={12}>
        <Filters
          onChange={handleFilterChange}
          selectOptions={getStatusOptions()}
          disableFutureDates
        />
      </Grid>
      <Grid
        className={styles.tableHeader}
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <div className={styles.tableTitle}>
          <PrefixTrans>TABLE.HEADER_TITLE</PrefixTrans>
        </div>
        <div className={styles.searchWrapper}>
          <NxInput
            type='search'
            label={''}
            placeholder={'Search...'}
            value={search}
            className={styles.searchInput}
            onChange={handleSearchInput}
            startAdornment={
              <InputAdornment position='end'>
                <SearchOutlined />
              </InputAdornment>
            }
          />
        </div>
      </Grid>
      <Grid className={styles.tableContainer}>
        <DataTable
          loading={loading}
          pageInfo={pageInfo}
          rows={paymentLinks}
          onRowClick={(params: GridRowParams): void => {
            const paymentLink = params.row as PaymentLink;
            history.push(`/pay-by-link/edit/${paymentLink.code}`);
          }}
          onPageChange={handlePageChange}
          pageSize={requestParams?.pageSize}
          columns={tableColumns(t)}
          getRowId={(row: GridRowData): GridRowId => (row as PaymentLink).code}
          noRowsMessage={
            envError
              ? t('SHARED.NO_DATA_ENV', { environmentType: requestParams?.env })
              : t('SHARED.NO_DATA')
          }
        />
      </Grid>
    </MainContainer>
  );
}
