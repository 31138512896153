import React from 'react';

interface ConditionalWrapperType<T> {
  condition: boolean,
  wrapper: (children: T) => T,
  children: T
}

/**
 * Allows conditionally wrap React element into specified wrapper.
 *
 * @param condition - Boolean condition for wrapper, if true then children will be wrapped into wrapper function,
 *                    otherwise children will be returned without wrapping
 * @param wrapper   - Function which wraps children element conditionally, it pass children as argument and returns
 *                    React Element (children wrapped into wrapper)
 *                    e.g. (children) => <b>{children}</b>
 * @param children  - React element which is conditionally wrapped
 */
export const ConditionalWrapper = <T extends React.ReactElement>(
  {condition, wrapper, children}: ConditionalWrapperType<T>): React.ReactElement => condition
  ? wrapper(children)
  : children;
