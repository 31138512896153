import { Grid } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { GridRowParams } from "@material-ui/data-grid";
import { AxiosResponse } from "axios";
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { MerchantContext, SecurityContext } from "../../App";
import {
  ADMIN_PAYMENTS_URL,
  INSTITUTIONS_URL,
  NOT_PAYMENTS_BUT_ACTUALLY_YES_URL,
  NOT_PAYMENTS_BUT_ACTUALLY_YES__ADMIN_THIS_TIME_URL,
  PAYMENTS_URL,
} from "../../shared/constants/api-urls";
import { DEFAULT_TABLE_PAGE_SIZE } from "../../shared/constants/app-constants";
import useGet, { useGetRaw } from "../../shared/hooks/use-get.hook";
import { EnvironmentType } from "../../shared/model/EnvironmentType.model";
import { PageInfo } from "../../shared/model/Page.model";
import { filterFactory } from "../../shared/utils/filterFactory";
import { TransHelper } from "../../utils/trans-helper";
import MainContainer from "../shared/main-container/MainContainer";
import DataTable from "../shared/table/Table";
import {
  fromPaymentTo,
  Payment,
  PaymentRequestParams,
  PaymentsResponse,
  PaymentTO,
} from "./Payment.model";
import styles from "./Payments.module.scss";
import { paymentsTableColumnDefinitions } from "./paymentsTableColumnDefinitions";
import { SettlementInstitution } from "../../shared/model/SettlementInstitution.model";
import {
  DEFAULT_DATE_TIME_FROM,
  DEFAULT_DATE_TIME_TO,
} from "../../utils/time-utils";
import { PaymentStatusEnum } from "../../shared/constants/receipt-status";
import PaymentDetailsContent from "./paymentDetails/PaymentDetailsContent";
import { Details } from "../shared/details/Details";
import { useLocation } from "react-router";
import { getFilterSearchParams } from "../../utils/search-helpers";
import TableFilters from "../shared/table/TableFilters";

export const PrefixTrans = TransHelper.getPrefixedTrans("PAYMENTS");
const SharedTrans = TransHelper.getPrefixedTrans("SHARED");

const getDefaultRequestParam = (
  currentMerchantId: number,
  env: EnvironmentType,
  merchantId?: number[] | number,
  isAdmin?: boolean
) => ({
  pageSize: DEFAULT_TABLE_PAGE_SIZE,
  merchantId: !isAdmin ? currentMerchantId : merchantId,
  dateFrom: DEFAULT_DATE_TIME_FROM,
  dateTo: DEFAULT_DATE_TIME_TO,
  status: PaymentStatusEnum.EXECUTED,
  env: EnvironmentType.PRODUCTION,
});

export default function Payments(): ReactElement {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [institutions, setInstitutions] = useState<SettlementInstitution[]>();
  const [payments, setPayments] = useState<Array<PaymentTO>>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [env, setEnv] = useState<EnvironmentType>(EnvironmentType.PRODUCTION);
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [detailsId, setDetailsId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingStats, setLoadingStats] = useState<boolean>(true);
  const [envError, setEnvError] = useState<boolean>(false);
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);
  const { currentMerchantId, merchants } = useContext(MerchantContext);
  const { isAdmin } = useContext(SecurityContext);
  const [paymentRequestParams, setPaymentRequestParams] = useState<
    Record<string, any> | undefined
  >(
    currentMerchantId
      ? getDefaultRequestParam(
          currentMerchantId,
          env,
          merchants?.map((m) => m.id),
          isAdmin
        )
      : undefined
  );
  const USER_PAYMENTS_URL = isAdmin
    ? NOT_PAYMENTS_BUT_ACTUALLY_YES__ADMIN_THIS_TIME_URL
    : NOT_PAYMENTS_BUT_ACTUALLY_YES_URL;
  const getPayments = useGet<PaymentsResponse>(USER_PAYMENTS_URL);
  const getInstitutions = useGet<SettlementInstitution[]>(INSTITUTIONS_URL);
  const paymentsCsvRequest = useGetRaw<string>(`${USER_PAYMENTS_URL}/csv`);

  const updateData = useCallback(async () => {
    if (!paymentRequestParams) {
      return;
    }

    setLoadingStats(true);
    setLoading(true);
    setEnvError(false);

    // fetch table data
    await getPayments(filterFactory(paymentRequestParams))
      .then((response: PaymentsResponse) => {
        setPageInfo(response);
        setPayments(response.result);
      })
      .catch((): void => {
        setPageInfo({
          pageNo: 0,
          resultCount: 0,
          totalCount: 0,
        });
        setPayments([]);
        setEnvError(true);
      });
    setLoading(false);
  }, [paymentRequestParams]);

  const paymentsWithInstitutionName: Payment[] = useMemo(() => {
    return payments.map((payment: PaymentTO) => fromPaymentTo(payment));
  }, [payments, institutions]);

  useEffect(() => {
    getInstitutions(new URLSearchParams({ env })).then(setInstitutions);
  }, [env]);

  useEffect(() => {
    if (institutions) {
      updateData();
    }
  }, [paymentRequestParams, institutions]);

  useEffect(() => {
    const params = getFilterSearchParams<string>(search);

    if (currentMerchantId) {
      setPaymentRequestParams((previousReqParams?: PaymentRequestParams) =>
        previousReqParams
          ? {
              ...previousReqParams,
              merchantId: merchants?.map((m) => m.id),
              env,
              ...params,
            }
          : getDefaultRequestParam(
              currentMerchantId,
              env,
              merchants?.map((m) => m.id),
              isAdmin
            )
      );
    }
  }, [search]);

  useEffect(() => {
    if (currentMerchantId) {
      setPaymentRequestParams((previousReqParams?: PaymentRequestParams) =>
        previousReqParams
          ? {
              ...previousReqParams,
              merchantId: currentMerchantId,

              env,
            }
          : getDefaultRequestParam(
              currentMerchantId,
              env,
              merchants?.map((m) => m.id),
              isAdmin
            )
      );
    }
  }, [currentMerchantId, merchants]);

  const handlePageChange = (pageNo: number): void => {
    if (currentMerchantId) {
      setPaymentRequestParams((previousReqParams?: PaymentRequestParams) => ({
        ...previousReqParams,
        pageNo: pageNo,
        merchantId: currentMerchantId,
        env,
      }));
    }
  };

  const handleDownloadCsv = async (): Promise<void> => {
    if (!paymentRequestParams) {
      return;
    }
    setDownloadInProgress(true);
    await paymentsCsvRequest(filterFactory(paymentRequestParams)).then(
      (response: AxiosResponse<string>) => {
        // As filename is included in header:
        // content-disposition: attachment; filename=swiftpay-payments-1650022658434.csv
        const contentDispositionHeaderValue: string =
          response.headers["content-disposition"];
        const fileName = contentDispositionHeaderValue.split("filename=")[1];
        const data = new Blob([response.data], { type: "text/csv" });
        const csvURL = window.URL.createObjectURL(data);
        const tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", fileName);
        tempLink.click();
      }
    );
    setDownloadInProgress(false);
  };

  const toggleDetails = (open: boolean, params?): void => {
    if (params) {
      setDetailsId(params.id.toString());
    } else {
      setDetailsId(null);
    }
    setOpenDetails(open);
  };

  return (
    <>
      <Drawer anchor={"right"} open={openDetails}>
        {detailsId?.length ? (
          <Details
            env={env}
            id={detailsId}
            url={isAdmin ? ADMIN_PAYMENTS_URL : PAYMENTS_URL}
            onClose={(): void => toggleDetails(false)}
          >
            {/* @ts-gnore */}
            <PaymentDetailsContent />
          </Details>
        ) : (
          <div>Error</div>
        )}
      </Drawer>
      <MainContainer>
        <Grid
          className={styles.tableHeader}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <div className={styles.title}>
            <PrefixTrans>TABLE.HEADER_TITLE</PrefixTrans>
          </div>
        </Grid>
        <Grid className={styles.tableContainer}>
          <TableFilters
            title={"Payments"}
            withDate
            withSearch
            onDowload={handleDownloadCsv}
            isDownloading={downloadInProgress}
          />
          <DataTable
            loading={loading}
            pageInfo={pageInfo}
            rows={paymentsWithInstitutionName}
            onRowClick={(param: GridRowParams): void =>
              toggleDetails(true, param)
            }
            onPageChange={handlePageChange}
            pageSize={paymentRequestParams?.pageSize}
            columns={paymentsTableColumnDefinitions(t, isAdmin)}
            noRowsMessage={
              envError
                ? t("SHARED.NO_DATA_ENV", { environmentType: env })
                : t("SHARED.NO_DATA")
            }
          />
        </Grid>
      </MainContainer>
    </>
  );
}
