import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import useDelete from "../../shared/hooks/use-delete.hook";
import { PAY_BY_LINK } from "../../shared/constants/api-urls";
import NxButton, { MaterialButtonVariant } from "../shared/nxButton/NxButton";
import styles from "./PayByLink.module.scss";
import { RoutesPaths } from "../../routes/routes.paths";
import { Dialog } from "@material-ui/core";

interface PayByLinkInvalidateProps {
  code: string;
  open: boolean;
  onClose: () => void;
}

export default function PayByLinkInvalidate({
  code,
  open,
  onClose,
}: PayByLinkInvalidateProps): ReactElement {
  const invalidateLink = useDelete(`${PAY_BY_LINK}/${code}`);
  const history = useHistory();

  const handleInvalidate = (): void => {
    invalidateLink()
      .then(() => {
        onClose();
        history.push(RoutesPaths.PAY_BY_LINK);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <Dialog
      open={open}
      className={styles.modal}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={styles.paper}>
          <h2 className={styles.modal__title}>Confirm action</h2>
          <p>Are you sure?</p>
          <div className={styles.modal__footer}>
            <NxButton
              onClick={onClose}
              variant={MaterialButtonVariant.OUTLINED}
            >
              Cancel
            </NxButton>
            <NxButton
              onClick={handleInvalidate}
              className={styles.buttonInvalid}
            >
              Confirm
            </NxButton>
          </div>
        </div>
      </Fade>
    </Dialog>
  );
}
