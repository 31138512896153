import {useEffect, useState} from 'react';

const ONE_SEC_INTERVAL = 1000;

type CountDownOutput = {
  seconds: number;
  isActive: boolean;
  activateCountDown: (seconds?: number) => void;
  reset: () => void;
}

export function useCountDownTimerHook(initState = 0): CountDownOutput {

  const [seconds, setSeconds] = useState(initState);
  const [isActive, setIsActive] = useState(false);

  function activateCountDown(seconds?: number): void {
    if (seconds) {
      setSeconds(seconds);
    }
    setIsActive(true);
  }

  function reset(): void {
    setIsActive(false);
    setSeconds(initState);
  }

  useEffect(() => {
    let interval;
    if (isActive) {
      interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds => seconds - 1);
          return;
        }

        setIsActive(false);
        clearInterval(interval);
      }, ONE_SEC_INTERVAL);
    }
    return (): void => clearInterval(interval);
  }, [isActive, seconds]);

  return {seconds, isActive, activateCountDown, reset};
}
