import {CancelTokenSource} from 'axios';
import {SwiftpayRequest} from '../model/SwiftpayRequest.model';
import HttpService from '../services/http.service';
import useApiCatch from './api-error-catch.hook';
import useReCaptcha from './use-recaptcha.hook';

export type UsePostOutput<T, E> = {
  (data?: E | null,
   params?: URLSearchParams | null,
   cancelTokenSource?: CancelTokenSource | null): Promise<T>
};

/**
 * Dedicated hook for perform POST request.
 *
 * @param url - target url for API request.
 * @param withReCaptcha - determines if reCaptcha token should be sent with request
 *
 * T - type of data returned by api call.
 * E - type of data passed as POST body.
 *
 * @return function which allows execute configured POST request. It has following arguments:
 * data - Data of type E which would be passed as POST body, it can be null or undefined.
 * cancelTokenSource - CancelTokenSource which allows to abort request, it can be null or undefined.
 */
export default function usePost<T, E>(url: string, withReCaptcha = false): UsePostOutput<T, E> {

  const catchError = useApiCatch();
  const getRecaptchaToken = useReCaptcha(withReCaptcha);

  return async (data?: E | null,
                params?: URLSearchParams | null,
                cancelTokenSource?: CancelTokenSource | null): Promise<T> => {
    const reCaptchaToken = await getRecaptchaToken();

    const request: SwiftpayRequest = {
      url,
      method: 'POST',
      data,
      params,
      cancelTokenSource,
      reCaptchaToken
    };

    return HttpService.request<T>(request)
      .catch(catchError);
  };
}
