import {Grid} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import {ReactElement, useState} from 'react';

import {TransHelper} from '../../../../utils/trans-helper';
import NxLoader, {NxLoaderColorVariant, NxLoaderVariant,} from '../../../shared/nxLoader/NxLoader';
import {CustomerStatus} from '../Customer.model';
import styles from '../../../shared/details/Details..module.scss';
import {UploadButton} from '../../../shared/uploadButton/uploadButton';
import NxSelect from '../../../shared/nxSelect/NxSelect';
import {getAmountOrNullReplacement} from '../../../../shared/utils/valueFormatter';
import NxTextarea from '../../../shared/nxTextarea/NxTextarea';
import NxButton from '../../../shared/nxButton/NxButton';
import {StatusField} from '../../../shared/status/StatusField';
import {StatusTypes} from '../../../../shared/utils/statusMapper';
import usePut from '../../../../shared/hooks/use-put.hook';
import {MERCHANTS_URL} from '../../../../shared/constants/api-urls';

export const PrefixTrans = TransHelper.getPrefixedTrans(
    'CUSTOMER_ADMIN.APPROVALS'
);
export const SharedTrans = TransHelper.getPrefixedTrans('SHARED');

interface CustomerDetailsProps {
    data?: { [key: string]: any | undefined };
    onClose?: () => void;
    isLoading?: boolean;
}

export default function CustomerDetails({
                                            data,
                                            isLoading,
                                            onClose,

                                        }: CustomerDetailsProps): ReactElement {
    const [emailMessage, setEmailMessage] = useState('');
    const [isEmailSending, setIsEmailSending] = useState(false);
    //i'm really sorry but i have to do this
    const [status, setStatus] = useState<string>(data?.status);
    const updateStatus = usePut(`${MERCHANTS_URL}/${data?.id}`);

    const handleSendMessage = (): void => {
        setIsEmailSending(true);
        setTimeout(() => {
            setIsEmailSending(false);
        }, 1500);
        setEmailMessage('');
    };
    const handleOnEmailMessageChange = (e): void => {
        setEmailMessage(e.target.value);
    };

    const handleStatusUpdate = async (status: string | null) => {
        if (status && status !== data?.status) {
            await updateStatus({...data, status});
            setStatus(status);
        }

    };
    return (
        <Grid>
            <Grid container className={styles.wrapper}>
                <div className={styles.title}>
                    <PrefixTrans>TITLE</PrefixTrans>
                    <IconButton
                        color='inherit'
                        aria-label='close details'
                        onClick={onClose}
                        edge='start'
                        className={styles.title__close}
                    >
                        <CloseIcon/>
                    </IconButton>
                </div>
                <NxLoader
                    variant={NxLoaderVariant.SMALL}
                    colorVariant={NxLoaderColorVariant.TEXT}
                    loaded={!isLoading}
                >
                    <div className={styles.row}>
                        <Grid xs={6} item>
                            <div className={styles.label}>
                                <PrefixTrans>ID</PrefixTrans>
                            </div>
                            <div className={styles.value}>
                                {getAmountOrNullReplacement(data?.id)}
                            </div>
                        </Grid>
                        <Grid xs={6} item>
                            <div className={styles.label}>
                                <PrefixTrans>NAME</PrefixTrans>
                            </div>
                            <div className={styles.value}>
                                {getAmountOrNullReplacement(data?.name)}
                            </div>
                        </Grid>
                    </div>
                    <div className={styles.row}>
                        <Grid xs={6} item>
                            <div className={styles.label}>
                                <PrefixTrans>URL</PrefixTrans>
                            </div>
                            <div className={styles.value}>
                                {getAmountOrNullReplacement(data?.websiteUrl)}
                            </div>
                        </Grid>
                        <Grid xs={6} item>
                            <div className={styles.label}>
                                <PrefixTrans>ADDRESS</PrefixTrans>
                            </div>
                            <div className={styles.value}>
                                {getAmountOrNullReplacement(data?.recipientAddress)}
                            </div>
                        </Grid>
                    </div>
                    <div className={styles.row}>
                        <Grid xs={12} item>
                            <div className={styles.label}>
                                <PrefixTrans>STATUS</PrefixTrans>
                            </div>
                            <div className={styles.value}>
                                {data?.status && (
                                    <StatusField
                                        status={data?.status as unknown as StatusTypes}
                                    />
                                )}
                            </div>
                        </Grid>
                    </div>
                    <div className={styles.row}>
                        <Grid xs={6} item>
                            <div className={styles.label}>
                                <PrefixTrans>TIN</PrefixTrans>
                            </div>
                            <div className={styles.value}>
                                {getAmountOrNullReplacement(data?.accountNo)}
                            </div>
                        </Grid>
                        <Grid xs={6} item>
                            <div className={styles.label}>
                                <PrefixTrans>UPDATE_STATUS</PrefixTrans>
                            </div>
                            <div className={styles.value}>
                                <NxSelect
                                    label=''
                                    options={Object.keys(CustomerStatus).map((key) => ({
                                        label: key,
                                        value: key,
                                    }))}
                                    value={status || data?.status }
                                    onChange={handleStatusUpdate}
                                />
                            </div>
                        </Grid>
                    </div>
                    <div className={styles.row}>
                        <Grid xs={6} item>
                            <div className={styles.label}>
                                <PrefixTrans>UPLOAD</PrefixTrans>
                            </div>
                            <div className={styles.value}>
                                <UploadButton onUpload={() => {
                                }}/>
                            </div>
                        </Grid>
                    </div>
                    <div className={styles.label}>
                        <PrefixTrans>SEND_MESSAGE</PrefixTrans>
                    </div>
                    <Grid container xs={12} direction='column'>
                        <NxTextarea
                            label=''
                            value={emailMessage}
                            onChange={handleOnEmailMessageChange}
                            disabled={isEmailSending}
                        />
                    </Grid>
                    <Grid container xs={12} justifyContent='flex-end' style={{marginTop: '2rem'}}>
                        <NxButton
                            onClick={handleSendMessage}
                            loaded={!isEmailSending}
                            disabled={isEmailSending}
                        >
                            Send email
                        </NxButton>
                    </Grid>

                </NxLoader>
            </Grid>
        </Grid>
    );
}
