import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { isNil } from "lodash";
import { isNotDevelopmentMode } from "../../utils/app-utils";
import {
  getSessionTokenFromStorage,
  setSessionTokenToStorage,
} from "../../utils/security";
import { ErrorBody, HttpError } from "../model/Error.model";
import { SwiftpayRequest } from "../model/SwiftpayRequest.model";

export const ABORT_ERROR_NAME = "AbortError";
const API_HOST = isNotDevelopmentMode ? process.env.REACT_APP_API_URL : "";
const API_URL = `${API_HOST}/api`;
const AUTH_TOKEN_HEADER_NAME = "x-swiftpay-session-token";
const RECAPTCHA_TOKEN_HEADER_NAME = "X-ReCaptcha-Token";

axios.defaults.baseURL = API_URL;
axios.interceptors.request.use((request) => {
  const authToken = getSessionTokenFromStorage();

  const additionalHeaders = {};

  if (!isNil(authToken)) {
    additionalHeaders[AUTH_TOKEN_HEADER_NAME] = authToken;
  }

  request.headers = {
    ...request.headers,
    ...additionalHeaders,
  };

  return request;
});
axios.interceptors.response.use(
  (response) => {
    const headers = response.headers as Map<string, string>;
    const authToken = headers[AUTH_TOKEN_HEADER_NAME];

    if (!isNil(authToken)) {
      setSessionTokenToStorage(authToken);
    }

    return response;
  },
  (error) => {
    const errorBody = axios.isCancel(error)
      ? ({ errorCode: ABORT_ERROR_NAME } as ErrorBody)
      : error.response.data;

    return Promise.reject(new HttpError(error.response, errorBody));
  }
);

const getResponseData = async <ResType>(
  axiosRequest: Promise<AxiosResponse<ResType>>
): Promise<ResType> => {
  const response = await axiosRequest;
  return response.data;
};

class HttpService {
  request<ResType>(
    request: SwiftpayRequest,
    headers?: AxiosRequestConfig["headers"]
  ): Promise<ResType> {
    const { url, method, data, params, reCaptchaToken, cancelTokenSource } =
      request;
    return getResponseData<ResType>(
      axios.request({
        url,
        method,
        data,
        ...(params && { params }),
        ...(cancelTokenSource && { cancelToken: cancelTokenSource.token }),
        headers: {
          ...(reCaptchaToken && {
            [RECAPTCHA_TOKEN_HEADER_NAME]: reCaptchaToken,
          }),
          ...headers,
        },
      } as AxiosRequestConfig)
    );
  }

  rawRequest<ResType>(
    request: SwiftpayRequest
  ): Promise<AxiosResponse<ResType>> {
    const { url, method, data, params, reCaptchaToken, cancelTokenSource } =
      request;
    return axios.request<ResType, AxiosResponse<ResType>>({
      url,
      method,
      data,
      ...(params && { params }),
      ...(cancelTokenSource && { cancelToken: cancelTokenSource.token }),
      headers: {
        ...(reCaptchaToken && {
          [RECAPTCHA_TOKEN_HEADER_NAME]: reCaptchaToken,
        }),
      },
    } as AxiosRequestConfig);
  }
}

export default new HttpService();
