import { Grid, Card as MuiCard } from "@material-ui/core";
import { ReactNode } from "react";
import styles from "./Card.module.scss";

type CardProps = {
  img: string;
  children: ReactNode;
};

export const Card = ({ children, img }: CardProps) => {
  return (
    <MuiCard className={styles.card}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <div className={styles.imageWrapper}>
            <img src={img} />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={styles.contentContainer}>{children}</div>
        </Grid>
      </Grid>
    </MuiCard>
  );
};
