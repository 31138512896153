import { Card, Grid } from "@material-ui/core";
import styles from "./ValidateReceipt.module.scss";
import { cards } from "./ValidateReceiptInfo.model";

export const ValidateReceiptInfo = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        Ensure authenticity and protect your transactions with e-receipt
        verification:
      </h2>
      <Grid container spacing={1}>
        {cards.map(({ icon, title, text }) => (
          <Grid item xs={6} key={title}>
            <Card className={styles.card}>
              <img src={icon} />
              <h3>{title}</h3>
              <p>{text}</p>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
