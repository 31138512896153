import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { ReactElement } from 'react';
import dayjs from 'dayjs';

import { TABLE_DATE_TIME_FORMAT } from '../../../shared/constants/format-templates';

import { TransHelper } from '../../../utils/trans-helper';
import NxLoader, {
  NxLoaderColorVariant,
  NxLoaderVariant,
} from '../../shared/nxLoader/NxLoader';
import styles from '../../shared/details/Details..module.scss';
import { DownloadButton } from '../../shared/downloadButton/downloadButton';
import { StatusField } from '../../shared/status/StatusField';
import { OrderStatusEnum } from '../../../shared/constants/receipt-status';
import { FileDropzone } from '../../shared/dropzone/FileDropzone';
import clsx from 'clsx';
import NxButton, {
  MaterialButtonVariant,
} from '../../shared/nxButton/NxButton';

export const PrefixTrans = TransHelper.getPrefixedTrans('ORDERS');
export const SharedTrans = TransHelper.getPrefixedTrans('SHARED');

interface PaymentsDetailsProps {
  env?: string;
  data?: { [key: string]: any | undefined };
  onClose?: () => void;
  handleOnConfirm?: (id?: string) => Promise<void>;
  isLoading?: boolean;
}

export default function EReceiptDetailsContent({
  data,
  onClose,
  handleOnConfirm,
  isLoading,
}: PaymentsDetailsProps): ReactElement {
  return (
    <div>
      <Grid container className={styles.wrapper}>
        <div className={styles.title}>
          <PrefixTrans>DETAILS.TITLE</PrefixTrans>
          <IconButton
            color='inherit'
            aria-label='close details'
            onClick={onClose}
            edge='start'
            className={styles.title__close}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <NxLoader
          variant={NxLoaderVariant.SMALL}
          colorVariant={NxLoaderColorVariant.TEXT}
          loaded={!isLoading}
        >
          <div className={styles.row}>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>ORDER_ID</PrefixTrans>
              </div>
              <div className={styles.value}>{data?.orderNumber}</div>
            </Grid>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>CREATED</PrefixTrans>
              </div>
              <div className={styles.value}>
                {dayjs(data?.createdOn).format(TABLE_DATE_TIME_FORMAT)}
              </div>
            </Grid>
          </div>
          <div className={styles.row}>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>RELEASED_ON</PrefixTrans>
              </div>
              <div className={styles.value}>
                {data?.approvedOn
                  ? dayjs(data?.approvedOn).format(TABLE_DATE_TIME_FORMAT)
                  : '-'}
              </div>
            </Grid>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>QUANTITY</PrefixTrans>
              </div>
              <div className={styles.value}>{data?.totalReceiptsCount}</div>
            </Grid>
          </div>
          <div className={styles.row}>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>MIN_RECEIPT_ID</PrefixTrans>
              </div>
              <div className={styles.value}>{data?.minReceiptId || '-'}</div>
            </Grid>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>MAX_RECEIPT_ID</PrefixTrans>
              </div>
              <div className={styles.value}>{data?.maxReceiptId || '-'}</div>
            </Grid>
          </div>
          <div className={styles.row}>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>ORDERED_BY</PrefixTrans>
              </div>
              <div className={styles.value}>{data?.orderedBy || '-'}</div>
            </Grid>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>AMOUNT</PrefixTrans>
              </div>
              <div className={styles.value}>{`P${data?.amount || '-'}`}</div>
            </Grid>
          </div>
          <div className={styles.row}>
            <Grid xs={6} item>
              <div className={styles.label}>
                <PrefixTrans>STATUS</PrefixTrans>
              </div>
              <div className={styles.value}>
                {data?.status && <StatusField status={data?.status} />}
              </div>
            </Grid>
          </div>
          <div className={styles.row}>
            <Grid xs={12} item>
              <div className={styles.label}>
                <PrefixTrans>
                  {data?.status === OrderStatusEnum.NEW
                    ? 'NEW_ORDER.UPLOAD_PAYMENT_CONFIRMATION'
                    : 'NEW_ORDER.DOWNLOAD_PAYMENT_CONFIRMATION'}
                </PrefixTrans>
              </div>
              <div className={clsx(styles.value, styles.valueExtended)}>
                {data?.status === OrderStatusEnum.NEW ? (
                  <FileDropzone />
                ) : (
                  <DownloadButton url='https://ereceipt-qa-gateway-public-files.s3.ap-southeast-1.amazonaws.com/ereceipt_proof_of_payment.pdf' />
                )}
              </div>
            </Grid>
          </div>
        </NxLoader>
      </Grid>
      <Grid
        container
        justifyContent='flex-end'
        className={styles.actionButtonsContainer}
      >
        <NxButton onClick={onClose} variant={MaterialButtonVariant.OUTLINED}>
          Cancel
        </NxButton>
        <NxButton
          onClick={async () => {
            // definitely require refactor
            if (handleOnConfirm) {
              await handleOnConfirm(data?.id);
            }
            if (onClose) {
              onClose();
            }
          }}
        >
          Save
        </NxButton>
        <NxButton
          onClick={() => {
            window.location.href = data?.paymentUrl;
          }}
          disabled={data?.status !== OrderStatusEnum.NEW}
        >
          Pay now!
        </NxButton>
      </Grid>
    </div>
  );
}
