import dayjs, {Dayjs} from 'dayjs';

export interface UserDataTO<T_DATE = string> {
  id: string;
  mobileNo: string;
  email: string;
  firstName: string;
  lastName: string;
  documentNo: string;
  merchantIds: Array<number>
  role: 'ADMIN' | 'MERCHANT';
}

export type UserData = UserDataTO<Dayjs>

export const fromUserDataTO = (userDataTo: UserDataTO): UserData => ({
  ...userDataTo,
});
