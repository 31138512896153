import {
  MerchantStatusEnum,
  OrderStatusEnum,
  PaymentStatusEnum,
} from '../constants/receipt-status';

type StatusResponse = {
  type: 'SUCCESS' | 'ERROR' | 'DEFAULT';
  value: string;
};

export type StatusTypes =
  | MerchantStatusEnum
  | OrderStatusEnum
  | PaymentStatusEnum;

export function statusMapper(
  status:
    | OrderStatusEnum
    | MerchantStatusEnum
    | PaymentStatusEnum
): StatusResponse {
  switch (status) {
    case OrderStatusEnum.APPROVED:
    case MerchantStatusEnum.ACTIVATED:
    case PaymentStatusEnum.EXECUTED: {
      return {
        type: 'SUCCESS',
        value: status,
      };
    }
    case OrderStatusEnum.REJECTED: {
      return {
        type: 'ERROR',
        value: status,
      };
    }
    default: {
      return {
        type: 'DEFAULT',
        value: status,
      };
    }
  }
}
