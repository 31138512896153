import {useField, useFormikContext} from 'formik';
import React, {ReactElement, useCallback} from 'react';
import {isFieldRequired, useValidationSchema} from '../../../shared/form/ValidationService';
import {NxFormikProps} from '../../../shared/model/NxComponents.model';
import NxSelect, {NxSelectProps} from './NxSelect';

export type NxFormikSelectProps<T_Value = string> = Omit<NxSelectProps<T_Value>, NxFormikProps> & {
  name: string;
  validate?: (value: T_Value) => string | void | Promise<string | void>;
};

export const NxFormikSelect = function <T_Value = string>(props: NxFormikSelectProps<T_Value>): ReactElement {

  const context = useFormikContext();
  const [field, meta, helpers] = useField(props);
  const validationSchema = useValidationSchema();

  const onChange = useCallback((value: T_Value | null): void => {
    helpers.setValue(value, context.validateOnChange);
  }, [helpers, context.validateOnChange]);

  const onBlur = useCallback((): void => {
    helpers.setTouched(true, context.validateOnBlur);
  }, [helpers, context.validateOnBlur]);

  return <NxSelect {...field}
                   {...props}
                   onBlur={onBlur}
                   onChange={onChange}
                   error={meta.error}
                   disabled={props.disabled || context.isSubmitting}
                   required={props.required || isFieldRequired(validationSchema, field.name)} />;
};