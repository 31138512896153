import { Grid, InputAdornment } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { GridRowParams } from "@material-ui/data-grid";
import { SearchSharp } from "@material-ui/icons";
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { MerchantContext } from "../../App";
import {
  DISBURSEMENTS_URL,
  DISBURSEMENTS_SUMMARY_URL,
  SETTLEMENTS_URL,
  SETTLEMENTS_ACCOUNT_BALANCE_URL,
} from "../../shared/constants/api-urls";
import { DEFAULT_TABLE_PAGE_SIZE } from "../../shared/constants/app-constants";
import useGet from "../../shared/hooks/use-get.hook";
import { EnvironmentType } from "../../shared/model/EnvironmentType.model";
import { PageInfo } from "../../shared/model/Page.model";
import { DisbursementStatus } from "../../shared/model/DisbursementStatus.model";
import { currencyFormat } from "../../shared/utils/currencyFormat";
import { filterFactory } from "../../shared/utils/filterFactory";
import { TransHelper } from "../../utils/trans-helper";
import EnvSwitch from "../shared/envSwitch/EnvSwitch";
import Filters, { FilterParams } from "../shared/filterTable/Filters";
import MainContainer from "../shared/main-container/MainContainer";
import NxInput from "../shared/nxInput/NxInput";
import { NxSelectOption } from "../shared/nxSelect/NxSelect";
import DataTable from "../shared/table/Table";
import { DisbursementCard } from "./disbursementCard/DisbursementCard";
import styles from "./Disbursements.module.scss";
import { SettlementInstitution } from "../../shared/model/SettlementInstitution.model";
import dayjs from "dayjs";
import { DEFAULT_DATE_PICKER_VALUE_FORMAT } from "../shared/nxDatePicker/NxDatePicker";
import {
  Disbursement,
  DisbursementRequestParams,
  DisbursementStatistics,
  DisbursementTO,
  DisbursementsResponse,
  fromDisbursementTo,
  AccountBalanceReqParams,
  AccountBalance,
} from "./Disbursement.model";
import DisbursementDetails from "./disbursementDetails/DisbursementDetails";
import { disbursementsTableColumnDefinitions } from "./disbursementsTableColumnDefinitions";

export const PrefixTrans = TransHelper.getPrefixedTrans("DISBURSEMENTS");

const getDefaultRequestParam = (
  currentMerchantId: number,
  env: EnvironmentType
): DisbursementRequestParams => ({
  env,
  pageSize: DEFAULT_TABLE_PAGE_SIZE,
  merchantId: currentMerchantId,
  dateFrom: dayjs().format(DEFAULT_DATE_PICKER_VALUE_FORMAT),
  dateTo: dayjs().format(DEFAULT_DATE_PICKER_VALUE_FORMAT),
  status: DisbursementStatus.EXECUTED,
});

export default function Disbursements(): ReactElement {
  const { t } = useTranslation();
  const [institutions, setInstitutions] = useState<SettlementInstitution[]>();
  const [disbursements, setDisbursements] = useState<Array<DisbursementTO>>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [disbursementStatistics, setDisbursementStatistics] =
    useState<DisbursementStatistics>();
  const [settlementAccountBalance, setSettlementAccountBalance] =
    useState<AccountBalance>();
  const [search, setSearch] = useState<string>("");
  const [env, setEnv] = useState<EnvironmentType>(EnvironmentType.PRODUCTION);
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [detailsId, setDetailsId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingStats, setLoadingStats] = useState<boolean>(true);
  const [envError, setEnvError] = useState<boolean>(false);
  const { currentMerchantId } = useContext(MerchantContext);
  const [disbursementRequestParams, setDisbursementRequestParams] = useState<
    DisbursementRequestParams | undefined
  >(
    currentMerchantId
      ? getDefaultRequestParam(currentMerchantId, env)
      : undefined
  );
  const [accountBalanceRequestParams, setAccountBalanceRequestParams] =
    useState<AccountBalanceReqParams | undefined>();
  const getDisbursements = useGet<DisbursementsResponse>(DISBURSEMENTS_URL);
  const getDisbursementsSummary = useGet<DisbursementStatistics>(
    DISBURSEMENTS_SUMMARY_URL
  );
  const getSettlementsAccountBalance = useGet<AccountBalance>(
    SETTLEMENTS_ACCOUNT_BALANCE_URL
  );
  const getInstitutions = useGet<SettlementInstitution[]>(
    `${SETTLEMENTS_URL}/institutions`
  );

  const updateData = useCallback(async () => {
    if (!disbursementRequestParams) {
      return;
    }

    setLoadingStats(true);
    setLoading(true);
    setEnvError(false);

    // fetch summary stats
    await getDisbursementsSummary(filterFactory(disbursementRequestParams))
      .then(setDisbursementStatistics)
      .catch(() => setDisbursementStatistics(undefined));
    setLoadingStats(false);

    // fetch settlements account_balance
    getSettlementsAccountBalance(
      filterFactory(accountBalanceRequestParams ?? {})
    )
      .then(setSettlementAccountBalance)
      .catch(() => setSettlementAccountBalance(undefined))
      .finally(() => setLoadingStats(false));

    // fetch table data
    await getDisbursements(filterFactory(disbursementRequestParams))
      .then((response: DisbursementsResponse) => {
        setPageInfo(response);
        setDisbursements(response.result);
      })
      .catch((): void => {
        setPageInfo({
          pageNo: 0,
          resultCount: 0,
          totalCount: 0,
        });
        setDisbursements([]);
        setEnvError(true);
      });
    setLoading(false);
  }, [disbursementRequestParams]);

  const disbursementsWithInstitutionName: Disbursement[] = useMemo(() => {
    return disbursements.map((disbursement: DisbursementTO) =>
      fromDisbursementTo({
        ...disbursement,
        institutionName:
          institutions?.find(
            (institution) => institution.swift === disbursement.institutionCode
          )?.name || "",
      })
    );
  }, [disbursements, institutions]);

  useEffect(() => {
    getInstitutions(new URLSearchParams({ env })).then(setInstitutions);
  }, [env]);

  useEffect(() => {
    if (institutions) {
      updateData();
    }
  }, [disbursementRequestParams, institutions]);

  useEffect(() => {
    if (currentMerchantId) {
      setDisbursementRequestParams(
        (previousReqParams?: DisbursementRequestParams) =>
          previousReqParams
            ? {
                ...previousReqParams,
                merchantId: currentMerchantId,
                env,
              }
            : getDefaultRequestParam(currentMerchantId, env)
      );
      setAccountBalanceRequestParams({ merchantId: currentMerchantId });
    }
  }, [currentMerchantId]);

  const handleFilterChange = (filterParams: FilterParams): void => {
    if (currentMerchantId) {
      setDisbursementRequestParams(
        (previousReqParams?: DisbursementRequestParams) => ({
          ...previousReqParams,
          ...filterParams,
          ...(filterParams.status && {
            status: DisbursementStatus[filterParams.status],
          }),
          pageNo: 0,
          merchantId: currentMerchantId,
          env,
        })
      );
    }
  };

  const handlePageChange = (pageNo: number): void => {
    if (currentMerchantId) {
      setDisbursementRequestParams(
        (previousReqParams?: DisbursementRequestParams) => ({
          ...previousReqParams,
          pageNo: pageNo,
          merchantId: currentMerchantId,
          env,
        })
      );
    }
  };

  const toggleDetails = (open: boolean, params?): void => {
    if (params) {
      setDetailsId(params.id.toString());
    } else {
      setDetailsId(null);
    }
    setOpenDetails(open);
  };

  const debouncedSearchInput = useDebouncedCallback((value) => {
    if (currentMerchantId) {
      setDisbursementRequestParams(
        (previousReqParams?: DisbursementRequestParams) => ({
          ...previousReqParams,
          phrase: value,
          merchantId: currentMerchantId,
          env,
        })
      );
    }
  }, 2000);

  const handleSearchInput = (e): void => {
    setSearch(e.target.value);
    debouncedSearchInput(e.target.value);
  };

  const handleEnvChange = (environment: EnvironmentType): void => {
    setEnv(environment);
    if (currentMerchantId) {
      setDisbursementRequestParams(
        (previousReqParams?: DisbursementRequestParams) => ({
          ...previousReqParams,
          merchantId: currentMerchantId,
          env: environment,
        })
      );
    }
  };

  const getStatusOptions = (): NxSelectOption[] =>
    Object.keys(DisbursementStatus).map(
      (key) =>
        ({
          label: (
            <PrefixTrans>{`STATUS.${DisbursementStatus[key]}`}</PrefixTrans>
          ),
          value: DisbursementStatus[key],
        } as NxSelectOption)
    );

  const formatDisbursementStatisticValue = (value?: number): string =>
    value ? currencyFormat(value) : "N/A";

  return (
    <>
      <Drawer anchor={"right"} open={openDetails}>
        {detailsId?.length ? (
          <DisbursementDetails
            id={detailsId}
            env={env}
            institutions={institutions}
            onClose={(): void => toggleDetails(false)}
          />
        ) : (
          <div>Error</div>
        )}
      </Drawer>
      <MainContainer>
        <Grid
          className={styles.pageHeader}
          container
          direction="row"
          justifyContent={"space-between"}
        >
          <div className={styles.title}>
            <PrefixTrans>TITLE</PrefixTrans>
          </div>
        </Grid>
        <Grid className={styles.filtersContainer} item xs={12}>
          <Filters
            onChange={handleFilterChange}
            selectOptions={getStatusOptions()}
            disableFutureDates
          />
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <DisbursementCard
              title={t("DISBURSEMENTS.SUMMARY.AVG_AMOUNT")}
              count={formatDisbursementStatisticValue(
                disbursementStatistics?.averageAmount
              )}
              loading={loadingStats}
            />
          </Grid>
          <Grid item xs={3}>
            <DisbursementCard
              title={t("DISBURSEMENTS.SUMMARY.TOTAL_AMOUNT")}
              count={formatDisbursementStatisticValue(
                disbursementStatistics?.totalAmount
              )}
              loading={loadingStats}
            />
          </Grid>
          <Grid item xs={3}>
            <DisbursementCard
              title={t("DISBURSEMENTS.SUMMARY.TOTAL_COUNT")}
              count={disbursementStatistics?.totalCount ?? "N/A"}
              loading={loadingStats}
            />
          </Grid>
          <Grid item xs={3}>
            <DisbursementCard
              title={t("DISBURSEMENTS.SETTLEMENT_ACCOUNT_BALANCE")}
              count={formatDisbursementStatisticValue(
                settlementAccountBalance?.balance
              )}
              loading={loadingStats}
            />
          </Grid>
        </Grid>
        <Grid
          className={styles.tableHeader}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <div className={styles.tableTitle}>
            <PrefixTrans>TABLE.HEADER_TITLE</PrefixTrans>
          </div>
          <div className={styles.downloadSearchContainer}>
            <NxInput
              type="search"
              label={""}
              placeholder={"Search..."}
              value={search}
              className={styles.searchInput}
              onChange={handleSearchInput}
              startAdornment={
                <InputAdornment position="end">
                  <SearchSharp />
                </InputAdornment>
              }
            />
          </div>
        </Grid>
        <Grid className={styles.tableContainer}>
          <DataTable
            loading={loading}
            pageInfo={pageInfo}
            rows={disbursementsWithInstitutionName}
            onRowClick={(param: GridRowParams): void =>
              toggleDetails(true, param)
            }
            onPageChange={handlePageChange}
            pageSize={disbursementRequestParams?.pageSize}
            columns={disbursementsTableColumnDefinitions(t)}
            noRowsMessage={
              envError
                ? t("SHARED.NO_DATA_ENV", { environmentType: env })
                : t("SHARED.NO_DATA")
            }
          />
        </Grid>
      </MainContainer>
    </>
  );
}
