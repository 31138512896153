import React, {ReactElement, useEffect, useState} from 'react';
import styles from './PasswordStrength.module.scss';

interface IPasswordStrengthMap {
  text: string,
  progress: number,
  color: string
}

const passwordStrengthMap: Record<string, IPasswordStrengthMap> = {
  weak: {
    text: 'Weak',
    progress: 20,
    color: 'red'
  },
  medium: {
    text: 'Weak',
    progress: 65,
    color: '#6666bf'
  },
  strong: {
    text: 'Strong',
    progress: 100,
    color: '#54a254'
  }
};

export const strongPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})');
export const mediumPassword = new RegExp(
  '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})');

export default function PasswordStrength({password, className}: {password: string, className?: string}): ReactElement {

  const [passwordStyles, setPasswordStyles] = useState<IPasswordStrengthMap | undefined>(passwordStrengthMap['weak']);

  useEffect(() => {
    if (strongPassword.test(password)) {
      setPasswordStyles(passwordStrengthMap['strong']);
    } else if (mediumPassword.test(password)) {
      setPasswordStyles(passwordStrengthMap['medium']);
    } else {
      setPasswordStyles(passwordStrengthMap['weak']);
    }
  }, [password]);

  return (
    <>
      {
        password && passwordStyles &&
        <div className={`${styles.wrapper} ${className}`}>
          <div className={styles.text}>
            {passwordStyles?.text}
          </div>
          <div className={styles.bar}>
            <div className={styles.barProgress}
                 style={{width: `${passwordStyles.progress}%`, backgroundColor: passwordStyles.color}} />
          </div>
        </div>
      }
    </>
  );
}