export enum DisbursementStatus {
  // Newly created transactions
  PENDING = 'PENDING',
  // Transaction has been processed, meaning either debited or credited.
  EXECUTED = 'EXECUTED',
  // Error during execution of disbursement
  ERROR = 'ERROR',
  // Transfer was rejected due to failed validations (e.g. Pesonet) or cancelled after settlement (e.g. Instapay).
  REJECTED = 'REJECTED'
}
