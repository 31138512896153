import { useEffect, useState } from "react";
import styles from "../../ValidateReceipt/ValidateSuccess/ValidateSuccess.module.scss";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { useLocation } from "react-router";
import { filterFactory } from "../../../shared/utils/filterFactory";
import { PaymentStatusEnum } from "../../../shared/constants/receipt-status";
import { usePaymentStatus } from "../../../shared/services/http.paymentStatus.service";
import { usePaymentDetails } from "../../../shared/services/http.paymentStatus.service";
import { VALIDATE_PAYMENT } from "../../../shared/constants/api-urls";
import useGet from "../../../shared/hooks/use-get.hook";
import { LandingPageLayout } from "../../shared/landingPageLayout/LandingPageLayout";
import { LandingPageLeftRight } from "../../shared/landingPageLayout/LandingPageLeftRight";
import { ValidationSuccess } from "../../ValidateReceipt/ValidationSuccess/ValidationSuccess";
import NxLoader from "../../shared/nxLoader/NxLoader";
import { ValidationResponse } from "../../ValidateReceipt/ValidateForm.model";

const StatusSuccess = () => {
  const { search } = useLocation();
  const paymentId = new URLSearchParams(search).get("paymentId");
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatusEnum | null>(
    null
  );
  const [paymentDetails, setPaymentDetails] =
    useState<ValidationResponse | null>(null);

  const getPaymentStatus = usePaymentStatus();
  const getPaymentDeatils = usePaymentDetails();

  useEffect(() => {
    if (paymentId) {
      updatePaymentStatus();
    }
  }, [paymentId]);

  useEffect(() => {
    if (paymentStatus === PaymentStatusEnum.EXECUTED) {
      updatePaymentDetails();
    }
  }, [paymentStatus]);

  const updatePaymentStatus = async () => {
    try {
      const result = await getPaymentStatus(
        filterFactory({ env: "PRODUCTION" }),
        null,
        {
          "X-Swiftpay-Payment-Token": paymentId,
        }
      );
      setPaymentStatus(result);
      console.debug(result);
    } catch (err) {
      console.error(err);
    }
  };

  const updatePaymentDetails = async () => {
    try {
      const result = await getPaymentDeatils(
        null,
        null,
        {
          "X-Swiftpay-Payment-Token": paymentId,
        }
      );

      setPaymentDetails(result);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <LandingPageLayout>
      <LandingPageLeftRight
        left={
          <div className={styles.header}>
            <div className={styles.text}>
              {paymentStatus === PaymentStatusEnum.EXECUTED &&
                "Payment validated successfully"}{" "}
            </div>
            <div>
              {paymentStatus === PaymentStatusEnum.EXECUTED && (
                <CheckCircleOutlineIcon className={styles.successIcon} />
              )}
            </div>
          </div>
        }
        right={
          !paymentDetails ? (
            <NxLoader />
          ) : (
            <ValidationSuccess receiptDetails={paymentDetails} />
          )
        }
      />
    </LandingPageLayout>
  );
};
export default StatusSuccess;
