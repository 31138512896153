export const filterFactory = (state: object): URLSearchParams => {
  const filter: URLSearchParams = new URLSearchParams();

  Object.keys(state).map((key) => {
    if (state[key] === undefined || state[key] === null || state[key] === '') {
      return;
    } else {
      filter.append(key, state[key]);
    }
  });

  return filter;
};
