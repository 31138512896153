
import dayjs ,{ Dayjs, isDayjs } from 'dayjs';
import { TABLE_DATE_TIME_FORMAT } from '../constants/format-templates';
import { currencyFormat } from './currencyFormat';

type Value = string | number | boolean | Date | null | object;

export const getValueOrNullReplacement = (value?: Value): Value => value ?? '-';
export const getFormattedDateOrNullReplacement = (value?: Value): Value => {

  if (value && dayjs(value as string).isValid()) {
    return dayjs(value as string).format('DD-MM-YYYY');
  }

  return getValueOrNullReplacement(value);
};

export const getAmountOrNullReplacement = (value?: Value): Value => {
  if (!isNaN(Number(value))) {
    return currencyFormat(value as number);
  }

  return getValueOrNullReplacement(value);
};
