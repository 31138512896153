import { Button } from '@material-ui/core';
import styles from './OrderBatch.module.scss';
import clsx from 'clsx';

export const BatchOptionButton = ({
  value,
  isSelected,
  onClick,
}: {
  value: number;
  onClick;
  isSelected: boolean;
}) => {
  const formattedNumber = value.toLocaleString();
  return (
    <Button
      variant='outlined'
      onClick={() => onClick(value)}
      className={clsx(styles.batchButton, {
        [styles.selected]: isSelected,
      })}
    >
      {formattedNumber}
    </Button>
  );
};
