import React, {ReactElement} from 'react';
import InputMask from 'react-input-mask';
import {MOBILE_NUMBER_PREFIX} from '../../../shared/constants/form-validation';
import NxInput, {NxInputProps} from '../nxInput/NxInput';

const MASK_CHAR = '';
// 9 means 0-9 number in react-input-mask default mask
export const MOBILE_NUMBER_MASK = `${MOBILE_NUMBER_PREFIX}999 999 9999`;

type PhoneTextFieldProps = NxInputProps & {
  mask?: string;
}

export default function PhoneTextField(props: PhoneTextFieldProps): ReactElement {
  return (
    // InputMask passes down every prop except the following ones:
    // onChange, onPaste, onMouseDown, onFocus, onBlur, value, disabled, readOnly.
    <InputMask {...props}
               mask={props.mask ? props.mask : MOBILE_NUMBER_MASK}
               maskChar={MASK_CHAR}
               alwaysShowMask>
      {(passedDownProps): ReactElement => <NxInput {...passedDownProps} disabled={props.disabled} type='tel' />}
    </InputMask>
  );
}
