import React from 'react';
import {Trans} from 'react-i18next';
import styles from './PageNotFound.module.scss';

export default function PageNotFound(): React.ReactElement {
  return (
    <h1 className={styles.title}>
      <Trans>PAGE_NOT_FOUND</Trans>
    </h1>
  );
}
