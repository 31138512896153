import type { ReactDatePickerCustomHeaderProps } from "react-datepicker";

import { ReactComponent as ChevronLeft } from "../../../assets/images/icon-chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../../assets/images/icon-chevron-right.svg";

import styles from "./CustomDatePicker.module.scss";

type HeaderProps = ReactDatePickerCustomHeaderProps;

export const Header = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: HeaderProps) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
      className={styles.headerWrapper}
    >
      <p className={styles.headerYear}>{`${date.toLocaleString("default", {
        month: "long",
      })} ${date.getFullYear()}`}</p>
      <div
        style={{
          display: "flex",
        }}
      >
        <ChevronLeft className={styles.headerIcon} onClick={decreaseMonth} />
        <ChevronRight className={styles.headerIcon} onClick={increaseMonth} />
      </div>
    </div>
  );
};
