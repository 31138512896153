import NxButton, { MaterialButtonVariant } from "../nxButton/NxButton";
import { TransHelper } from "../../../utils/trans-helper";
import { saveAs } from "file-saver";
import { ReactComponent as DownloadIcon } from "../../../assets/images/download.svg";
import styles from "./DownloadButton.module.scss";

export type DownloadButtonProps = {
  url?: string;
  onClick?: () => void;
  asIcon?: boolean;
  isLoading?: boolean;
};

const SharedPrefixTrans = TransHelper.getPrefixedTrans("SHARED");

export const DownloadButton = ({
  url,
  asIcon,
  isLoading,
  onClick,
}: DownloadButtonProps) => {
  const handleFileDownload = async () => {
    saveAs(url as string, "ereceipt_proof_of_payment.pdf");
  };

  if (asIcon) {
    return (
      <NxButton
        className={styles.button}
        onClick={onClick}
        loaded={!isLoading}
        variant={MaterialButtonVariant.OUTLINED}
      >
        <DownloadIcon />
      </NxButton>
    );
  }

  return (
    <NxButton onClick={handleFileDownload}>
      <SharedPrefixTrans>DOWNLOAD</SharedPrefixTrans>
    </NxButton>
  );
};
