import { Grid } from '@material-ui/core';
import { TransHelper } from '../../../utils/trans-helper';
import { Product, ProductEnum } from '../../../shared/model/Product.model';
import styles from './PaymentDetails.module.scss';
import { currencyFormat } from '../../../shared/utils/currencyFormat';
const PrefixTrans = TransHelper.getPrefixedTrans('PAYMENTS.PRODUCT');

export const PaymentProductDetails = ({ paymentData }: any) => {
  const {
    REFERENCE_NUMBER,
    TAX_DECLARATION_NUMBER,
    amount,
    NAME,
    EMAIL_ADDRESS,
    CONTACT_NUMBER,
    BUSINESS_NAME,
    PAYOR_NAME,
    TICKET_NUMBER,
    VIOLATION_TYPE,
    id,
  } = paymentData;

  const product: Omit<Product, 'id'> = {
    REFERENCE_NUMBER,
    TAX_DECLARATION_NUMBER,
    amount,
    NAME,
    EMAIL_ADDRESS,
    CONTACT_NUMBER,
    BUSINESS_NAME,
    PAYOR_NAME,
    TICKET_NUMBER,
    VIOLATION_TYPE,
  };

  return (
    <>
      {id && <h3>Product details</h3>}
      <Grid container>
        {Object.entries(product).map((data) => {
          if (data[1]) {
            if (data[0] === ProductEnum.amount) {
              return (
                <Grid xs={6} item key={data[0]}>
                  <div className={styles.label}>
                    <PrefixTrans>{data[0]}</PrefixTrans>
                  </div>
                  <div className={styles.value}>
                    {currencyFormat(Number(data[1]))}
                  </div>
                </Grid>
              );
            }
            return (
              <Grid xs={6} item key={data[0]}>
                <div className={styles.label}>
                  <PrefixTrans>{data[0]}</PrefixTrans>
                </div>
                <div className={styles.value}>{data[1]}</div>
              </Grid>
            );
          }
          return null;
        })}
      </Grid>
    </>
  );
};
