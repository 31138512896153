import {GridColDef} from '@material-ui/data-grid';
import {TFunction} from 'i18next';
import {createColDefWithDefaultConfiguration} from '../../shared/utils/dataGridDefaultColumnDefValues';
import {tableCellCurrencyFormatter, tableCellDateFormatter} from '../../shared/utils/tableCellValueFormatter';
import styles from './Disbursements.module.scss';
import {
  tableCellDestinationFormatter,
  tableCellDisbursementStatusClassNameFormatter,
  tableCellDisbursementStatusFormatter
} from './disbursementsTableFormatters';
import CopyCellRender from '../shared/table/renders/copyCellRender/CopyCellRender';

export const disbursementsTableColumnDefinitions =
  (t: TFunction): Array<GridColDef> => createColDefWithDefaultConfiguration(
    [
      {
        field: 'settlementTime',
        headerName: t('DISBURSEMENTS.SETTLEMENT_TIME'),
        valueFormatter: tableCellDateFormatter
      },
      {
        field: 'shortId',
        headerName: t('DISBURSEMENTS.DISBURSEMENT_ID'),
        renderCell: (params) => CopyCellRender(params, params.id)
      },
      {
        field: 'merchantReferenceNo',
        headerName: t('DISBURSEMENTS.MERCHANT_REFERENCE_NO'),
        cellClassName: styles.orderNo,
        renderCell: CopyCellRender
      },
      {
        field: 'registrationTime',
        headerName: t('DISBURSEMENTS.REGISTRATION_TIME'),
        valueFormatter: tableCellDateFormatter
      },
      {
        field: 'destination',
        headerName: t('DISBURSEMENTS.DESTINATION'),
        valueFormatter: tableCellDestinationFormatter(t)
      },
      {
        field: 'disbursementStatus',
        headerName: t('DISBURSEMENTS.DISBURSEMENT_STATUS'),
        valueFormatter: tableCellDisbursementStatusFormatter(t),
        cellClassName: tableCellDisbursementStatusClassNameFormatter
      },
      {
        field: 'amount',
        headerName: t('SHARED.AMOUNT'),
        valueFormatter: tableCellCurrencyFormatter
      }
    ]
  );
