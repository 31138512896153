import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TransHelper } from "../../../utils/trans-helper";
import NxDatePicker from "../nxDatePicker/NxDatePicker";
import NxSelect, { NxSelectOption } from "../nxSelect/NxSelect";
import styles from "./Filter.module.scss";
import dayjs from "dayjs";
import { Formik } from "formik";
import { SchemaOf, object, string } from "yup";
import {
  DEFAULT_DATE_TIME_FROM,
  DEFAULT_DATE_TIME_TO,
} from "../../../utils/time-utils";
import { useHistory, useLocation } from "react-router";
import { SearchParamsKeys } from "../../../shared/constants/search-params";
import { SearchInput } from "./searchInput/searchInput";

export interface FiltersProps {
  className?: string;
  onChange: (values: FilterParams) => void;
  value?: string;
  selectOptions: NxSelectOption[];
  disableFutureDates?: boolean;
  isCurrentDate?: boolean;
}

export type FilterParams = {
  dateFrom?: string;
  dateTo?: string;
  status?: string;
};

const emptyFilterInputValues: FilterInputs = {
  dateFrom: "",
  dateTo: "",
  status: "EXECUTED",
};

const filterParamsInitValues: FilterParams = {
  dateFrom: DEFAULT_DATE_TIME_FROM,
  dateTo: DEFAULT_DATE_TIME_TO,
  status: "EXECUTED",
};

type FilterInputs = Required<FilterParams>;

const filterInputsInitValues: FilterInputs =
  filterParamsInitValues as FilterInputs;

const PrefixTrans = TransHelper.getPrefixedTrans("FILTERS");
const SharedPrefixTrans = TransHelper.getPrefixedTrans("SHARED");

const filedRequiredErrorMessage = "SHARED.ERROR_MESSAGE.FIELD_REQUIRED";

function Filters({
  onChange,
  selectOptions,
  disableFutureDates,
  isCurrentDate = true,
}: FiltersProps): ReactElement {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const initialValues = isCurrentDate
    ? filterInputsInitValues
    : emptyFilterInputValues;

  const validationSchema: SchemaOf<FilterParams> = object({
    dateFrom: string().defined(t(filedRequiredErrorMessage)),
    dateTo: string()
      .defined(t(filedRequiredErrorMessage))
      .test(
        "maxDays",
        t("SHARED.ERROR_MESSAGE.MAX_DAYS", { maxDays: 31 }),
        (date, context) => {
          const dateFrom = dayjs(context.parent.dateFrom);
          const maxDaysAdded = dateFrom.add(30, "day");
          const dateTo = dayjs(date);

          return dateTo.isBefore(maxDaysAdded) || maxDaysAdded.isSame(dateTo);
        }
      )
      .test(
        "validDateRange",
        t("SHARED.ERROR_MESSAGE.INVALID_DATE_RANGE"),
        (date, context) => {
          if (!context.parent.dateFrom) {
            return true;
          }

          const dateFrom = dayjs(context.parent.dateFrom);
          const dateTo = dayjs(date);

          return dateFrom.isBefore(dateTo) || dateFrom.isSame(dateTo);
        }
      ),
    status: string().oneOf(
      [...selectOptions.map((o) => o.value), undefined, ""],
      t("SHARED.ERROR_MESSAGE.INVALID_OPTION")
    ),
  }).defined();

  const handleFilterChange = (key: SearchParamsKeys, value: string) => {
    const params = new URLSearchParams(location.search);
    params.set(key, value);

    const url = `${location.pathname}?${params.toString()}`;
    history.push(url);
  };

  const values = useMemo(() => {
    const params = new URLSearchParams(location.search);

    return {
      dateTo: params.get(SearchParamsKeys.dateTo) || DEFAULT_DATE_TIME_FROM,
      dateFrom: params.get(SearchParamsKeys.dateFrom) || DEFAULT_DATE_TIME_TO,
      status: params.get(SearchParamsKeys.status) || "EXECUTED",
    };
  }, [location.search]);

  return (
    <Formik<FilterParams>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onChange}
    >
      {({ errors, handleSubmit, setFieldValue, resetForm }) => (
        <form className={styles.filterWrapper} onSubmit={handleSubmit}>
          <div>
            <NxDatePicker
              label={""}
              className={styles.filterInput}
              maxDate={values.dateTo || undefined}
              value={values.dateFrom}
              disableFutureDates={disableFutureDates}
              placeholder={t("FILTERS.INPUT.DATE_FROM.PLACEHOLDER")}
              onChange={(date) => {
                setFieldValue("dateFrom", date || "");
                handleFilterChange(SearchParamsKeys.dateFrom, date || "");
              }}
            />
            <div className={styles.error}>{errors.dateFrom}</div>
          </div>
          <div>
            <NxDatePicker
              label={""}
              className={styles.filterInput}
              minDate={values.dateFrom || undefined}
              value={values.dateTo}
              disableFutureDates={disableFutureDates}
              placeholder={t("FILTERS.INPUT.DATE_TO.PLACEHOLDER")}
              onChange={(date) => {
                setFieldValue("dateTo", date || "");
                handleFilterChange(SearchParamsKeys.dateTo, date || "");
              }}
            />
            <div className={styles.error}>{errors.dateTo}</div>
          </div>
          <div>
            <NxSelect
              options={selectOptions}
              label={<SharedPrefixTrans>STATUS</SharedPrefixTrans>}
              className={styles.filterInput}
              value={values.status}
              onChange={(status) => {
                setFieldValue("status", status || "");
                handleFilterChange(SearchParamsKeys.status, status || "");
              }}
            />
            <div className={styles.error}>{errors.status}</div>
          </div>
          <div>
            <SearchInput
              onChange={(value) =>
                handleFilterChange(SearchParamsKeys.phrase, value)
              }
            />
          </div>
        </form>
      )}
    </Formik>
  );
}

export default Filters;
