import { Formik, FormikHelpers, FormikProps } from "formik";
import React, { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { boolean, object, SchemaOf, string } from "yup";
import { MerchantContext, SecurityContext } from "../../../App";
import { RoutesPaths } from "../../../routes/routes.paths";
import { LOGIN_URL, MERCHANTS_URL } from "../../../shared/constants/api-urls";
import useGet from "../../../shared/hooks/use-get.hook";
import usePost from "../../../shared/hooks/use-post.hook";
import { HttpError, SimpleErrorBody } from "../../../shared/model/Error.model";
import { Merchant } from "../../../shared/model/Merchant.model";
import {
  fromUserDataTO,
  UserDataTO,
} from "../../../shared/model/UserData.model";
import NxButton from "../../shared/nxButton/NxButton";
import { NxFormikCheckbox } from "../../shared/nxCheckbox/NxCheckbox";
import { NxFormikInput } from "../../shared/nxInput/NxFormikInput";
import { MOBILE_NUMBER_MASK } from "../../shared/phone-text-field/PhoneTextField";
import TermsLink from "../../shared/terms-link/TermsLink";
import { PrefixTrans } from "../Login";
import { LoginFormFields, LoginPayload } from "./LoginForm.model";
import styles from "./LoginForm.module.scss";

export default function LoginForm({
  onError,
}: {
  onError: (error) => void;
}): ReactElement {
  const GENERAL_ERROR_KEY = "authentication";

  const { setIsLogged, setUserData } = useContext(SecurityContext);
  const { setMerchants, setCurrentMerchant } = useContext(MerchantContext);
  const history = useHistory();
  const { t } = useTranslation();

  const login = usePost<UserDataTO, LoginPayload>(LOGIN_URL);
  const merchantsRequest = useGet<Array<Merchant>>(MERCHANTS_URL);

  const submit = async (
    values: LoginFormFields,
    actions: FormikHelpers<LoginFormFields>
  ): Promise<void> => {
    const loginPayload: LoginPayload = {
      ...values,
    };

    const userData = await login(loginPayload)
      .then(fromUserDataTO)
      .catch((error) => {
        const httpError = error as HttpError<SimpleErrorBody>;

        if (httpError.error.errorMessage === "Invalid credentials") {
          actions.setFieldError("username", "");
          actions.setFieldError("password", "");
          actions.setFieldError(
            GENERAL_ERROR_KEY,
            httpError.error.errorMessage
          );
          return Promise.resolve();
        }

        onError(
          httpError.response.status === 401 || httpError.response.status === 403
            ? t("LOGIN.ERROR")
            : t("LOGIN.SERVER_ERROR")
        );

        return null;
      });

    if (!userData) {
      return;
    }

    const merchants = await merchantsRequest().catch(() => []);

    setMerchants(merchants);
    setCurrentMerchant(
      merchants.find((merchant) => merchant.id === userData?.merchantIds[0])
    );
    setIsLogged(true);
    setUserData(userData);
    history.replace(RoutesPaths.ROOT);
  };

  const loginFormSchema: SchemaOf<LoginFormFields> = object({
    username: string()
      .defined(t("SHARED.FIELD_REQUIRED"))
      .test(
        "emptyMaskedField",
        t("SHARED.FIELD_REQUIRED"),
        (number) => Boolean(number) && number !== MOBILE_NUMBER_MASK
      ),
    password: string().defined(t("SHARED.FIELD_REQUIRED")),
    termsAgreement: boolean()
      .defined()
      .test("terms", t("SHARED.FIELD_REQUIRED"), (checked) => !!checked),
  }).defined();

  const LoginForm = ({
    handleSubmit,
    errors,
    isSubmitting,
  }: FormikProps<LoginFormFields>): ReactElement => (
    <div className={styles.inputs}>
      <form onSubmit={handleSubmit} className={styles.loginForm}>
        <NxFormikInput
          className={styles.input}
          name="username"
          label={<PrefixTrans>USERNAME</PrefixTrans>}
          type="username"
        />
        <NxFormikInput
          className={styles.input}
          name="password"
          label={<PrefixTrans>PASSWORD</PrefixTrans>}
          type="password"
        />
        <div className={styles.link}>
          <Link to={RoutesPaths.FORGOT_PASSWORD} className={styles.link}>
            <PrefixTrans>FORGOT_PASSWORD</PrefixTrans>
          </Link>
        </div>
        <TermsLink
          className={styles.terms}
          checkbox={
            <NxFormikCheckbox
              name="termsAgreement"
              className={`${errors.termsAgreement && styles.checkboxInvalid}`}
            />
          }
        />

        <div className={styles.error}>{errors.termsAgreement}</div>
        <div className={styles.error}>{errors[GENERAL_ERROR_KEY]}</div>
        <div className={styles.signInRow}>
          <NxButton
            type="submit"
            className={styles.button}
            loaded={!isSubmitting}
          >
            <PrefixTrans>SIGN_IN</PrefixTrans>
          </NxButton>
        </div>
        <p className={styles.register}>
          Don&apos;t have an account ?{" "}
          <a href={RoutesPaths.REGISTER}>Register</a>
        </p>
      </form>
    </div>
  );
  //TODO: remove env values before release
  const formInitValues = {
    username:
      (process.env.REACT_APP_ENV === "local" &&
        process.env.REACT_APP_DEFAULT_LOGIN) ||
      "",
    password:
      (process.env.REACT_APP_ENV === "local" &&
        process.env.REACT_APP_DEFAULT_PASSWORD) ||
      "",
    termsAgreement: true,
  };

  return (
    <Formik<LoginFormFields>
      initialValues={formInitValues}
      onSubmit={submit}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={loginFormSchema}
    >
      {LoginForm}
    </Formik>
  );
}
