export const INSTITUTIONS_URL = "/institutions";
export const REGISTER_URL = "/users/register";
export const SESSION_DATA_URL = "/users/session";
export const FORGOT_PASSWORD_URL = "/users/secret/reset";
export const FORGOT_PASSWORD_OTP_SNIPPET_URL =
  "/users/secret/reset/otp-snippet";
export const FORGOT_PASSWORD_RESEND_OTP_URL = "/users/secret/reset/resend-otp";
export const FORGOT_PASSWORD_CONFIRM_URL = "/users/secret/reset/confirm";
export const LOGIN_URL = "/users/login";
export const RESET_URL = "/users/secret/reset";
export const RESET_CONFIRM_URL = "/users/secret/reset/confirm";
export const PASSWORD_UPDATE_URL = "/users/secret/update";
export const PAYMENTS_URL = "/payments";
export const NOT_PAYMENTS_BUT_ACTUALLY_YES_URL = "/receipt-report";
export const NOT_PAYMENTS_BUT_ACTUALLY_YES__ADMIN_THIS_TIME_URL =
  "admin/payments";
export const ADMIN_PAYMENTS_URL = "admin/payments";
export const ORDERS_URL = "/orders";
export const ADMIN_ORDERS_URL = "admin/orders";
export const RECEIPT_REPORTS_URL = "/receipt-report";
export const CLIENT_RECEIPT_URL = "/client-report";
export const PAY_BY_LINK = "/payments/links";
export const PAY_BY_LINK_PERMANENT = "/payments/links/permanent";
export const PAY_BY_LINK_NEW = "/payments/links/new";
export const PAYMENTS_EXECUTE_URL = "/payments/execute";
export const PAYMENTS_SUMMARY_URL = "/orders/summary";
export const PAYMENTS_CHANGE_INSTITUTION = "/payments/change-institution";
export const ACCOUNTS = "/accounts";
export const OTP_SNIPPET = "/payments/otp-snippet";
export const SEND_OTP = "/payments/send-otp";
export const RESEND_OTP = "/payments/resend-otp";
export const ADMIN_MERCHANT_URL = "admin/merchant";
export const MERCHANT_URL = "/merchant";
export const ADMIN_MERCHANTS_URL = "admin/merchants";
export const MERCHANTS_URL = "/merchants";
export const SETTLEMENTS_URL = "/settlements";
export const DISBURSEMENTS_URL = "/disbursements";
export const DISBURSEMENTS_SUMMARY_URL = "/disbursements/summary";
export const SETTLEMENTS_ACCOUNT_BALANCE_URL = "/disbursements/account-balance";
export const PAYMENT_CALLBACK_URL = "/callbacks/PAYMENT";
export const SWIFTPAY = "/swiftpay";
export const VALIDATE_RECEIPT = "/public/receipts/validate";
export const VALIDATE_PAYMENT = "/payments/reference-no";
