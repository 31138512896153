import { useLocation } from 'react-router';
import { SearchParamsKeys } from '../shared/constants/search-params';
import { DEFAULT_DATE_TIME_FROM, DEFAULT_DATE_TIME_TO } from './time-utils';

export const getFilterSearchParams = <T>(search: string) => {
  const params = new URLSearchParams(search);

  const dateFrom =
    (params.get(SearchParamsKeys.dateFrom) as string) || DEFAULT_DATE_TIME_FROM;
  const dateTo =
    (params.get(SearchParamsKeys.dateTo) as string) || DEFAULT_DATE_TIME_TO;
  const phrase = params.get(SearchParamsKeys.phrase) as string;
  const status = params.get(SearchParamsKeys.status) as unknown as T;

  return { dateFrom, dateTo, phrase, status };
};
