import styles from './OrderBatch.module.scss';
import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { FC } from 'react';
import NxButton, {
  MaterialButtonVariant,
} from '../../shared/nxButton/NxButton';

type Props = {
  onClose: () => void;
  orderId?: string;
  onSubmit: () => void;
};

export const PaymentInstruction: FC<Props> = ({ onClose, orderId, onSubmit }) => {
  return (
    <>
      <DialogTitle className={styles.title}>
        Waiting for the payment
      </DialogTitle>
      <p className={styles.description}>
        Order your receipts batch here. Simply select the quantity of receipts
        you need.
      </p>
      <DialogContent>
        <div className={styles.modalBody}>
          <div className={styles.stepWrapper}>
            <div className={styles.stepBullet}>1</div>
            <span>
              Check your email, we have sent you a payment instructions to
              estoria.buiness@gmail.com.
            </span>
          </div>
          <div className={styles.stepWrapper}>
            <div className={styles.stepBullet}>2</div>
            <span>
              Make online payment via link or pay offline and upload the payment
              confirmation under &apos;Order Details&apos; section.
            </span>
          </div>
          {orderId && (
            <div>
              <div className={styles.orderIdLabel}>Order number</div>
              <div className={styles.orderId}>{orderId}</div>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <NxButton
          variant={MaterialButtonVariant.OUTLINED}
          className={styles.actionButton}
          onClick={onClose}
        >
          Cancel
        </NxButton>
        <NxButton className={styles.actionButton} onClick={onSubmit}>
          Go to the order details
        </NxButton>
      </DialogActions>
    </>
  );
};
