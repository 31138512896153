import { RoutesPaths } from "../../../routes/routes.paths";

export type NavItem = {
  to: string;
  translationKey: string;
};

export const navItems: NavItem[] = [
  {
    to: "",
    translationKey: "ABOUT",
  },
  {
    to: RoutesPaths.VALIDATE_RECEIPT,
    translationKey: "VERIFY_RECEIPT",
  },

  {
    to: "",
    translationKey: "CONTACT_US",
  },
];
