import { isNil } from 'lodash/fp';
import React, { ChangeEvent, ReactElement, useCallback, useRef } from 'react';
import { Nil } from '../../../shared/model/NxComponents.model';
import { logMissingOnChange } from '../inputError';
import NxGenericTextField from '../nxGenericTextField/NxGenericTextField';

export interface NxTextareaProps {
  className?: string;
  disabled?: boolean;
  error?: string;
  id?: string;
  label: React.ReactNode;
  name?: string;
  onBlur?: (e: ChangeEvent) => void;
  onChange?: (e: ChangeEvent) => void;
  onFocus?: (e: ChangeEvent) => void;
  positionAbsoluteError?: boolean;
  required?: boolean;
  value?: string | null;
  placeholder?: string;
}

const calculateValue = (value: string | Nil, lastValue: string): string => {
  if (isNil(value)) {
    return value === null ? lastValue : '';
  }

  return value;
};

const NxTextarea = ({
  className,
  disabled = false,
  error,
  id,
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  positionAbsoluteError,
  required,
  value,
  placeholder,
}: NxTextareaProps): ReactElement => {
  const lastValue = useRef('');
  const finalValue = calculateValue(value, lastValue.current);
  lastValue.current = finalValue;

  const handleChange = useCallback(
    (event: ChangeEvent): void => {
      lastValue.current = (event as ChangeEvent<HTMLInputElement>).target.value;
      if (!onChange) {
        logMissingOnChange('Input', name);
        return;
      }

      onChange(event);
    },
    [name, onChange]
  );

  return (
    <NxGenericTextField
      className={className}
      disabled={disabled}
      error={error}
      id={id}
      onBlur={onBlur}
      onChange={handleChange}
      onFocus={onFocus}
      positionAbsoluteError={positionAbsoluteError}
      required={required}
      label={label}
      name={name}
      rows={6}
      multiline
      value={finalValue}
      type='textarea'
      placeholder={placeholder}
    />
  );
};

export default NxTextarea;
