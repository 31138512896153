import { Grid } from "@material-ui/core";
import styles from "./ValidationSuccess.module.scss";

type ValidationSuccessEntityProps = {
  label: string;
  value: string;
};

export const ValidationSuccessEntity = ({
  label,
  value,
}: ValidationSuccessEntityProps) => {
  return (
    <Grid item xs={6}>
      <p className={styles.label}>{label}</p>
      <h3 className={styles.value}>{value}</h3>
    </Grid>
  );
};
