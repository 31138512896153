import {Collapse, Grid, InputAdornment} from '@material-ui/core';
import Arrow from '@material-ui/icons/ChevronLeftOutlined';
import classNames from 'classnames';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {number, object, SchemaOf, string} from 'yup';
import {MerchantContext} from '../../../App';
import {PAY_BY_LINK} from '../../../shared/constants/api-urls';
import usePost from '../../../shared/hooks/use-post.hook';
import {EnvironmentType} from '../../../shared/model/EnvironmentType.model';
import {HttpError, SimpleErrorBody} from '../../../shared/model/Error.model';
import {TransHelper} from '../../../utils/trans-helper';
import NxButton from '../../shared/nxButton/NxButton';
import NxDatePicker from '../../shared/nxDatePicker/NxDatePicker';
import {NxFormikInput} from '../../shared/nxInput/NxFormikInput';
import {PayByLinkFieldsWithEnv, PayByLinkFormFields, PayByLinkPayload, toFormFields} from './PayByLinkForm.model';
import styles from './PayByLinkNewEdit.module.scss';

const PrefixTrans = TransHelper.getPrefixedTrans('PAY_BY_LINK_NEW_EDIT');
const SharedPrefixTrans = TransHelper.getPrefixedTrans('SHARED');
const GENERAL_ERROR_KEY = 'HTTP_ERROR';

interface PaymentCreateEditProps {
  onCreate: (el: PayByLinkPayload) => void;
  formData: PayByLinkPayload | undefined;
}

export default function PayByLinkForm({
  onCreate,
  formData
}: PaymentCreateEditProps): React.ReactElement {
  const {t} = useTranslation();
  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    valid: formData ? formData.validUntil : ''
  });
  const env = new URLSearchParams(useLocation().search).get('env');
  const {currentMerchantId} = useContext(MerchantContext);
  const today = new Date();
  const minDate = today.toISOString().split('T')[0];
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 7);
  const formattedMaxDate = maxDate.toISOString().split('T')[0];

  const createPaymentLink = usePost<PayByLinkPayload, PayByLinkFieldsWithEnv>(
    PAY_BY_LINK
  );

  const [formInitValues, setFormInitValues] = useState<PayByLinkFormFields>({
    amount: undefined
  });

  useEffect(() => {
    if (formData) {
      setFormInitValues({...toFormFields(formData)});
    }
  }, []);

  const toggleOptionalInfo = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    setShow(!show);
  };

  const submit = async (values: PayByLinkFormFields, actions: FormikHelpers<PayByLinkFormFields>): Promise<void> => {
    const formInput: PayByLinkFieldsWithEnv = {
      ...values,
      ...(state.valid && {validUntil: state.valid + 'T00:00:00'}),
      environmentType: env as EnvironmentType,
      merchantId: currentMerchantId
    };
    await createPaymentLink(formInput)
      .then((res) => {
        onCreate(res);
      })
      .catch((error) => {
        /**
         * TODO: add error support
         */
        const httpError = error as HttpError<SimpleErrorBody>;
        if (httpError.error) {
          actions.setFieldError(
            GENERAL_ERROR_KEY,
            httpError.error.errorMessage
          );
          return Promise.resolve();
        }
      });
  };

  const payByLinkFormSchema: SchemaOf<PayByLinkFormFields> = object({
    amount: number()
      .typeError(t('SHARED.INVALID_NUMBER'))
      .positive(t('SHARED.POSITIVE_NUMBER'))
      .defined(t('SHARED.FIELD_REQUIRED')),
    customerName: string(),
    description: string(),
    referenceNo: string(),
    title: string(),
    validUntil: string()
  }).defined();

  const CreatePaymentLinkForm = ({
    handleSubmit,
    errors,
    isSubmitting
  }: FormikProps<PayByLinkFormFields>): ReactElement => (
    <div className={styles.inputs}>
      <form onSubmit={handleSubmit} className={styles.loginForm}>
        <div className={styles.formRow}>
          <Grid item xs={12} md={6}>
            <NxFormikInput className={styles.input}
              name='amount'
              endAdornment={<InputAdornment position='end'>PHP</InputAdornment>}
              disabled={!!formData}
              label={<SharedPrefixTrans>AMOUNT</SharedPrefixTrans>} />
          </Grid>
          <Grid item xs={12} md={6} className={styles.helperText}>
            <PrefixTrans>AMOUNT_HELP</PrefixTrans>
          </Grid>
        </div>
        <div className={styles.formRow}>
          <Grid item xs={12} md={6}>
            <NxFormikInput className={styles.input}
              name='title'
              disabled={!!formData}
              label={<PrefixTrans>TITLE</PrefixTrans>} />
          </Grid>
          <Grid item xs={12} md={6} className={styles.helperText}>
            <PrefixTrans>TITLE_HELP</PrefixTrans>
          </Grid>
        </div>

        <Grid item xs={12} md={6}>
          <button className={styles.customToggleBtn} onClick={toggleOptionalInfo}>
            Optional info
            <Arrow className={classNames(styles.btnArrow, {[styles.btnArrowUp]: !show})} />
          </button>
        </Grid>

        <Collapse in={show}>
          <div className={styles.formRow}>
            <Grid item xs={12} md={6}>
              <NxFormikInput className={styles.input}
                name='referenceNo'
                disabled={!!formData}
                label={<PrefixTrans>REFERENCE</PrefixTrans>} />
            </Grid>
            <Grid item xs={12} md={6} className={styles.helperText}>
              <PrefixTrans>REFERENCE_HELP</PrefixTrans>
            </Grid>
          </div>
          <div className={styles.formRow}>
            <Grid item xs={12} md={6}>
              <NxDatePicker name='validUntil'
                label={<PrefixTrans>VALID</PrefixTrans>}
                className={styles.input}
                disabled={!!formData}
                value={state.valid}
                minDate={minDate}
                maxDate={formattedMaxDate}
                initialView="date"
                onChange={(d): void => setState({valid: d ? d : ''})} />
            </Grid>
            <Grid item xs={12} md={6} className={styles.helperText}>
              <PrefixTrans>VALID_HELP</PrefixTrans>
            </Grid>
          </div>
          <div className={styles.formRow}>
            <Grid item xs={12} md={6}>
              <NxFormikInput className={styles.input}
                disabled={!!formData}
                name='description'
                label={<PrefixTrans>DESCRIPTION</PrefixTrans>} />
            </Grid>
            <Grid item xs={12} md={6} className={styles.helperText}>
              <PrefixTrans>DESCRIPTION_HELP</PrefixTrans>
            </Grid>
          </div>
          <div className={styles.formRow}>
            <Grid item xs={12} md={6}>
              <NxFormikInput className={styles.input}
                name='customerName'
                disabled={!!formData}
                label={<PrefixTrans>CUSTOMER</PrefixTrans>} />
            </Grid>
            <Grid item xs={12} md={6} className={styles.helperText}>
              <PrefixTrans>CUSTOMER_HELP</PrefixTrans>
            </Grid>
          </div>
        </Collapse>

        <div className={'error'}>{errors[GENERAL_ERROR_KEY]}</div>

        <Grid item xs={12} md={6}>
          {!formData && (
            <div className={styles.submitWrapper}>
              <NxButton type='submit' loaded={!isSubmitting}>
                <PrefixTrans>GENERATE</PrefixTrans>
              </NxButton>
            </div>
          )}
        </Grid>
      </form>
    </div>
  );

  return (
    <Formik<PayByLinkFormFields>
      enableReinitialize
      initialValues={formInitValues}
      onSubmit={submit}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={payByLinkFormSchema}>
      {CreatePaymentLinkForm}
    </Formik>
  );
}
