import { GridColDef } from '@material-ui/data-grid';
import { TFunction } from 'i18next';
import { createColDefWithDefaultConfiguration } from '../../shared/utils/dataGridDefaultColumnDefValues';
import { tableCellCurrencyFormatter, tableCellDateFormatter } from '../../shared/utils/tableCellValueFormatter';
import styles from './Order.module.scss';
import { StatusField } from '../shared/status/StatusField';

export const ordersTableColumnDefinitions = (
  t: TFunction,
  isAdmin: boolean
): Array<GridColDef> =>
  createColDefWithDefaultConfiguration(
    !isAdmin
      ? [
          {
            field: 'createdOn',
            headerName: t('ORDERS.CREATED'),
            renderCell: (params) => tableCellDateFormatter(params),
          },
          {
            field: 'approvedOn',
            headerName: t('ORDERS.APPROVED_ON'),
            renderCell: (params) => tableCellDateFormatter(params),
          },
          {
            field: 'quantity',
            headerName: t('ORDERS.QUANTITY'),
            cellClassName: styles.orderNo,
            renderCell: (params) => params.row.totalReceiptsCount,
          },
          {
            field: 'rangeMin',
            headerName: t('ORDERS.MIN_RECEIPT_ID'),
            renderCell: (params) => params.row.minReceiptId,
          },
          {
            field: 'rangeMax',
            headerName: t('ORDERS.MAX_RECEIPT_ID'),
            renderCell: (params) => params.row.maxReceiptId,
          },
          {
            field: 'status',
            headerName: t('ORDERS.STATUS'),
            renderCell: (params) => <StatusField status={params.row.status} />,
          },
          {
            field: 'amount',
            headerName: t('ORDERS.AMOUNT'),
            renderCell: (params) => params.row.amount,
          },
        ]
      : [
          {
            field: 'createdOn',
            headerName: t('ORDERS.CREATED'),
            renderCell: (params) => tableCellDateFormatter(params),
          },
          {
            field: 'approvedOn',
            headerName: t('ORDERS.APPROVED_ON'),
            renderCell: (params) => tableCellDateFormatter(params),
          },
          {
            field: 'merchantName',
            headerName: t('ORDERS.MERCHANT'),
            renderCell: (params) => params.row.merchantName,
          },
          {
            field: 'quantity',
            headerName: t('ORDERS.QUANTITY'),
            cellClassName: styles.orderNo,
            renderCell: (params) => params.row.totalReceiptsCount,
          },
          {
            field: 'rangeMin',
            headerName: t('ORDERS.MIN_RECEIPT_ID'),
            renderCell: (params) => params.row.minReceiptId,
          },
          {
            field: 'rangeMax',
            headerName: t('ORDERS.MAX_RECEIPT_ID'),
            renderCell: (params) => params.row.maxReceiptId,
          },
          {
            field: 'status',
            headerName: t('ORDERS.STATUS'),
            renderCell: (params) => <StatusField status={params.row.status} />,
          },
          {
            field: 'amount',
            headerName: t('ORDERS.AMOUNT'),
            renderCell: tableCellCurrencyFormatter,
          },
        ]
  );
