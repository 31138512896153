import React, { useCallback, useEffect, useState } from "react";
import { MerchantContextType, SecurityContextType } from "./App.model";
import NxLoader from "./components/shared/nxLoader/NxLoader";
import RenderRoutes from "./routes/render-routes/RenderRoutes";
import { ROUTES } from "./routes/routes";
import { MERCHANTS_URL, SESSION_DATA_URL } from "./shared/constants/api-urls";
import useGet from "./shared/hooks/use-get.hook";
import { Merchant } from "./shared/model/Merchant.model";
import { UserData } from "./shared/model/UserData.model";
import "./styles/styles.scss";
import { isTokenValid } from "./utils/security";
import { useLocation } from "react-router";
import { RoutesPaths } from "./routes/routes.paths";

import "react-datepicker/dist/react-datepicker.css";

export const SecurityContext = React.createContext<SecurityContextType>({
  isLogged: undefined,
  setIsLogged: () => null,
  logoutReason: undefined,
  setLogoutReason: () => null,
  setUserData: () => null,
  isAdmin: false,
  userData: undefined,
});

export const MerchantContext = React.createContext<MerchantContextType>({
  setMerchants: () => null,
  setCurrentMerchant: () => null,
});

function App(): React.ReactElement {
  const [isLogged, setIsLogged] = useState<boolean>();
  const [userData, setUserData] = useState<UserData>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [logoutReason, setLogoutReason] = useState<string>();
  const user = useGet<UserData>(SESSION_DATA_URL);
  const merchantsRequest = useGet<Array<Merchant>>(MERCHANTS_URL);
  const [merchants, setMerchants] = useState<Merchant[]>();
  const [currentMerchant, setCurrentMerchant] = useState<Merchant>();
  const { pathname } = useLocation();

  const fetchUserData = useCallback(async () => {
    if (pathname.includes(RoutesPaths.VALIDATE_RECEIPT)) {
      return;
    }
    // fetch session data
    const userData = await user().catch(() => {
      setIsLogged(false);
      return null;
    });

    if (!userData) {
      setIsLogged(false);
      return null;
    }

    setUserData(userData);
    setIsLogged(true);

    // fetch orders
    const merchants = await merchantsRequest().catch(() => []);

    setMerchants(merchants);
    if (merchants.length) {
      setCurrentMerchant(merchants[0]);
    }
  }, []);

  useEffect(() => {
    if (isLogged && userData) {
      setIsAdmin(isLogged && userData.role === "ADMIN");
    }
  }, [isLogged, userData]);

  useEffect(() => {
    if (isTokenValid()) {
      fetchUserData();
      return;
    }
    setIsLogged(false);
  }, []);

  return (
    <SecurityContext.Provider
      value={{
        isLogged,
        setIsLogged,
        logoutReason,
        setLogoutReason,
        userData,
        setUserData,
        isAdmin,
      }}
    >
      <MerchantContext.Provider
        value={{
          merchants,
          setMerchants,
          currentMerchant,
          currentMerchantId: currentMerchant?.id,
          setCurrentMerchant,
        }}
      >
        <NxLoader loaded={isLogged !== undefined}>
          <RenderRoutes routes={ROUTES} isLogged={isLogged} />
        </NxLoader>
      </MerchantContext.Provider>
    </SecurityContext.Provider>
  );
}

export default App;
