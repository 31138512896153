import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './dropzone.module.scss';

export function FileDropzone() {
  const [files, setFiles] = useState([]);
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        'application/pdf': ['.pdf'],
      },
    });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  return (
    <section className={styles.container}>
      <div {...getRootProps({ className: styles.dropzone })}>
        <input {...getInputProps()} />
        <p>Drop file here, or click to select file (PDF up to 5mb)</p>
      </div>
      <aside>
        <h4>Uploaded file</h4>
        <ul>{acceptedFileItems}</ul>
      </aside>
    </section>
  );
}
