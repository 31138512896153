import { Grid } from "@material-ui/core";
import { ReactNode } from "react";
import styles from "./LandingPageLayout.module.scss";
import classNames from "classnames";

type LandingPageLeftRightProps = {
  left: ReactNode;
  right: ReactNode;
};

export const LandingPageLeftRight = ({
  left,
  right,
}: LandingPageLeftRightProps) => {
  return (
    <Grid container className={styles.container}>
      <Grid item xs={6} className={classNames(styles.left, styles.item)}>
        {left}
      </Grid>
      <Grid item xs={6} className={styles.item}>
        {right}
      </Grid>
    </Grid>
  );
};
