export const MOBILE_NUMBER_PREFIX = '+63 ';

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 64;
export const EMAIL_MAX_LENGTH = 124;
export const ACCOUNT_NUMBER_MAX_LENGTH = 32;
export const FIRST_NAME_MAX_LENGTH = 32;
export const LAST_NAME_MAX_LENGTH = 32;
export const DOCUMENT_NO_MAX_LENGTH = 32;
export const MIN_YEARS_OLD = 18;
