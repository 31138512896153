import {EnvironmentType} from '../../../shared/model/EnvironmentType.model';
import {PaymentStatus} from '../../../shared/model/PaymentStatus.model';

export type PayByLinkFieldsWithEnv = PayByLinkFormFields & {
  environmentType: EnvironmentType;
  merchantId?: number;
};

export type PayByLinkFormFields = {
  amount?: number;
  customerName?: string;
  description?: string;
  referenceNo?: string;
  title?: string;
  validUntil?: string;
};

export type PayByLinkPayload<T_STATUS = PaymentStatus> =
  PayByLinkFieldsWithEnv & {
  code: string;
  currency: string;
  paymentUrl: string;
  status: T_STATUS;
};

export const toFormFields = (
  formData: PayByLinkPayload
): PayByLinkFormFields => {
  return {
    amount: formData.amount,
    customerName: formData.customerName,
    description: formData.description,
    referenceNo: formData.referenceNo,
    title: formData.title,
    validUntil: formData.validUntil /*  dayjs(formData.validUntil) */
  };
};
