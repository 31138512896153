import { GridColDef } from '@material-ui/data-grid';
import { TFunction } from 'i18next';
import { createColDefWithDefaultConfiguration } from '../../shared/utils/dataGridDefaultColumnDefValues';
import {
  tableCellCurrencyFormatter,
  tableCellDateFormatter,
} from '../../shared/utils/tableCellValueFormatter';

export const paymentsTableColumnDefinitions = (
  t: TFunction,
  isAdmin: boolean
): Array<GridColDef> =>
  createColDefWithDefaultConfiguration(
    !isAdmin
      ? [
          {
            field: 'executedOn',
            headerName: t('PAYMENTS.BOOKED_ON'),
            valueFormatter: tableCellDateFormatter,
            flex: 1,
          },
          {
            field: 'attributes[paymentId]',
            headerName: t('PAYMENTS.PAYMENT_ID'),
            renderCell: (params) => params.row.attributes.paymentId,
            flex: 2,
          },
          {
            field: 'id',
            headerName: t('PAYMENTS.RECEIPT_ID'),
            flex: 2,
          },
          {
            field: 'amount',
            headerName: t('PAYMENTS.AMOUNT'),
            valueFormatter: tableCellCurrencyFormatter,
            flex: 2,
          },
        ]
      : [
          {
            field: 'executedOn',
            headerName: t('PAYMENTS.BOOKED_ON'),
            valueFormatter: tableCellDateFormatter,
            flex: 1,
          },
          {
            field: 'referenceNo',
            headerName: t('PAYMENTS.PAYMENT_ID'),
            flex: 2,
          },
          {
            field: 'attributes[orderId]',
            headerName: t('PAYMENTS.ORDER_ID'),
            renderCell: (params) => params.row.attributes.orderId,
            flex: 2,
          },
          {
            field: 'merchant',
            headerName: t('PAYMENTS.MERCHANT'),
            valueFormatter: (params) => params.row.merchantName,
            flex: 1,
          },
          {
            field: 'amount',
            headerName: t('PAYMENTS.AMOUNT'),
            valueFormatter: tableCellCurrencyFormatter,
            flex: 1,
          },
        ]
  );
