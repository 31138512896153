import { AppBar } from "@material-ui/core";
import { RoutesPaths } from "../../../routes/routes.paths";
import MainLogo from "../mainLogo/MainLogo";
import Link from "@material-ui/core/Link";
import NpoLogo from "../../../assets/images/npo-logo.jpg";

import styles from "./LandingPageNavbar.module.scss";
import { NavLink } from "react-router-dom";
import { TransHelper } from "../../../utils/trans-helper";
import { navItems } from "./LandingPageNavbar.model";

export const PrefixTrans = TransHelper.getPrefixedTrans("NAVBAR");

export const LandingPageNavbar = () => {
  return (
    <AppBar position="static" color={"transparent"} className={styles.navbar}>
      <div className={styles.navBarContent}>
        <div className={styles.logoLinkContainer}>
          <Link href={RoutesPaths.ROOT} className={styles.logoLink}>
            <MainLogo className={styles.customLogo}>
              <img src={NpoLogo} alt="npo logo" />
            </MainLogo>
          </Link>
        </div>
        <div className={styles.links}>
          {navItems.map(({ to, translationKey }) => (
            <div className={styles.linkWrapper} key={translationKey}>
              <Link
                component={NavLink}
                to={to}
                exact
                className={styles.navLink}
                activeClassName={styles.activeLink}
              >
                <PrefixTrans>{translationKey}</PrefixTrans>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </AppBar>
  );
};
