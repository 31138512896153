import logos from "../../../assets/images/national-printing-agency-logos.png";
import longLogo from "../../../assets/images/national_printing_office.png";

import styles from "./Footer.module.scss";

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <img src={longLogo} />
      <img src={logos} />
    </div>
  );
};
