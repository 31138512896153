import { ReactElement, ReactNode } from "react";

export enum BannerType {
  INFO,
  SUCCESS,
  WARN,
  ERROR,
}

export interface BannerMessage {
  type: BannerType;
  message: ReactNode;
}

export interface MainContainerProps {
  children: ReactNode;
  additionalHeaderElement?: ReactElement;
  footer?: ReactElement;
  bannerMessage?: BannerMessage;
  CustomNavbar?: ReactNode;
}
