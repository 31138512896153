import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from "axios";
import { isNotDevelopmentMode } from "../../utils/app-utils";
import { SwiftpayRequest } from "../model/SwiftpayRequest.model";
import { PaymentStatusEnum } from "../constants/receipt-status";
import { ValidationResponse } from "../../components/ValidateReceipt/ValidateForm.model";
import useApiCatch from "../hooks/api-error-catch.hook";
import { PAYMENTS_URL } from "../constants/api-urls";

export const ABORT_ERROR_NAME = "AbortError";
const API_HOST = process.env.REACT_APP_PAYMENT_STATUS_API;
const API_URL = `${API_HOST}/api`;
const RECAPTCHA_TOKEN_HEADER_NAME = "X-ReCaptcha-Token";

const axiosClient = axios.create({
  baseURL: API_URL,
});

const getResponseData = async <ResType>(
  axiosRequest: Promise<AxiosResponse<ResType>>
): Promise<ResType> => {
  const response = await axiosRequest;
  return response.data;
};

class PaymentStatusHttpService {
  request<ResType>(
    request: SwiftpayRequest,
    headers?: AxiosRequestConfig["headers"]
  ): Promise<ResType> {
    const { url, method, data, params, reCaptchaToken, cancelTokenSource } =
      request;
    return getResponseData<ResType>(
      axiosClient.request({
        url,
        method,
        data,
        ...(params && { params }),
        ...(cancelTokenSource && { cancelToken: cancelTokenSource.token }),
        headers: {
          ...(reCaptchaToken && {
            [RECAPTCHA_TOKEN_HEADER_NAME]: reCaptchaToken,
          }),
          ...headers,
        },
      } as AxiosRequestConfig)
    );
  }

  rawRequest<ResType>(
    request: SwiftpayRequest
  ): Promise<AxiosResponse<ResType>> {
    const { url, method, data, params, reCaptchaToken, cancelTokenSource } =
      request;
    return axiosClient.request<ResType, AxiosResponse<ResType>>({
      url,
      method,
      data,
      ...(params && { params }),
      ...(cancelTokenSource && { cancelToken: cancelTokenSource.token }),
      headers: {
        ...(reCaptchaToken && {
          [RECAPTCHA_TOKEN_HEADER_NAME]: reCaptchaToken,
        }),
      },
    } as AxiosRequestConfig);
  }
}

const paymentStatusHttpService = new PaymentStatusHttpService();

/**
 * Dedicated hook for perform GET request.
 *
 * @param url - target url for API request.
 *
 * T - type of data returned by api call.
 *
 * @return function which allows execute configured GET request. It has following arguments:
 * cancelTokenSource - CancelTokenSource which allows to abort request, it can be null or undefined.
 */
export const usePaymentStatus = (): ((
  params?: URLSearchParams | null,
  cancelTokenSource?: CancelTokenSource | null,
  headers?: AxiosRequestConfig["headers"] | null
) => Promise<PaymentStatusEnum>) => {
  const catchError = useApiCatch();

  return (
    params?: URLSearchParams | null,
    cancelTokenSource?: CancelTokenSource | null,
    headers?: AxiosRequestConfig["headers"] | null
  ): Promise<PaymentStatusEnum> => {
    const request: SwiftpayRequest = {
      url: `${PAYMENTS_URL}/status`,
      method: "GET",
      params,
      cancelTokenSource,
    };

    return paymentStatusHttpService
      .request<PaymentStatusEnum>(request, headers)
      .catch(catchError);
  };
};

export const usePaymentDetails = (): ((
  params?: URLSearchParams | null,
  cancelTokenSource?: CancelTokenSource | null,
  headers?: AxiosRequestConfig["headers"] | null
) => Promise<ValidationResponse>) => {
  const catchError = useApiCatch();

  return (
    params?: URLSearchParams | null,
    cancelTokenSource?: CancelTokenSource | null,
    headers?: AxiosRequestConfig["headers"] | null
  ): Promise<ValidationResponse> => {
    const request: SwiftpayRequest = {
      url: `${PAYMENTS_URL}/get-by-reference-no`,
      method: "GET",
      params,
      cancelTokenSource,
    };

    return paymentStatusHttpService
      .request<ValidationResponse>(request, headers)
      .catch(catchError);
  };
};
