import { Grid } from "@material-ui/core";
import { Footer } from "./Footer/Footer";
import { ReceiptCard } from "./ReceiptCard/ReceiptCard";
import styles from "./Home.module.scss";
import { MerchantCard } from "./MerchantCard/MerchantCard";
import { LandingPageLayout } from "../shared/landingPageLayout/LandingPageLayout";

const Home = () => {
  return (
    <LandingPageLayout footer={<Footer />}>
      <div className={styles.container}>
        <div className={styles.infoWrapper}>
          <p className={styles.start}>Start here</p>
          <p className={styles.header}>Verify, sign in, or register</p>
        </div>
        <Grid
          container
          style={{
            width: "100%",
          }}
          spacing={3}
        >
          <Grid item xs={6}>
            <ReceiptCard />
          </Grid>
          <Grid item xs={6}>
            <MerchantCard />
          </Grid>
        </Grid>
      </div>
    </LandingPageLayout>
  );
};

export default Home;
