import {Button, Grid, Typography} from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import classnames from 'classnames';
import classNames from 'classnames';
import React, {ReactElement, useEffect, useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useTranslation} from 'react-i18next';
import {Link, useLocation, useParams} from 'react-router-dom';
import {ReactComponent as Illustration} from '../../../assets/images/generate-icon.svg';
import {RoutesPaths} from '../../../routes/routes.paths';
import {PAY_BY_LINK} from '../../../shared/constants/api-urls';
import useGet from '../../../shared/hooks/use-get.hook';
import {TransHelper} from '../../../utils/trans-helper';
import MainContainer from '../../shared/main-container/MainContainer';
import NxButton from '../../shared/nxButton/NxButton';
import stylesPayByLink from '../PayByLink.module.scss';
import PayByLinkInvalidate from '../PayByLinkInvalidate';
import PayByLinkForm from './PayByLinkForm';
import {PayByLinkPayload} from './PayByLinkForm.model';
import styles from './PayByLinkNewEdit.module.scss';

const PrefixTrans = TransHelper.getPrefixedTrans('PAY_BY_LINK_NEW_EDIT');

function PayByLinkNewEdit(): ReactElement {
  const {t} = useTranslation();
  const [invalidate, setInvalidate] = useState<boolean>(
    false
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<PayByLinkPayload | undefined>(
    undefined
  );
  const [key, setKey] = useState<boolean>(false);
  const {code} = useParams<{code: string;}>();
  const paymentLink = useGet<PayByLinkPayload>(`${PAY_BY_LINK}/${code}`);
  const env = new URLSearchParams(useLocation().search).get('env');

  useEffect(() => {
    if (code) {
      paymentLink().then((res) => {
        setFormData(res);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const goBackRoute = (): string => {
    if (env) {
      return `${RoutesPaths.PAY_BY_LINK}?env=${env}`;
    }
    return RoutesPaths.PAY_BY_LINK;
  };

  const handleCreateLink = (paymentObject: PayByLinkPayload): void => {
    setFormData(paymentObject);
    // history.push(`/pay-by-link/edit/${paymentObject.code}?env=${env}`)
  };

  return (
    <MainContainer>
      <PayByLinkInvalidate open={invalidate}
        code={code || (formData ? formData.code : '')}
        onClose={(): void => setInvalidate(false)} />
      <Grid container>
        <Grid item xs={12} md={12}>
          {!loading && (
            <>
              <Typography variant='h1' component='h1' className={styles.title}>
                <Link className={classNames(styles.backBtn,
                  'MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-disableElevation')}
                  to={goBackRoute}>
                  <BackIcon />
                </Link>
                {
                  formData
                    ? <PrefixTrans>TITLE_EDIT</PrefixTrans>
                    : <PrefixTrans>TITLE_NEW</PrefixTrans>
                }
              </Typography>

              <div>
                <PayByLinkForm formData={formData}
                  onCreate={handleCreateLink} />
                {formData && (
                  <Grid item xs={12} md={6}>
                    <div className={styles.submitWrapper}>
                      <NxButton className={styles.buttonInvalid} onClick={(): void => setInvalidate(true)}>
                        <PrefixTrans>INVALIDATE</PrefixTrans>
                      </NxButton>
                    </div>
                  </Grid>
                )}
              </div>
            </>
          )}
        </Grid>
      </Grid>
      <Grid>
        {!loading && (
          <Grid item xs={12} md={12}>
            {formData ? (
              <div className={classnames(stylesPayByLink.paymentLinkWrapper, styles.paymentLinkWrapper)}>
                <p className={stylesPayByLink.text}>Your link:</p>
                <a href={formData.paymentUrl}>{formData.paymentUrl} </a>
                <CopyToClipboard text={formData.paymentUrl}>
                  <Button onClick={(): void => {
                    setKey(true);
                    setTimeout(() => setKey(false), 3000);
                  }}
                    variant='contained'
                    color='primary'
                    startIcon={!key && <CopyIcon />}>
                    {!key ? t('SHARED.COPY') : t('SHARED.COPIED')}
                  </Button>
                </CopyToClipboard>
              </div>
            ) : (
              <div className={styles.illustrationWrapper}>
                <div>
                  <PrefixTrans values={{quoted: `"${t('PAY_BY_LINK.GENERATE')}"`}}>INFO_TEXT</PrefixTrans>
                </div>
                <div className={styles.illustration}>
                  <Illustration />
                </div>
              </div>
            )}
          </Grid>
        )}
      </Grid>
    </MainContainer>
  );
}

export default PayByLinkNewEdit;
