import dayjs, {Dayjs} from 'dayjs';
import {EnvironmentType} from '../../shared/model/EnvironmentType.model';
import {FilterCriteria} from '../../shared/model/Filter.model';
import {PagedResponse} from '../../shared/model/Page.model';
import {PageCriteria} from '../../shared/model/PagedCriteria.model';
import {PaymentStatus} from '../../shared/model/PaymentStatus.model';
import { PaymentStatusEnum } from '../../shared/constants/receipt-status';

export enum PaymentLinkType {
  PERMANENT = 'PERMANENT',
  TRANSIENT = 'TRANSIENT'
}

export type PaymentLinkTO<T_DATE = string, T_STATUS = string,
  T_PAYMENT_LINK_TYPE = string, T_ENV_TYPE = string> = {
  amount: number;
  code: string;
  currency: string;
  environmentType: T_ENV_TYPE;
  merchantId: number;
  paymentUrl: string;
  status: T_STATUS;
  type: T_PAYMENT_LINK_TYPE;
  customerName?: string;
  description?: string;
  paymentId?: number;
  referenceNo?: string;
  title?: string;
  validUntil?: T_DATE;
};

export type PaymentLink = PaymentLinkTO<Dayjs, PaymentStatus, PaymentLinkType, EnvironmentType>;

export const fromPaymentLinkTO = (paymentLinkTO: PaymentLinkTO): PaymentLink => ({
  ...paymentLinkTO,
  environmentType: EnvironmentType[paymentLinkTO.environmentType],
  status: PaymentStatus[paymentLinkTO.status],
  validUntil: dayjs(paymentLinkTO.validUntil),
  type: PaymentLinkType[paymentLinkTO.type]
});

export type PaymentLinksResponse = PagedResponse<PaymentLinkTO>;

export type PaymentLinkRequestParams = PageCriteria & FilterCriteria<PaymentStatusEnum> & {
  env: EnvironmentType;
  type?: PaymentLinkType;
}
