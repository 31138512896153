import { ReactNode } from "react";
import classNames from "classnames";
import styles from "./CardLabel.module.scss";

type CardLabelProps = {
  children: ReactNode;
  variant?: "primary" | "secondary";
};

export const CardLabel = ({
  children,
  variant = "primary",
}: CardLabelProps) => {
  return (
    <p
      className={classNames(styles.cardLabel, {
        [styles.primary]: variant === "primary",
        [styles.secondary]: variant === "secondary",
      })}
    >
      {children}
    </p>
  );
};
