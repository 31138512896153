import { Container } from "@material-ui/core";
import styles from "./Status.module.scss";
import { useEffect } from "react";
import { useLocation } from "react-router";

export const Status = () => {
  const { search } = useLocation();
  const paymentId = new URLSearchParams(search).get("paymentId");

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://status-page-vv.s3.ap-southeast-1.amazonaws.com/status-page-qa.js";
    script.async = true;
    script.onload = () => handleScriptLoad();

    document.body.appendChild(script);
  }, []);

  const handleScriptLoad = () => {
    // @ts-expect-error oasjdflkjdsl
    StatusPage.initStatusPageRedirect({
      successUrl: `https://merchant.ereceipt.swiftpay.ph/status/success?paymentId=${paymentId}`,
      failureUrl: "http://example.com/failure",
      cancelUrl: "http://example.com/cancel",
      expiredUrl: "http://example.com/expired",
    });
  };

  return (
    <Container className={styles.center}>
      <div className={styles.content}>
        <h1>Payment Status Check</h1>
        <p>Checking payment status...</p>
      </div>
    </Container>
  );
};

export default Status;
