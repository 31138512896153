import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { SearchParamsKeys } from "../../../../shared/constants/search-params";
import NxInput from "../../nxInput/NxInput";
import { InputAdornment } from "@material-ui/core";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search-icon.svg";
import styles from "./SearchInput.module.scss";

export type SearchInputProps = {
  onChange: (value: string) => void;
};

export const SearchInput = ({ onChange }: SearchInputProps) => {
  const [search, setSearch] = useState("");

  const debouncedSearchInput = useDebouncedCallback(
    (value) => onChange(value),
    300
  );

  const handleSearchInput = (value: string) => {
    setSearch(value);
    debouncedSearchInput(value);
  };

  return (
    <NxInput
      type="search"
      label={""}
      placeholder={"Search..."}
      value={search}
      className={styles.searchInput}
      onChange={(e) => handleSearchInput((e.target as HTMLInputElement).value)}
      startAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};
