import clsx from "clsx";
import { ReactElement, useEffect, useState } from "react";
import { ReactComponent as InfoIcon } from "../../../assets/images/info-icon.svg";
import { useCountDownTimerHook } from "../../../shared/hooks/count-down-timer.hook";
import { TransHelper } from "../../../utils/trans-helper";
import Navbar from "../navbar/Navbar";
import { BannerType, MainContainerProps } from "./MainContainer.model";
import styles from "./MainContainer.module.scss";
import { useLocation } from "react-router";

const SharedTrans = TransHelper.getPrefixedTrans("SHARED");

const MainContainer = ({
  children,
  additionalHeaderElement,
  footer,
  bannerMessage,
  CustomNavbar,
}: MainContainerProps) => {
  const location = useLocation();
  const [isBannerShown, setIsBannerShown] = useState<boolean>();
  const { seconds, activateCountDown } = useCountDownTimerHook();

  useEffect(() => {
    if (bannerMessage) {
      activateCountDown(3);
      setIsBannerShown(true);
    }
  }, [bannerMessage]);

  useEffect(() => {
    if (seconds === 0) {
      setIsBannerShown(false);
    }
  }, [seconds]);

  const copyright = (
    <div className={styles.copyright}>
      <div className={styles.copyrightLinks}>
        <a target="_blank" rel="noopener noreferrer" className={styles.link}>
          <SharedTrans>PRIVACY_POLICY</SharedTrans>
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.link} ${styles.link_termsAndConditions}`}
          href={process.env.REACT_APP_TERMS_AND_CONDITIONS_LINK}
        >
          <SharedTrans>TERMS_AND_CONDITIONS</SharedTrans>
        </a>
      </div>
    </div>
  );

  const getBannerClasses = (bannerType?: BannerType): string =>
    clsx(styles.banner, {
      [styles.banner_shown]: isBannerShown,
      [styles.banner_info]: bannerType === BannerType.INFO,
      [styles.banner_success]: bannerType === BannerType.SUCCESS,
      [styles.banner_warn]: bannerType === BannerType.WARN,
      [styles.banner_error]: bannerType === BannerType.ERROR,
    });

  const banner = (isMobileVariant: boolean): ReactElement => (
    <div
      className={clsx(styles.bannerContainer, {
        [styles.bannerContainer_mobile]: isMobileVariant,
        [styles.bannerContainer_desktop]: !isMobileVariant,
        [styles.bannerContainer_shown]: isBannerShown,
      })}
    >
      <div className={getBannerClasses(bannerMessage?.type)}>
        {bannerMessage?.type === BannerType.INFO ||
        bannerMessage?.type === BannerType.SUCCESS ? (
          <InfoIcon className={styles.bannerIcon} />
        ) : (
          <></>
        )}
        {bannerMessage?.message}
      </div>
    </div>
  );

  return (
    <div className={styles.mainContainer}>
      <div className={styles.navBarContainer}>{CustomNavbar || <Navbar />}</div>
      <main className={styles.main}>
        {banner(false)}
        {additionalHeaderElement}
        <div className={styles.content}>
          {banner(true)}
          {children}
        </div>
      </main>
      <div className={styles.footer}>
        {footer}
        {!(location.pathname.includes("pos") || location.pathname.includes("register")) && copyright}
      </div>
    </div>
  );
};

export default MainContainer;
